namespace Advant.Crossroads {

    interface ICollegeSearch {
        colleges: ICollege[];
        searchFilter: string;
        selectedSchool: ICollege;
        state: string;
        schoolName: string;
        stateList: any;
        unlistedSchool: boolean;
        search?: () => void;
        selectSchool?: (college: ICollege) => void;
        selectSchoolNotInList?: () => void;
        nextPage?: () => void;
        clear: (event: angular.IAngularEvent) => void;
        close: () => void;

    }

    interface IAdvCollege extends angular.IDirective {
    }

    interface IAdvCollegeScope extends angular.IScope {
        $schoolSearch: ICollegeSearch;
        ngModel: any;
        ceebField: any;
        typeField: any;
        streetField: any;
        street2Field: any;
        cityField: any;
        stateField: any;
        countryField: any;
        postalField: any;
    }

    class AdvCollege implements IAdvCollege {
        static directiveId: string = "advCollege";
        restrict: string = "EA";
        priority = 1;
        templateUrl: string = "/app/directives/advCollege.html";
        scope: any = {
            ngModel: "=",
            ceebField: "=",
            typeField: "=",
            streetField: "=",
            street2Field: "=",
            cityField: "=",
            stateField: "=",
            countryField: "=",
            postalField: "=",
            placeholder: "@"
        }

        constructor(private $document: angular.IDocumentService,
            private $uibPosition: angular.ui.bootstrap.IPositionService,
            private $parse: angular.IParseService,
            private $timeout: angular.ITimeoutService,
            private collegeLookup: ICollegeLookup,
            private stateLookup: IStateLookup) {
        }

        link = (scope: IAdvCollegeScope, element: JQuery, attrs) => {
            scope.$schoolSearch = {
                colleges: null,
                searchFilter: null,
                selectedSchool: null,
                unlistedSchool: false,
                state: "NC",
                stateList: null,
                schoolName: scope.ngModel,
                clear: null,
                close: null
            };

            var lookupBox = element.find("[name = 'schoolLookupBox']").first();
            var schoolField = element.find("[name = 'schoolNameField']").first();
            var schoolFinder = element.find("[name = 'schoolFinder']").first();
            var schoolList = lookupBox.find(".school-list").first();
            var schoolId = this.$parse(attrs.ngModel + "Id")(scope.$parent);

            var schoolNameWatch, searchWatch, stateWatch;

            scope.$schoolSearch.stateList = this.stateLookup.getStates();
            scope.$schoolSearch.unlistedSchool = !schoolId && scope.ngModel;

            if (!scope.$schoolSearch.unlistedSchool) {
                this.disableFields(attrs);
            };

            var turnOnSchoolNameWatch = () => {
                schoolNameWatch = scope.$watch((listenScope: IAdvCollegeScope) => { return listenScope.$schoolSearch.schoolName; }, (newValue) => { scope.ngModel = newValue; });
            };

            if (scope.$schoolSearch.unlistedSchool) {
                turnOnSchoolNameWatch();
            }

            var turnOnWatches = () => {
                turnOnSchoolNameWatch();

                searchWatch = scope.$watch((listenScope: IAdvCollegeScope) => { return listenScope.$schoolSearch.searchFilter; }, () => {
                    scope.$schoolSearch.search();
                });

                stateWatch = scope.$watch((listenScope: IAdvCollegeScope) => { return listenScope.$schoolSearch.state; }, (newValue, oldValue) => {
                    if (newValue === oldValue) {
                        return;
                    }
                    this.collegeLookup.state = newValue;
                    scope.$schoolSearch.search();
                });
            }

            var turnOffWatches = () => {
                schoolNameWatch();
                searchWatch();
                stateWatch();
            }

            var turnOffJustSearchWatches = () => {
                searchWatch();
                stateWatch();
            }

            schoolField.click((event) => {
                event.preventDefault();
                var position = this.$uibPosition.position(element);
                lookupBox.css({ top: (position.top - 320 < -150 ? position.top + 60 : position.top - 400), left: position.left + 20 });
                if (!scope.$schoolSearch.unlistedSchool) {
                    lookupBox.toggleClass("hidden");
                    scope.$schoolSearch.search();
                    turnOnWatches();
                }
            });

            schoolFinder.click((event) => {
                event.preventDefault();
                schoolNameWatch();
                lookupBox.toggleClass("hidden");
                scope.$schoolSearch.search();
                turnOnWatches();
                scope.$schoolSearch.unlistedSchool = false;
                scope.$digest();
            });

            scope.$schoolSearch.search = () => {
                this.collegeLookup.state = scope.$schoolSearch.state;
                this.collegeLookup.searchFilter = scope.$schoolSearch.searchFilter;
                if (scope.$schoolSearch.searchFilter) {
                    this.collegeLookup.sortByRelevance = true;
                } else {
                    this.collegeLookup.sortByRelevance = false;
                }

                this.collegeLookup.page = 1;
                schoolList.scrollTop(0);
                this.collegeLookup.getColleges().then((result) => {
                    scope.$schoolSearch.colleges = result;
                });
            };

            scope.$schoolSearch.selectSchoolNotInList = () => {
                this.$parse(attrs.ngModel + "Id").assign(scope.$parent, null);
                scope.$schoolSearch.schoolName = "";
                scope.$schoolSearch.unlistedSchool = true;
                this.enableFields(attrs);
                turnOffJustSearchWatches();
                lookupBox.addClass("hidden");
            };

            scope.$schoolSearch.selectSchool = (college: ICollege) => {
                scope.ngModel = college.name;
                this.$parse(attrs.ngModel + "Id").assign(scope.$parent, college.id);
                scope.$applyAsync(attrs.ngModel + "Id");
                scope.$schoolSearch.schoolName = college.name;
                scope.$schoolSearch.unlistedSchool = false;

                if (angular.isDefined(attrs.ceebField)) {
                    scope.ceebField = college.ceebCode;
                    if (college.ceebCode == null || college.ceebCode == "")
                        attrs.ceebFieldDisabled = false;
                    else
                        attrs.ceebFieldDisabled = true;
                }

                if (angular.isDefined(attrs.typeField)) {
                    scope.typeField = college.type;
                    if (college.type == null)
                        attrs.typeFieldDisabled = false;
                    else
                        attrs.typeFieldDisabled = true;
                }

                if (angular.isDefined(attrs.streetField)) {
                    scope.streetField = college.address.street1;
                    if (college.address.street1 == null || college.address.street1 == "")
                        attrs.streetFieldDisabled = false;
                    else
                        attrs.streetFieldDisabled = true;
                }

                if (angular.isDefined(attrs.street2Field)) {
                    scope.street2Field = college.address.street2;
                    if (college.address.street2 == null || college.address.street2 == "")
                        attrs.street2FieldDisabled = false;
                    else
                        attrs.street2FieldDisabled = true;
                }

                if (angular.isDefined(attrs.cityField)) {
                    scope.cityField = college.address.city;
                    if (college.address.city == null || college.address.city == "")
                        attrs.cityFieldDisabled = false;
                    else
                        attrs.cityFieldDisabled = true;
                }

                if (angular.isDefined(attrs.stateField)) {
                    scope.stateField = college.address.state;
                    if (college.address.state == null || college.address.state == "")
                        attrs.stateFieldDisabled = false;
                    else
                        attrs.stateFieldDisabled = true;
                }

                if (angular.isDefined(attrs.countryField)) {
                    //scope.countryField = college.address.country || "US";
                    scope.countryField = college.address.country;
                    if (college.address.country == null || college.address.country == "")
                        attrs.countryFieldDisabled = false;
                    else
                        attrs.countryFieldDisabled = true;
                }

                if (angular.isDefined(attrs.postalField)) {
                    scope.postalField = college.address.zip;
                    if (college.address.zip == null || college.address.zip == "")
                        attrs.postalFieldDisabled = false;
                    else
                        attrs.postalFieldDisabled = true;
                }

                this.disableFields(attrs);
                turnOffWatches();
                lookupBox.addClass("hidden");
            };

            scope.$schoolSearch.nextPage = () => {
                if (this.collegeLookup.totalPages > this.collegeLookup.page) {
                    this.collegeLookup.page += 1;
                    this.collegeLookup.getColleges().then((result) => {
                        scope.$schoolSearch.colleges = scope.$schoolSearch.colleges.concat(result);
                    });
                }
            };

            scope.$schoolSearch.clear = (event: angular.IAngularEvent) => {
                event.preventDefault();

                scope.$schoolSearch.schoolName = null;
                this.$parse(attrs.ngModel + "Id").assign(scope.$parent, null);
                this.$parse(attrs.ngModel).assign(scope.$parent, null);

                this.clearFields(scope, attrs);
            };

            scope.$schoolSearch.close = () => {
                turnOffWatches();
                lookupBox.addClass("hidden");
            };
        }

        disableFields = (attrs) => {
            // Have to run this code in $timeout because we need to run after the last $digest cycle in case any of these fields
            // are hidden
            this.$timeout(() => {
                if (angular.isDefined(attrs.ceebFieldDisabled) && attrs.ceebFieldDisabled == true) {
                    this.$document.find("[ng-model='" + attrs.ceebField + "']").attr("disabled", "disabled");
                }
                if (angular.isDefined(attrs.typeFieldDisabled) && attrs.typeFieldDisabled == true) {
                    this.$document.find("[ng-model='" + attrs.typeField + "']").attr("disabled", "disabled");
                }
                if (angular.isDefined(attrs.streetFieldDisabled) && attrs.streetFieldDisabled == true) {
                    this.$document.find("[ng-model='" + attrs.streetField + "']").attr("disabled", "disabled");
                }
                if (angular.isDefined(attrs.street2FieldDisabled) && attrs.street2FieldDisabled == true) {
                    this.$document.find("[ng-model='" + attrs.street2Field + "']").attr("disabled", "disabled");
                }
                if (angular.isDefined(attrs.cityFieldDisabled) && attrs.cityFieldDisabled == true) {
                    this.$document.find("[ng-model='" + attrs.cityField + "']").attr("disabled", "disabled");
                }
                if (angular.isDefined(attrs.stateFieldDisabled) && attrs.stateFieldDisabled == true) {
                    this.$document.find("[ng-model='" + attrs.stateField + "']").attr("disabled", "disabled");
                    this.$document.find("[ng-model='" + attrs.stateField + "']").find("*").attr("disabled", "disabled");
                    this.$document.find("[ng-model='" + attrs.stateField + "']").find("*").css("pointerEvents", "none");
                }
                if (angular.isDefined(attrs.countryFieldDisabled) && attrs.countryFieldDisabled == true) {
                    this.$document.find("[ng-model='" + attrs.countryField + "']").attr("disabled", "disabled");
                    this.$document.find("[ng-model='" + attrs.countryField + "']").find("*").attr("disabled", "disabled");
                    this.$document.find("[ng-model='" + attrs.countryField + "']").find("*").css("pointerEvents", "none");
                }
                if (angular.isDefined(attrs.postalFieldDisabled) && attrs.stateFieldDisabled == true) {
                    this.$document.find("[ng-model='" + attrs.postalField + "']").attr("disabled", "disabled");
                }
            });
        };

        clearFields = (scope, attrs) => {
            if (angular.isDefined(attrs.ceebField)) {
                scope.ceebField = null;
            }

            if (angular.isDefined(attrs.typeField)) {
                scope.typeField = null;
            }

            if (angular.isDefined(attrs.streetField)) {
                scope.streetField = null;
            }

            if (angular.isDefined(attrs.street2Field)) {
                scope.street2Field = null;
            }

            if (angular.isDefined(attrs.cityField)) {
                scope.cityField = null;
            }

            if (angular.isDefined(attrs.stateField)) {
                scope.stateField = null;
            }

            if (angular.isDefined(attrs.countryField)) {
                scope.countryField = null;
            }

            if (angular.isDefined(attrs.postalField)) {
                scope.postalField = null;
            }

        };

        enableFields = (attrs) => {
            // Have to run this code in $timeout because we need to run after the last $digest cycle in case any of these fields
            // are hidden
            this.$timeout(() => {
                if (angular.isDefined(attrs.ceebFieldDisabled)) {
                    this.$document.find("[ng-model='" + attrs.ceebField + "']").removeAttr("disabled");
                }
                if (angular.isDefined(attrs.typeFieldDisabled)) {
                    this.$document.find("[ng-model='" + attrs.typeField + "']").removeAttr("disabled");
                }
                if (angular.isDefined(attrs.streetFieldDisabled)) {
                    this.$document.find("[ng-model='" + attrs.streetField + "']").removeAttr("disabled");
                }
                if (angular.isDefined(attrs.street2FieldDisabled)) {
                    this.$document.find("[ng-model='" + attrs.street2Field + "']").removeAttr("disabled");
                }
                if (angular.isDefined(attrs.cityFieldDisabled)) {
                    this.$document.find("[ng-model='" + attrs.cityField + "']").removeAttr("disabled");
                }
                if (angular.isDefined(attrs.stateFieldDisabled)) {
                    this.$document.find("[ng-model='" + attrs.stateField + "']").removeAttr("disabled");
                    this.$document.find("[ng-model='" + attrs.stateField + "']").find("*").removeAttr("disabled");
                    this.$document.find("[ng-model='" + attrs.stateField + "']").find("*").css("pointerEvents", "");
                }
                if (angular.isDefined(attrs.countryFieldDisabled)) {
                    this.$document.find("[ng-model='" + attrs.countryField + "']").removeAttr("disabled");
                    this.$document.find("[ng-model='" + attrs.countryField + "']").find("*").removeAttr("disabled");
                    this.$document.find("[ng-model='" + attrs.countryField + "']").find("*").css("pointerEvents", "");
                }
                if (angular.isDefined(attrs.postalFieldDisabled)) {
                    this.$document.find("[ng-model='" + attrs.postalField + "']").removeAttr("disabled");
                }
            });
        };
    }

    angular.module("app").directive(AdvCollege.directiveId, ["$document", "$uibPosition", "$parse", "$timeout", "collegeLookup", "stateLookup",
        ($document, $uibPosition, $parse, $timeout, collegeLookup, stateLookup) =>
            new AdvCollege($document, $uibPosition, $parse, $timeout, collegeLookup, stateLookup)
    ]);
}