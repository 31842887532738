/// <reference path="../app.ts" />
namespace Advant.Crossroads {

    export interface IHelper {
        isType: (entityType: Type, match: string) => boolean;
        isUpperCase: (value: string) => boolean;
        toCamelCase: (value: string) => string;
        openFile: (httpPath) => angular.IPromise<void>;

    }

    export class Helper implements IHelper {
        static serviceId: string = "helper";
        static $inject: string[] = ["$http"];

        constructor(private $http: angular.IHttpService) {
        }

        isType(entityType: Type, match: string) {
            var result = false;
            if (entityType) {
                if (entityType.name === match) {
                    result = true;
                } else if (entityType.baseType) {
                    result = this.isType(entityType.baseType, match);
                }
            }

            return result;
        }

        isUpperCase = (value: string): boolean => {
            if (!isNaN(<any>value * 1)) {
                return false;
            } else {
                if (value === value.toUpperCase()) {
                    return true;
                } else {
                    return false;
                }
            }
        };

        toCamelCase = (value: string): string => {
            if (!value) {
                return value;
            }

            if (!this.isUpperCase(value.charAt(0))) {
                return value;
            }

            var chars = value.split("");

            for (var i = 0; i < chars.length; i++) {
                var hasNext = (i + 1 < chars.length);

                if (i > 0 && hasNext && !this.isUpperCase(chars[i + 1])) {
                    break;
                }

                chars[i] = chars[i].toLowerCase();
            }

            return chars.join("");
        };

        openFile = (httpPath) => {
            return this.$http.get(httpPath, { responseType: "arraybuffer" })
                .then((response) => {
                    var octetStreamMime = "application/octet-stream";

                    // Get the headers
                    var headerValues = response.headers();

                    // Get the filename from the x-filename header or default to "download.bin"
                    var filename = headerValues["x-filename"] || "download.pdf";

                    // Determine the content type from the header or default to "application/octet-stream"
                    var contentType = headerValues["content-type"] || octetStreamMime;
                    var blob = new Blob([response.data], { type: contentType });

                    saveAs(blob, filename);

                },
                (reason) => {
                    console.log(`Request failed with status: ${status}`, reason);
                });
        };

    }

    angular.module("app").service(Helper.serviceId, Helper);
}