/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    interface IAdvTabsetControllerScope extends angular.IScope {

    }

    interface IAdvTabsetController {
        tabs: Array<any>;
        oldIndex: number;
        active: number;
        destroyed: boolean;
        currentIndex: number;
    }

    class AdvTabsetController implements IAdvTabsetController {
        tabs: Array<any> = [];
        oldIndex: number;
        active: number;
        destroyed: boolean;
        currentIndex;

        static $inject: string[] = ["$scope"];

        constructor(private $scope: IAdvTabsetControllerScope) {
            $scope.$watch("tabset.active", (val) => {
                if (angular.isNumber(val) && val !== this.oldIndex) {
                    this.select(this.findTabIndex(val));
                }
            });

            $scope.$on("$destroy", () => {
                this.destroyed = true;
            });

        }

        findTabIndex = (index) => {
            for (var i = 0; i < this.tabs.length; i++) {
                if (this.tabs[i].index === index) {
                    return i;
                }
            }
        };


        select = (index) => {
            this.currentIndex = index;
            if (!this.destroyed) {
                var previousIndex = this.findTabIndex(this.oldIndex);
                var previousSelected = this.tabs[previousIndex];
                if (previousSelected) {
                    previousSelected.tab.onDeselect();
                    previousSelected.tab.active = false;
                }

                var selected = this.tabs[index];
                if (selected) {
                    selected.tab.onSelect();
                    selected.tab.active = true;
                    this.active = selected.index;
                    this.oldIndex = selected.index;
                } else if (angular.isNumber(this.oldIndex)) {
                    this.active = null;
                    this.oldIndex = null;
                }
            }
        }

        addTab = (tab) => {
            var existingIndex = _.findIndex(this.tabs, ["tab", tab]);
            if (existingIndex > -1) {
                return;
            }

            this.tabs.push({
                tab: tab,
                index: tab.index
            });
            this.tabs.sort((t1, t2) => {
                if (t1.index > t2.index) {
                    return 1;
                }

                if (t1.index < t2.index) {
                    return -1;
                }

                return 0;
            });

            if (tab.index === this.active || !angular.isNumber(this.active) && this.tabs.length === 1) {
                var newActiveIndex = this.findTabIndex(tab.index);
                this.select(newActiveIndex);
            }
        };

        removeTab = (tab) => {
            var arrayIndex = this.findTabIndex(tab.index);
            if (arrayIndex) {
                if (tab.index === this.active) {
                    var newActiveTabIndex = arrayIndex === this.tabs.length - 1 ?
                        arrayIndex - 1 : arrayIndex + 1 % this.tabs.length;
                    this.select(newActiveTabIndex);
                }

                this.tabs.splice(arrayIndex, 1);
            }
        };

        navigateNextPrev = () => {
            debugger;
            
        }
    }

    angular.module("app").controller("AdvTabsetController", AdvTabsetController);
}