/// <reference path="app.ts" />

namespace Advant.Crossroads {
    "use strict";

    export interface IStorageEvents {
        error: string;
        storeChanged: string;
        wipChanged: string;
    }

    export interface ICrossroadsEvents {
        controllerActivateSuccess: string;
        spinnerToggle: string;
        storage: IStorageEvents;
        login: string;
        logout: string;
        reloadUser: string;
    }

    export interface IKeyCodes {
        backspace: number;
        tab: number;
        enter: number;
        esc: number;
        space: number;
        pageup: number;
        pagedown: number;
        end: number;
        home: number;
        left: number;
        up: number;
        right: number;
        down: number;
        insert: number;
        del: number;
    }

    export interface ICrossroadsConfig {
        appErrorPrefix: string;
        docTitle: string;
        events: ICrossroadsEvents;
        keyCodes: IKeyCodes;
        remoteServiceName: string;
        version: string;
        apiHost: string;
        authCookieName: string;
        rdsUrl: string;
    }


    export class Config implements ICrossroadsConfig {
        appErrorPrefix: string;
        docTitle: string;
        events: ICrossroadsEvents;
        keyCodes: IKeyCodes;
        remoteServiceName: string;
        version: string;
        apiHost: string;
        authCookieName: string;
        rdsUrl: string;


        constructor() {
            this.apiHost = "https://localhost:44353";
            this.appErrorPrefix = "[Crossroads Error]";
            this.docTitle = "CrossRoads: ";
            this.authCookieName = ".CfncIdentityLocal";
            this.rdsUrl = "https://testncresidency.cfnc.org";

            this.keyCodes = {
                backspace: 8,
                tab: 9,
                enter: 13,
                esc: 27,
                space: 32,
                pageup: 33,
                pagedown: 34,
                end: 35,
                home: 36,
                left: 37,
                up: 38,
                right: 39,
                down: 40,
                insert: 45,
                del: 46
            };

            this.events = {
                controllerActivateSuccess: "controller.activateSuccess",
                spinnerToggle: "spinner.toggle",
                storage: {
                    error: "store.error",
                    storeChanged: "store.changed",
                    wipChanged: "wip.changed"
                },
                logout: "auth.logout",
                login: "auth.login",
                reloadUser: "auth.reloadUser"
            };



            this.remoteServiceName = "/breeze/Breeze";

            this.version = "1.0.0";
        }
    }
    angular.module("app").constant("config", new Config());
}