// Install the angularjs.TypeScript.DefinitelyTyped NuGet package
module App {
    "use strict";

    interface ITranscriptStudentId extends ng.IDirective {
    }

    interface ITranscriptStudentIdScope extends ng.IScope {
        transcriptStudentId: any;
    }

    interface ITranscriptStudentIdAttributes extends ng.IAttributes {
        ngModel: any;
        transcriptStudentId: any;
    }

    TranscriptStudentId.$inject = ["$parse", "$compile"];

    function TranscriptStudentId($parse: ng.IParseService, $compile: ng.ICompileService): ITranscriptStudentId {
        var self = this;

        var link = (scope: ITranscriptStudentIdScope, element: ng.IAugmentedJQuery, attrs: ITranscriptStudentIdAttributes, ctrl: ng.INgModelController) => {
            var getter = $parse(attrs.transcriptStudentId);
            var setter = getter.assign;
            ctrl.$viewChangeListeners.push(() => {
                var modelValue = scope.$eval(attrs.ngModel);
                var currentValue = getter(scope);
                setter(scope, modelValue);
            });
        };

        return {
            restrict: "A",
            require: "ngModel",
            link: link
        }
    }

    angular.module("app").directive("transcriptStudentId", TranscriptStudentId);
}