namespace Advant.Crossroads {

    export interface IRLCPSponsoringSchoolLookup {
        log: (msg, data?, showToast?) => void;
        logError: (msg, data?, showToast?) => void;
        logSuccess: (msg, data?, showToast?) => void;
        loadingSchools: boolean;
        page: number;
        pageSize: number;
        totalCount: number;
        sortByRelevance: boolean;
        sortBy: string;
        sortDirection: string;
        totalPages: number;
        startRow: number;
        endRow: number;
        searchFilter: string;
        district: string;

        getRLCPSponsoringSchools: () => angular.IPromise<IRLCPSponsoringSchool[]>;
    }

    export class RLCPSponsoringSchoolLookup implements IRLCPSponsoringSchoolLookup {
        static serviceId: string = "rlcpSponsoringSchoolLookup";
        static $inject = ["Restangular", "common"];
        log: (msg, data?, showToast?) => void;
        logError: (msg, data?, showToast?) => void;
        logSuccess: (msg, data?, showToast?) => void;
        loadingSchools: boolean;
        page: number;
        pageSize: number;
        totalCount: number;
        sortByRelevance: boolean;
        sortBy: string;
        sortDirection: string;
        totalPages: number;
        startRow: number;
        endRow: number;
        searchFilter: string;
        district: string;

        constructor(private Restangular: Restangular.IService, private common: ICommonService) {
            this.log = common.logger.getLogFn(RLCPSponsoringSchoolLookup.serviceId);
            this.logError = common.logger.getLogFn(RLCPSponsoringSchoolLookup.serviceId, "error");
            this.logSuccess = common.logger.getLogFn(RLCPSponsoringSchoolLookup.serviceId, "success");

            this.page = 1;
            this.pageSize = 25;
            this.sortByRelevance = false;
            this.sortBy = "LEANameSorting";
            this.sortDirection = "ASC";
            this.searchFilter = "";
        }

        getRLCPSponsoringSchools = (): angular.IPromise<IRLCPSponsoringSchool[]> => {
            this.loadingSchools = true;
            var queryParams = {
                page: this.page,
                pageSize: this.pageSize,
                sortByRelevance: this.sortByRelevance,
                sortBy: this.sortBy,
                sortDirection: this.sortDirection,
                filter: this.searchFilter,
                district: this.district
            };

            return this.Restangular.one("lookup").one("school").withHttpConfig({ cache: true }).getList("rlcpsponsoringschools", queryParams).then((result: any) => {
                this.totalCount = result.totalCount;
                this.totalPages = result.totalPages;

                if (result.totalCount > 0) {
                    this.startRow = this.page === 1 ? 1 : (this.pageSize * (this.page - 1)) + 1;
                    this.endRow = (this.startRow - 1) + result.length;
                } else {
                    this.startRow = 0;
                    this.endRow = 0;
                }
                this.loadingSchools = false;
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting the RLCP sponsoring schools", reason);
            });
        };

        searchSchools = (): void => {
            _.debounce(() => {
                this.sortByRelevance = true;
                this.sortBy = null;
                this.getRLCPSponsoringSchools();
            }, 500);
        };

        sort = (sortValue: string): void => {
            if (this.sortBy === sortValue) {
                this.sortDirection = this.sortDirection === "ASC" ? "DESC" : "ASC";
            } else {
                this.sortBy = sortValue;
            }
            this.page = 1;
            this.searchFilter = "";
            this.sortByRelevance = false;
            this.getRLCPSponsoringSchools();
        };

    }
    angular.module("app").service(RLCPSponsoringSchoolLookup.serviceId, RLCPSponsoringSchoolLookup);
}