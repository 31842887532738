/// <reference path="rules.ts" />
/// <reference path="ruleservice.ts" />

namespace Advant.Crossroads.Rules {
    "use strict";

    export interface IAdvRule extends angular.IDirective {

    }

    export interface IAdvRuleAttribute extends angular.IAttributes {
        advResponse: any;
        advRule: any;        
        ngModel: any;
    }

    export interface IAdvRuleScope extends angular.IScope {
        advResponse: any;
        advRule: IRuleSet;
    }

    export class AdvRule implements IAdvRule {
        static directiveId: string = "advRule";
        restrict: string = "A";
        require: string = "?ngModel";
        modelController: angular.INgModelController;

        constructor(private $parse: angular.IParseService, private $timeout: angular.ITimeoutService, private ruleService: IRuleService) {
        }

        link = (scope: IAdvRuleScope, element: angular.IAugmentedJQuery, attrs: IAdvRuleAttribute, ctrl: angular.INgModelController) => {
            if (!ctrl) { return; };
            var rule: IRuleSet = this.$parse(attrs.advRule)(scope);


            if (rule) {
                var ruleProperties = this.ruleService.getPropertiesToWatch(rule);

                angular.forEach(ruleProperties, (ruleProperty) => {
                    scope.$watch(attrs.advResponse + "." + ruleProperty, (newValue: any, oldValue: any, oScope: IAdvRuleScope) => {
                            ctrl.$validate();
                    }, true);
                });

                (ctrl.$validators as any).rule = (modelValue, viewValue) => {
                    return this.ruleIsValid(attrs, scope, modelValue, viewValue);
                };

            }
        };

        ruleIsValid = (attrs: IAdvRuleAttribute, scope: IAdvRuleScope, modelValue, viewValue): boolean => {
            var value = modelValue || viewValue;

            
            var rule: IRuleSet = this.$parse(attrs.advRule)(scope);           
            var response = _.cloneDeep(this.$parse(attrs.advResponse)(scope)); // Have to use a clone otherwise anything watching the value will fire a $digest cycle

            //  Have to set the model value back to the scope. Validate fires before the model value is actually set
            //  which can cause the rule to fail
            var propertyToSet = attrs.ngModel.replace("vm.response.", "");
            response[propertyToSet] = value;

            var result = this.ruleService.evalRuleSet(rule, response);

            return result;
        };
    }


    angular.module("rules").directive(AdvRule.directiveId, ["$parse", "$timeout", "ruleService",
        ($parse: angular.IParseService, $timeout: angular.ITimeoutService, ruleService: IRuleService) =>
            new AdvRule($parse, $timeout, ruleService)
    ]);
}