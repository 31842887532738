namespace Advant.Crossroads {
    "use strict";

    interface ICheckList extends angular.IDirective {
    }

    interface ICheckListScope extends angular.IScope {
        list: any;
        checkListValue: string;
        checked: boolean;
    }

    class CheckList implements ICheckList {
        static directiveId: string = "checkList";
        restrict: string = "A";
        priority = 1000;
        scope = true;

        constructor(private $parse: angular.IParseService, private $compile: angular.ICompileService) {

        }

        // contains
        contains(arr, item, comparator) {
            if (angular.isArray(arr)) {
                for (var i = arr.length; i--;) {
                    if (comparator(arr[i], item)) {
                        return true;
                    }
                }
            }
            return false;
        }

        // add
        add(arr, item, comparator) {
            arr = angular.isArray(arr) ? arr : [];
            if (!this.contains(arr, item, comparator)) {
                arr.push(item);
            }
            return arr;
        }

        // remove
        remove(arr, item, comparator) {
            if (angular.isArray(arr)) {
                for (var i = arr.length; i--;) {
                    if (comparator(arr[i], item)) {
                        arr.splice(i, 1);
                        break;
                    }
                }
            }
            return arr;
        }

        compile = (element: angular.IAugmentedJQuery, attrs) => {
            element.removeAttr("check-list");

            element.attr("ng-model", "checked");

            return this.postLink;
        };

        postLink = (scope: ICheckListScope, element, attrs) => {

            var required = attrs.hasOwnProperty("checkListRequired");

            // compile with `ng-model` pointing to `checked`
            var compiled = this.$compile(element)(scope);
            // gets the model controller so we can set validity on the list
            var ngModelCtrl = compiled.controller("ngModel");

            // getter / setter for backing model
            var getter = this.$parse(attrs.checkList);
            var setter = getter.assign;
            var checklistChange = this.$parse(attrs.checklistChange);

            // value added to list
            var value = this.$parse(attrs.checkListValue)(scope.$parent);


            var comparator = angular.equals;

            if (required) {
                required = scope.$eval(attrs.checkListRequired);
            }

            scope.$watch(attrs.checkListRequired, (checklistRequired) => {
                required = checklistRequired;
                if (required) {
                    const currentModel = getter(scope.$parent);
                    let modelHasValue = currentModel && angular.isArray(currentModel) && currentModel.length > 0;
                    //  cannot have the isValid argument set to undefined. If it is than the validator ends up in the $pending property which
                    //  will not render the validation colors properly
                    modelHasValue = modelHasValue === undefined || modelHasValue === null ? false : modelHasValue;
                    ngModelCtrl.$setValidity("required", modelHasValue);
                } else {
                    ngModelCtrl.$setValidity("required", true);
                }
            });

            if (attrs.hasOwnProperty("checklistComparator")) {
                comparator = this.$parse(attrs.checklistComparator)(scope.$parent);
            }

            scope.$watch("checked", (newValue, oldValue) => {
                if (newValue === oldValue) {
                    return;
                }
                var current = getter(scope.$parent);
                if (newValue === true) {
                    setter(scope.$parent, this.add(current, value, comparator));
                } else {
                    setter(scope.$parent, this.remove(current, value, comparator));
                }

                if (checklistChange) {
                    checklistChange(scope);
                }
            });

            scope.$parent.$watchCollection<any>(attrs.checkList, (newArray) => {
                scope.checked = this.contains(newArray, value, comparator);
                if (required) {
                    var modelHasValue = newArray && angular.isArray(newArray) && newArray.length > 0;
                    //  cannot have the isValid argument set to undefined. If it is than the validator ends up in the $pending property which
                    //  render the validation colors properly
                    modelHasValue = modelHasValue === undefined ? false : modelHasValue;
                    ngModelCtrl.$setValidity("required", modelHasValue);
                }
            });
        };
    }

    angular.module("app").directive(CheckList.directiveId, ["$parse", "$compile", ($parse, $compile) =>
        new CheckList($parse, $compile)
    ]);

}