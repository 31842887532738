namespace Advant.Crossroads {
    "use strict";

    interface IAdvScrollTo extends angular.IDirective {
    }

    interface IAdvScrollToScope extends angular.IScope {
    }

    interface IAdvScrollToAttributes extends angular.IAttributes {
        advScrollTo: string;
        scrollSpeed?: string;
        ngClick?: any;
        clickEventAction?: string;
        scrollEnabled?: string;
    }

    AdvScrollTo.$inject = ["$window", "$parse"];
    function AdvScrollTo($window: angular.IWindowService, $parse: angular.IParseService): IAdvScrollTo {
        return {
            restrict: "A",
            link: link,

        }

        function link(scope: IAdvScrollToScope, element: angular.IAugmentedJQuery, attrs: IAdvScrollToAttributes) {
            var speed = attrs.scrollSpeed != null ? parseInt(attrs.scrollSpeed) : 500;
            var clickEvent;
            
            attrs.clickEventAction = attrs.clickEventAction ? attrs.clickEventAction : "before";

            if (attrs.ngClick) {
                clickEvent = _.clone(attrs.ngClick);
                element.off("click");
            }


            element.on('click', (e) => {
                var scrollEnabled = attrs.scrollEnabled ? scope.$eval(attrs.scrollEnabled) : true;
                if (attrs.clickEventAction == "before" && clickEvent) {
                    scope.$apply(clickEvent);
                }

                if (scrollEnabled) {
                    $("html, body").animate({
                        scrollTop: isFinite(parseInt(attrs.advScrollTo)) ? attrs.advScrollTo : $("#" + attrs.advScrollTo).offset().top
                    }, speed).promise().done(() => {
                        if (attrs.clickEventAction == "after" && clickEvent) {
                            scope.$apply(clickEvent);
                        }
                    });
                } else {
                    if (attrs.clickEventAction == "after" && clickEvent) {
                        scope.$apply(clickEvent);
                    }
                }


            });


        }
    }

    angular.module("app").directive("advScrollTo", AdvScrollTo);
}