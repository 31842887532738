/// <reference path="common.ts" />
namespace Advant.Crossroads {
    export interface ILogger {
        getLogFn: (moduleId, fnName?) => (msg, data?, showToast?) => void;
        log: (message, data?, source?, showToast?) => void;
        logWarning: (message, data?, source?, showToast?) => void;
        logSuccess: (message, data?, source?, showToast?) => void;
        logError: (message, data?, source?, showToast?) => void;
    }


    export class Logger implements ILogger {
        static serviceId: string = "logger";


        constructor(private $log: ng.ILogService) {
        }

        public getLogFn(moduleId, fnName?) {
            fnName = fnName || "log";
            switch (fnName.toLowerCase()) { // convert aliases
                case "success":
                    fnName = "logSuccess"; break;
                case "error":
                    fnName = "logError"; break;
                case "warn":
                    fnName = "logWarning"; break;
                case "warning":
                    fnName = "logWarning"; break;
            }

            var logFn = this[fnName] || this.log;
            return (msg, data?, showToast?) => {
                logFn(msg, data, moduleId, (showToast === undefined) ? false : showToast);
            };
        }

        public log = (message, data?, source?, showToast?) => {
            this.logIt(message, data, source, showToast, "info");
        };

        public logWarning = (message, data?, source?, showToast?) => {
            this.logIt(message, data, source, showToast, "warning");
        };

        public logSuccess = (message, data?, source?, showToast?) => {
            this.logIt(message, data, source, showToast, "success");
        };

        public logError = (message, data?, source?, showToast?) => {
            appInsights.trackEvent("CrossroadsError", { message: message, data: data, source: source });
            this.logIt(message, data, source, showToast, "error");
        };

        private logIt = (message, data, source, showToast, toastType) => {
            var write = (toastType === "error") ? this.$log.error : this.$log.log;
            source = source ? "[" + source + "] " : "";
            write(source, message, data);
            if (showToast) {
                if (toastType === "error") {
                    toastr.error(message);
                } else if (toastType === "warning") {
                    toastr.warning(message);
                } else if (toastType === "success") {
                    toastr.success(message);
                } else {
                    toastr.info(message);
                }
            }
        };
    }


    common.factory(Logger.serviceId, ["$log", ($log) =>
        new Logger($log)
    ]);
}