

namespace Advant.Crossroads.Rules {
    
    interface IRules extends angular.IModule { }
    // create the module and define its dependencies.
    var rules: IRules = angular.module("rules", ["restangular"]);

    /* tslint:disable: no-empty */
    // execute bootstrapping code and any dependencies.
    rules.run(["$q", "$rootScope", ($q, $rootScope) => {

    }]);
}