/// <reference path="rules/rules.ts" />

namespace Advant.Crossroads {
    "use strict";

    interface Iapp extends angular.IModule { }

    // create the module and define its dependencies.
    var app: Iapp = angular.module("app", [
        // angular modules 
        "ngResource",       // $resource for REST queries
        "ngAnimate",        // animations
        "ngRoute",          // routing
        "ngSanitize",
        "ngTouch",
        "ngMessages",
        "ngCookies",

        // custom modules 
        "authentication",
        "rules",
        "common",

        // 3rd Party Modules
        "ui.bootstrap",
        "mgcrea.ngStrap",
        "ui.select",
        "restangular",
        "ngFileUpload",
        "ngIdle",
        "dialogs.main"
    ]);

    app.config(["$httpProvider", "$locationProvider", "authenticationProvider", "config",
        ($httpProvider: angular.IHttpProvider, $locationProvider: angular.ILocationProvider, authenticationProvider: AuthenticationProvider, advConfig: ICrossroadsConfig) => {
            $locationProvider.html5Mode(true);

            authenticationProvider.clientId = "CrossRoadsLocal";
            authenticationProvider.useFullRefreshForLoginUrl = true;
            authenticationProvider.useFullRefreshForRedirect = true;

            authenticationProvider.apiUrls.apiHost = advConfig.apiHost;
            $httpProvider.interceptors.push(AuthInterceptor.serviceId);

            authenticationProvider.events.login = advConfig.events.login;
            authenticationProvider.events.logout = advConfig.events.logout;
            authenticationProvider.events.reloadUser = advConfig.events.reloadUser;

        }]);

    app.config(["$logProvider", ($logProvider) => {
        // turn debugging off/on (no info or warn)
        if ($logProvider.debugEnabled) {
            $logProvider.debugEnabled(true);
        }
    }]);

    //#region Configure the common services via commonConfig
    app.config(["commonConfigProvider", "config", (cfg: ICommonServiceProvider, advConfig: ICrossroadsConfig) => {
        cfg.config.controllerActivateSuccessEvent = advConfig.events.controllerActivateSuccess;
        cfg.config.spinnerToggleEvent = advConfig.events.spinnerToggle;
        cfg.config.loginEvent = advConfig.events.login;
        cfg.config.logoutEvent = advConfig.events.logout;
        cfg.config.reloadUserEvent = advConfig.events.reloadUser;
    }]);
    //#endregion

    //#region Configure the Restangular Service
    app.config(["RestangularProvider", "config", (RestangularProvider: Restangular.IProvider, advConfig: ICrossroadsConfig) => {
        RestangularProvider.setBaseUrl(advConfig.apiHost);

        // add a response interceptor
        RestangularProvider.addResponseInterceptor((data, operation, what, url, response, deferred) => {
            var extractedData;
            // .. to look for getList operations
            if (operation === "getList" &&
                (url.indexOf("/rules")
                    || url.indexOf("/majors")
                    || url.indexOf("/highSchools")
                    || url.indexOf("/colleges")
                    || url.indexOf("/majorlists")
                    || url.indexOf("/districts"))) {
                // .. and handle the data and meta data
                extractedData = data.results;
                extractedData.totalCount = data.totalCount;
                extractedData.totalPages = data.totalPages;
                return extractedData;
            }

            return data;
        });
    }]);
    //#endregion

    app.config(["$compileProvider", ($compileProvider) => {
        $compileProvider.debugInfoEnabled(false);
    }]);

    // execute bootstrapping code and any dependencies.
    app.run(["$route", "$q", "$rootScope", "$http", "$location", "Restangular", "authentication", "routemediator",
        ($route, $q, $rootScope, $http: angular.IHttpService, $location: angular.ILocationService, Restangular, authentication: IAuthenticationProviderService, routemediator: IRouteMediator) => {
            // include $route to kick start the router.
            routemediator.setRoutingHandlers();

            $rootScope.$on("$routeChangeStart", (event, next: any) => {
                appInsights.startTrackPage(next.$$route.title);

            });

            $rootScope.$on("$routeChangeSuccess", (event, current: any) => {
                var pagePath = `CrossRoads${$location.url()}`;

                appInsights.stopTrackPage(current.$$route.title, pagePath);

            });

            /* tslint:disable: no-string-literal */
            (<any>$http.defaults.headers.common).Authorization = "Bearer " + authentication.accessToken();
            $http.defaults.withCredentials = true;

            Restangular.configuration.getIdFromElem = (elem: Restangular.IElement) => {
                if (elem.hasOwnProperty("tenantName")) {
                    return elem["tenantName"];
                } else if (elem.hasOwnProperty("applicationDefinitionId")) {
                    var appId: string = elem["applicationDefinitionId"];
                    var id = appId.split("/")[5];
                    return id;
                } else {
                    return elem["id"];
                }
            };
        }]);


    app.config(["IdleProvider", "KeepaliveProvider", (IdleProvider, KeepaliveProvider) => {
        // configure Idle settings
        IdleProvider.idle(1800); // in seconds
        IdleProvider.timeout(60); // in seconds 
        KeepaliveProvider.interval(2); // in seconds
    }
    ]);
}