/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    interface IAdvCopy extends angular.IDirective {
    }

    interface IAdvCopyScope extends angular.IScope {
    }

    interface IAdvCopyAttributes extends angular.IAttributes {
        copyFields: string;
        advResponse: any;
        title: string;
        cssClass: string;
    }

    advCopy.$inject = ["$parse", "helper"];
    function advCopy($parse: angular.IParseService, helper: IHelper): IAdvCopy {

        var template = (tElement, tAttrs: IAdvCopyAttributes) => {
            return `<button type="button" class="${tAttrs.cssClass}">${tAttrs.title}</button>`;
        };

        var link = (scope: IAdvCopyScope, element: angular.IAugmentedJQuery, attrs: IAdvCopyAttributes) => {
            var copyFields: IDynamicCopyFields[] = $parse(attrs.copyFields)(scope);

            element.on("click", (event) => {
                var localResponse = $parse(attrs.advResponse)(scope);
                scope.$applyAsync((localScope) => {
                    angular.forEach(copyFields, (copyField: IDynamicCopyFields) => {
                        localResponse[helper.toCamelCase(copyField.copyToKey)] = localResponse[helper.toCamelCase(copyField.copyFromKey)];
                    });
                });
            });
        };


        return {
            restrict: "EA",
            template: template,
            link: link,
            replace: true
        }

    }

    angular.module("app").directive("advCopy", advCopy);
}