/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    interface IAdvTabContentTransclude extends angular.IDirective {
    }

    interface IAdvTabContentTranscludeScope extends angular.IScope {
    }

    interface IAdvTabContentTranscludeAttributes extends angular.IAttributes {
        advTabContentTransclude: string;
    }

    function advTabContentTransclude(): IAdvTabContentTransclude {
        return {
            restrict: "A",
            require: "^advTabset",
            link: link
        }

        function link(scope: IAdvTabContentTranscludeScope, element: angular.IAugmentedJQuery, attrs: IAdvTabContentTranscludeAttributes) {
            var tab = scope.$eval(attrs.advTabContentTransclude).tab;

            //Now our tab is ready to be transcluded: both the tab heading area
            //and the tab content area are loaded.  Transclude "em both.
            tab.$transcludeFn(tab.$parent, contents => {
                angular.forEach(contents, node => {
                    if (isTabHeading(node)) {
                        //Let tabHeadingTransclude know.
                        tab.headingElement = node;
                    } else {
                        element.append(node);
                    }
                });
            });

        }

        function isTabHeading(node) {
            return node.tagName && (
                node.hasAttribute("adv-tab-heading") ||
                node.hasAttribute("data-adv-tab-heading") ||
                node.hasAttribute("x-adv-tab-heading") ||
                node.tagName.toLowerCase() === "adv-tab-heading" ||
                node.tagName.toLowerCase() === "data-adv-tab-heading" ||
                node.tagName.toLowerCase() === "x-adv-tab-heading"
            );
        }

    }

    angular.module("app").directive("advTabContentTransclude", advTabContentTransclude);
}