// Install the angularjs.TypeScript.DefinitelyTyped NuGet package
namespace Advant.Crossroads {
    "use strict";

    interface IAdvPrecision extends angular.IDirective {
    }

    interface IAdvPrecisionScope extends angular.IScope {
    }

    interface IAdvPrecisionAttributes extends angular.IAttributes {
        advPrecision: string;
    }

    advPrecision.$inject = ["$parse"];
    function advPrecision($parse: angular.IParseService): IAdvPrecision {
        return {
            restrict: "A",
            require: "ngModel",
            link: link
        }

        function link(scope: IAdvPrecisionScope, element: angular.IAugmentedJQuery, attrs: IAdvPrecisionAttributes, ngModelCtrl: angular.INgModelController) {
            ngModelCtrl.$parsers.unshift((viewValue) => {
                if (viewValue) {
                    var maxPrecision = attrs.advPrecision;
                    var precisionRegex = new RegExp("^\\d+(\\.\\d{0," + maxPrecision + "})?$");
                    if (viewValue.match(precisionRegex)) {
                        ngModelCtrl.$setValidity("number", true);
                    } else {
                        ngModelCtrl.$setValidity("number", false);
                        return undefined;
                    }
                } else {
                    ngModelCtrl.$setValidity("number", true);
                }

                return viewValue;
            });
        }
    }

    angular.module("app").directive("advPrecision", advPrecision);
}