/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    export interface IConfirmationRouteParams extends angular.route.IRouteParamsService {
        userApplicationId: string;
    }

    interface IConfirmationScope extends angular.IScope {
        vm: Confirmation;
        applicationForm: angular.IFormController;
    }

    interface IConfirmationRootScope extends angular.IScope {
        stylesheets: Array<any>;
    }

    interface IConfirmation {
        loading: boolean;
        appDef: IApplicationDefinition;
        userApplication: IUserApplication;
    }

    class Confirmation implements IConfirmation {
        static controllerId: string = "confirmation";
        static $inject: any = [
            "$scope", "$rootScope", "$routeParams", "$location", "$sce", "$uibModal", "authentication",
            "common", "config", "userApplicationService", "Restangular"
        ];
        loading: boolean;
        errors: Array<any> = [];
        log: (msg, data?, showToast?) => void;
        logError: (msg, data?, showToast?) => void;
        appDef: IApplicationDefinition;
        userApplication: IUserApplication;
        response: angular.resource.IResource<any>;
        paymentInfo: angular.resource.IResource<any>;
        currentSection: string;
        userInfo: IUserInfoModel;
        logoPath: string;
        closed: boolean;
        majors: Array<IMajor>;
        majorLists: Array<IMajorList>;


        constructor(private $scope: IConfirmationScope,
            private $rootScope: IConfirmationRootScope,
            private $routeParams: IConfirmationRouteParams,
            private $location: angular.ILocationService,
            private $sce: angular.ISCEService,
            private $uibModal: angular.ui.bootstrap.IModalService,
            private authentication: IAuthenticationProviderService,
            private common: ICommonService,
            private config: Advant.Crossroads.ICrossroadsConfig,
            private userApplicationService: IUserApplicationService,
            private Restangular: Restangular.IService) {

            this.loading = true;

            this.authentication.authenticate().then((result) => {
                if (result) {
                    this.closed = false;
                    this.log = common.logger.getLogFn(LoginController.controllerId);
                    this.logError = common.logger.getLogFn(LoginController.controllerId, "error");


                    this.activate();
                    this.$rootScope.stylesheets = [];
                    var baseStyleSheet = {
                        link: "/content/application.css"
                    };

                    this.$rootScope.stylesheets.push(baseStyleSheet);
                    this.userInfo = this.authentication.getUser();
                }
                else {
                    return;
                }

            });
        }

        private activate() {
            var self = this;
            this.common.activateController([
                this.getAppResponse()
                    .then(this.getAppDef)
            ], Confirmation.controllerId)
                .then(result => {
                    self.loading = false;
                    self.log("Activated Confirmation Screen");
                });
        }

        getAppDef = () => {
            return this.Restangular.all("application")
                .customGET(this.userApplication.applicationDefinitionId).then((result) => {
                    this.log("Retrieved Application Definition");
                    this.appDef = result;
                    this.logoPath = this.config.apiHost + "/application/" + this.appDef.applicationDefinitionId + "/logo";

                    var appStyleSheet = {
                        link: this.config.apiHost + "/application/" + this.appDef.applicationDefinitionId + "/stylesheet"
                    };

                    this.$rootScope.stylesheets.push(appStyleSheet);

                }, (reason) => {
                    this.logError("Failed to Retrieve Application Definition", reason, true);
                });
        }

        getAppResponse = () => {
            return this.Restangular.one("userApplications", this.$routeParams.userApplicationId).get().then((result) => {
                this.log("Retrieved user's application response.");
                this.userApplication = result;
                this.response = result.applicationResponse;
            }, (reason) => {
                if (reason.data && reason.data.modelState && reason.data.modelState.applicationClosed) {
                    this.closed = true;
                } else {
                    this.logError("Failed to retrieve the user's application response.", reason);
                }
            });
        };

        openApplicationModal() {
            var appData = { appDef: this.appDef, userApp: this.userApplication };
            var modalInstance = this.$uibModal.open({
                templateUrl: '/app/application/applicationModal.html',
                controller: 'applicationModal',
                size: 'xlg',
                resolve: {
                    appData: function () { return appData; }
                }
            });

            modalInstance.result.then(() => { }, () => { });
        }
    }

    angular.module("app").controller(Confirmation.controllerId, Confirmation);
}