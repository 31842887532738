/// <reference path="../rules/rules.d.ts" />
/// <reference path="../rules/ruleservice.ts" />
/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    interface IAdvDocumentAttributes extends angular.IAttributes {
    }

    interface IAdvDocument extends angular.IDirective {

    }

    interface IAdvDocumentScope extends angular.IScope {
        field: IDocumentUploadField;
        viewLink: string;
        ngModel: any;
        userApplicationId: string;
        uploadDocument: ($files) => void;
        clearDocument: () => void;
        viewDocument: () => void;
        uploading: boolean;
        uploaded: boolean;
        modelController: angular.INgModelController;
        hasDocument: boolean;
    }

    class AdvDocument implements IAdvDocument {
        static directiveId: string = "advDocument";
        templateUrl: string = "/app/directives/advDocument.html";
        restrict: string = "E";
        require = "?ngModel";
        scope: any = {
            field: "=",
            ngModel: "=",
            userApplicationId: "="
        };

        constructor(private $http: angular.IHttpService, private Upload: angular.angularFileUpload.IUploadService, private config: Advant.Crossroads.ICrossroadsConfig, private helper: IHelper, private Restangular: Restangular.IService) {
        }

        link = (scope: IAdvDocumentScope, element, attrs: IAdvDocumentAttributes, ngModelController: angular.INgModelController) => {
            scope.uploaded = false;

            scope.$watch("field", (newValue: any, oldValue, originalScope) => {
                scope.viewLink = this.config.apiHost + "/" + scope.userApplicationId + "/attachment/" + scope.field.key;
            });

            scope.$watch("userApplicationId", (newValue: any, oldValue, originalScope) => {
                scope.viewLink = this.config.apiHost + "/" + scope.userApplicationId + "/attachment/" + scope.field.key;
                this.Restangular.one(scope.userApplicationId + "/attachment/" + scope.field.key + "/exists").get().then((result) => {
                    scope.uploaded = result;
                }, (reason) => {
                });
            });

            scope.uploadDocument = ($files: any): void => {
                scope.uploading = true;
                ngModelController.$setTouched();
                ngModelController.$setValidity("uploadType", true);
                ngModelController.$setValidity("uploadSize", true);

                var file = $files[0];
                if (file) {
                    var convertedSize = (file.size / 1024.0) / 1024.0;
                    if (convertedSize > scope.field.maxFileSizeInMB) {
                        ngModelController.$setValidity("uploadSize", false);
                        scope.uploading = false;
                    }
                    else if (scope.field.acceptedMimeTypes.split(",").indexOf(file.type) == -1) {
                        ngModelController.$setValidity("uploadType", false);
                        scope.uploading = false;
                    }
                    else {
                        this.Upload.upload(<any>{
                            url: this.config.apiHost + "/" + scope.userApplicationId + "/attachment/" + scope.field.key,
                            method: "POST",
                            file: file
                        }).then((response) => {
                            scope.ngModel = response.data;
                            scope.uploading = false;
                            scope.uploaded = true;
                        }, (reason) => {
                            scope.uploading = false;
                            if (reason.exceptionMessage && reason.exceptionMessage === "File type is not allowed") {
                                ngModelController.$setValidity("uploadType", false);
                            }
                            if (reason.exceptionMessage && reason.exceptionMessage.indexOf("File is too large") > -1) {
                                ngModelController.$setValidity("uploadSize", false);
                            }
                        });
                    }
                }

            };

            scope.viewDocument = (): void => {
                var httpPath = scope.viewLink;
                this.helper.openFile(httpPath);
            };
            scope.clearDocument = (): void => {
                scope.ngModel = null;
                scope.uploaded = false;
            };
        }
    }
    angular.module("app").directive(AdvDocument.directiveId, ["$http", "Upload", "config", "helper", "Restangular", ($http, Upload, config, helper, Restangular) =>
        new AdvDocument($http, Upload, config, helper, Restangular)
    ]);
}