/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    interface ILogoutModalScope extends angular.IScope {
        refreshToken: () => void;
        timer: number;
        tokenExpiresOn: any;
    }

    interface ILogoutModal {
        activate: () => void;

    }

    class LogoutModal implements ILogoutModal {
        static controllerId: string = "logoutModal";

        static $inject: string[] = ["$scope", "$location", "$uibModalInstance", "authentication", "$interval"];
        intervalPromise: angular.IPromise<any>;

        constructor(private $scope: ILogoutModalScope, private $location: angular.ILocationService, private $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance, private authentication: IAuthenticationProviderService, private $interval: angular.IIntervalService) {
            this.activate();
            $scope.refreshToken = this.refreshToken;
            $scope.timer = 2 * 60;
            $scope.tokenExpiresOn = new Date(sessionStorage[CrossroadsStorage.expiresOn]);
            this.intervalPromise = this.$interval(this.intervalLogout, 1000);
        }

        activate() {

        }

        intervalLogout = () => {
            if (this.$scope.timer == 0) {
                if (sessionStorage[CrossroadsStorage.expiresOn]) {
                    var expiresOn: any = new Date(sessionStorage[CrossroadsStorage.expiresOn]);
                    if ((expiresOn - this.$scope.tokenExpiresOn) == 0) {
                        this.authentication.logout();
                        this.$location.path("/login");
                    } else {
                        this.authentication.setLogoutTimer();
                    }
                } else {
                    this.authentication.logout();
                    this.$location.path("/login");
                }



                this.$uibModalInstance.close();
                this.$interval.cancel(this.intervalPromise);
            } else {
                this.$scope.timer = this.$scope.timer - 1;
            }

        };
        
        refreshToken = () => {
            this.$interval.cancel(this.intervalPromise);
            this.$uibModalInstance.close();
            this.authentication.refreshToken();

        };
    }

    angular.module("app").controller(LogoutModal.controllerId, LogoutModal);
}