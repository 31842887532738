namespace Advant.Crossroads {
    export interface ICrossRoadsRootScope extends angular.IRootScopeService {
        title: string;
    }

    export interface IRouteMediator {
        setRoutingHandlers: () => void;
    }

    export class RouteMediator implements IRouteMediator {
        static serviceId: string = "routemediator";

        // define the functions and properties to reveal.
        handleRouteChangeError = false;

        constructor(private $location: angular.ILocationService, private $rootScope: ICrossRoadsRootScope,
            private config: Advant.Crossroads.ICrossroadsConfig, private logger: ILogger) {

        }

        setRoutingHandlers() {
            this.updateDocTitle();
            this.handleRoutingErrors();
        }

        handleRoutingErrors() {
            this.$rootScope.$on("$routeChangeError",
                (event, current, previous, rejection) => {
                    if (this.handleRouteChangeError) { return; }
                    this.handleRouteChangeError = true;
                    var msg = "Error routing: " + (current && current.name)
                        + ". " + (rejection.msg || "");
                    this.logger.logWarning(msg, current, RouteMediator.serviceId, true);
                    // self.$location.path("/");
                });
        }

        updateDocTitle() {
            var self = this;
            self.$rootScope.$on("$routeChangeSuccess",
                (event, current, previous) => {
                    this.handleRouteChangeError = false;
                    var title = this.config.docTitle + " " + (current.title || "");
                    this.$rootScope.title = title;
                });
        }



    }


    angular.module("app").factory(RouteMediator.serviceId, ["$location","$rootScope", "config", "logger", ($location, $rootScope, config, logger) =>
        new RouteMediator($location, $rootScope, config, logger)
    ]);
}
