
namespace Advant.Crossroads {
    "use strict";

    // configure by setting an optional string value for appErrorPrefix.
    // Accessible via config.appErrorPrefix (via config value).
    function extendExceptionHandler($delegate: any, config: any, logger: any) {
        var appErrorPrefix = config.appErrorPrefix;
        var logError = logger.getLogFn("app", "error");
        return (exception, cause) => {
            $delegate(exception, cause);
            if (appErrorPrefix && exception.message && exception.message.indexOf(appErrorPrefix) === 0) { return; }

            var errorData = { exception: exception, cause: cause };
            var msg = appErrorPrefix + exception.message;
            logError(msg, errorData, true);
        };
    }

    angular.module("app").config(["$provide", $provide => {
        $provide.decorator("$exceptionHandler",
            ["$delegate", "config", "logger", extendExceptionHandler]);
    }]);

    // extend the $exceptionHandler service to also display a toast.
}