namespace Advant.Crossroads {
    interface IHomeScope extends ng.IScope {
        vm: Home;
    }

    interface IHome {

        errors: Array<any>;
        log: (msg, data?, showToast?) => void;
    }

    class Home implements IHome {
        static controllerId: string = "home";
        static $inject: any = ["$scope", "authentication", "common"];
        errors: Array<any> = [];
        log: (msg, data?, showToast?) => void;

        constructor(private $scope: IHomeScope, private authentication: IAuthenticationProviderService, private common: ICommonService) {
            this.log = common.logger.getLogFn(LoginController.controllerId);
            this.activate();
        }

        private activate() {
            var self = this;
            this.common.activateController([], Home.controllerId)
                .then(result=> {
                    self.log("Activated Home Screen");
                });
        }
    }


    angular.module("app").controller(Home.controllerId, Home);
}