namespace Advant.Crossroads {

    export interface ISchoolDistrictLookup {
        log: (msg, data?, showToast?) => void;
        logError: (msg, data?, showToast?) => void;
        logSuccess: (msg, data?, showToast?) => void;
        loadingHighSchools: boolean;
        page: number;
        pageSize: number;
        totalCount: number;
        sortByRelevance: boolean;
        sortBy: string;
        sortDirection: string;
        totalPages: number;
        startRow: number;
        endRow: number;
        searchFilter: string;

        getSchoolDistricts: () => angular.IPromise<ISchoolDistrict[]>;
    }

    export class SchoolDistrictLookup implements ISchoolDistrictLookup {
        static serviceId: string = "schoolDistrictLookup";
        static $inject = ["Restangular", "common"];
        log: (msg, data?, showToast?) => void;
        logError: (msg, data?, showToast?) => void;
        logSuccess: (msg, data?, showToast?) => void;
        loadingHighSchools: boolean;
        page: number;
        pageSize: number;
        totalCount: number;
        sortByRelevance: boolean;
        sortBy: string;
        sortDirection: string;
        totalPages: number;
        startRow: number;
        endRow: number;
        searchFilter: string;

        constructor(private Restangular: Restangular.IService, private common: ICommonService) {
            this.log = common.logger.getLogFn(SchoolDistrictLookup.serviceId);
            this.logError = common.logger.getLogFn(SchoolDistrictLookup.serviceId, "error");
            this.logSuccess = common.logger.getLogFn(SchoolDistrictLookup.serviceId, "success");

            this.page = 1;
            this.pageSize = 25;
            this.sortByRelevance = false;
            this.sortBy = "LEANameSorting";
            this.sortDirection = "ASC";
            this.searchFilter = "";
        }

        getSchoolDistricts = (): angular.IPromise<ISchoolDistrict[]> => {
            this.loadingHighSchools = true;
            var queryParams = {
                page: this.page,
                pageSize: this.pageSize,
                sortByRelevance: this.sortByRelevance,
                sortBy: this.sortBy,
                sortDirection: this.sortDirection,
                filter: this.searchFilter
            };

            return this.Restangular.one("lookup").one("school").withHttpConfig({ cache: true }).getList("districts", queryParams).then((result: any) => {
                this.totalCount = result.totalCount;
                this.totalPages = result.totalPages;

                if (result.totalCount > 0) {
                    this.startRow = this.page === 1 ? 1 : (this.pageSize * (this.page - 1)) + 1;
                    this.endRow = (this.startRow - 1) + result.length;
                } else {
                    this.startRow = 0;
                    this.endRow = 0;
                }
                this.loadingHighSchools = false;
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting the school districts", reason);
            });
        };

        searchSchoolDistricts = (): void => {
            _.debounce(() => {
                this.sortByRelevance = true;
                this.sortBy = null;
                this.getSchoolDistricts();
            }, 500);
        };

        sort = (sortValue: string): void => {
            if (this.sortBy === sortValue) {
                this.sortDirection = this.sortDirection === "ASC" ? "DESC" : "ASC";
            } else {
                this.sortBy = sortValue;
            }
            this.page = 1;
            this.searchFilter = "";
            this.sortByRelevance = false;
            this.getSchoolDistricts();
        };

    }
    angular.module("app").service(SchoolDistrictLookup.serviceId, SchoolDistrictLookup);
}