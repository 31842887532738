/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    interface IApplicationModalScope extends ng.IScope {
        close: () => void;
        userApplication: any;
        applicationDefinition: any;
    }

    interface IApplicationModal {

    }

    interface IApplicationData { userApp: Advant.Crossroads.IUserApplicationViewModel; appDef: Advant.Crossroads.IApplicationDefinition }

    class ApplicationModal implements IApplicationModal {
        static $inject: Array<string> = ["$uibModalInstance", "common", "$scope", "Restangular", "appData"];
        static controllerId: string = "applicationModal";
        userApplication: any;
        applicationDefinition: any;
        logError: (msg, data?, showHowl?) => void;

        constructor(private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
            private common: ICommonService,
            private $scope: IApplicationModalScope,
            private Restangular: Restangular.IService,
            appData: IApplicationData) {
            this.logError = common.logger.getLogFn(ApplicationModal.controllerId, "error");
            $scope.close = () => this.close();
            $scope.userApplication = appData.userApp;            
            $scope.applicationDefinition = appData.appDef;            
        }

        close() {            
            this.$uibModalInstance.dismiss();
        }
    }

    angular.module("app").controller(ApplicationModal.controllerId, ApplicationModal);
}