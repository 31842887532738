/// <reference path="../rules/ruleservice.ts" />
/// <reference path="../app.ts" />

namespace Advant.Crossroads {
    "use strict";

    interface IAdvShowHide extends angular.IDirective {
    }

    interface IAdvShowHideScope extends angular.IScope {
        advResponse: any;
    }

    advShowHide.$inject = ["$parse", "$timeout", "ruleService"];
    function advShowHide($parse: angular.IParseService,
        $timeout: angular.ITimeoutService,
        ruleService: Rules.IRuleService): IAdvShowHide {

        var link = (scope: IAdvShowHideScope, element: angular.IAugmentedJQuery, attrs, controller, transclude) => {
            var displayRule: IDynamicDisplayRule = $parse(attrs.advShowHide)(scope);
            if (displayRule) {
                var ruleProperties = ruleService.getPropertiesToWatch(displayRule.rule);

                angular.forEach(ruleProperties, (ruleProperty) => {
                    scope.$parent.$watch(attrs.advResponse + "." + ruleProperty, (newValue, oldValue, originalScope) => {
                        var localResponse = $parse(attrs.advResponse)(originalScope);
                        var result = ruleService.evalRuleSet(displayRule.rule, localResponse);

                        if (result) {
                            if (displayRule.display === "Show") {
                                element.show();
                            } else {
                                element.hide();
                            }
                        } else {
                            if (displayRule.display === "Show") {
                                element.hide();
                            } else {
                                element.show();
                            }
                        }

                    }, true);
                });
            }
        };

        return {
            restrict: "A",
            priority: 999,
            link: link
        }
    }


    angular.module("app").directive("advShowHide", advShowHide);
}