/// <reference path="../app.ts" />
namespace Advant.Crossroads {

    export interface IAuthInterceptor {
        responseError: (rejection) => angular.IPromise<any>;
    }

    export class AuthInterceptor implements IAuthInterceptor {
        static serviceId: string = "authInterceptor";


        constructor(private $q: angular.IQService, private $injector: angular.auto.IInjectorService, private $location: angular.ILocationService) {
        }

        request = (config) => {
            config.headers = config.headers || {};
            var authService: IAuthenticationProviderService = this.$injector.get<IAuthenticationProviderService>("authentication");

            if (authService.accessToken()) {
                config.headers.Authorization = "Bearer " + authService.accessToken();
            }

            return config;

        };

        responseError = (rejection) => {
            var deferred = this.$q.defer();
            if (rejection.status === 401) {
                var authService: IAuthenticationProviderService = this.$injector.get<IAuthenticationProviderService>("authentication");
                this.throttledRefresh().then((response) => {
                    this.retryHttpRequest(rejection.config).then((result) => {
                        deferred.resolve(result);
                    }, (reason) => {
                        authService.logout();
                        deferred.reject(rejection);
                    });
                }, () => {
                    authService.logout();
                    deferred.reject(rejection);
                });

            } else {
                deferred.reject(rejection);
            }
            return deferred.promise;
        };

        throttledRefresh = _.throttle(this.refreshToken, 400, { "trailing": false });


        private refreshToken(): angular.IPromise<any> {
            var deferred = this.$q.defer();
            var authService: IAuthenticationProviderService = this.$injector.get<IAuthenticationProviderService>("authentication");
            authService.refreshToken().then((response) => {
                deferred.resolve(response);
            }, (reason) => {
                deferred.reject(reason);
            });

            return deferred.promise;
        }


        private retryHttpRequest = (config) => {
            var deferred = this.$q.defer();
            var $http = this.$injector.get<any>("$http");
            $http(config).then((response) => {
                deferred.resolve(response);
            }, (response) => {
                deferred.reject(response);
            });

            return deferred.promise;
        };


    }


    angular.module("app").factory(AuthInterceptor.serviceId, [
        "$q", "$injector", "$location", ($q, $injector, $location) =>
            new AuthInterceptor($q, $injector, $location)
    ]);
}
