/// <reference path="../services/advant-auth.ts" />

interface ICommon extends angular.IModule { }

// create the module and define its dependencies.
var common: ICommon = angular.module("common", ["authentication"]);


/* tslint:disable: no-empty */
// execute bootstrapping code and any dependencies.
common.run(["$q", "$rootScope", ($q, $rootScope) => {

}]);

