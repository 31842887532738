/// <reference path="../rules/rules.d.ts" />
/// <reference path="../rules/ruleservice.ts" />
/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    interface IAdvTab extends angular.IDirective {
    }

    interface IAdvTabScope extends angular.IScope {
        disabled: boolean;
        index: number;
        select: () => void;
        $transcludeFn: Function;
    }

    interface IAdvTabAttributes extends angular.IAttributes {
        disable: string;
        index: string;
        displayRule: string;
        advResponse: string;
        templateUrl: string;
    }

    advTab.$inject = ["$parse", "ruleService"];
    function advTab($parse: angular.IParseService, ruleService: Rules.IRuleService): IAdvTab {
        return {
            require: "^advTabset",
            replace: true,
            templateUrl: (element, attrs: IAdvTabAttributes) => {
                return attrs.templateUrl || "adv/template/tabs/tab.html";
            },
            transclude: true,
            scope: {
                heading: "@",
                index: "=?",
                onSelect: "&select", //This callback is called in contentHeadingTransclude
                //once it inserts the tab"s content into the dom
                onDeselect: "&deselect"
            },
            controller: () => {
                //Empty controller so other directives can require being "under" a tab
            },
            controllerAs: "tab",
            link: link
        }

        function link(scope: IAdvTabScope, element: angular.IAugmentedJQuery, attrs: IAdvTabAttributes, tabsetCtrl, transclude) {
            scope.disabled = false;
            if (attrs.disable) {
                scope.$parent.$watch($parse(attrs.disable), (value) => {
                    scope.disabled = !!value;
                });
            }

            if (angular.isUndefined(attrs.index)) {
                if (tabsetCtrl.tabs && tabsetCtrl.tabs.length) {
                    scope.index = Math.max.apply(null, tabsetCtrl.tabs.map((t) => { return t.index; })) + 1;
                } else {
                    scope.index = 0;
                }
            }

            scope.select = () => {
                if (!scope.disabled) {
                    var index;
                    for (var i = 0; i < tabsetCtrl.tabs.length; i++) {
                        if (tabsetCtrl.tabs[i].tab === scope) {
                            index = i;
                            break;
                        }
                    }

                    tabsetCtrl.select(index);
                }
            };


            var displayRule: IDynamicDisplayRule = $parse(attrs.displayRule)(scope.$parent);
            if (displayRule) {
                var ruleProperties = ruleService.getPropertiesToWatch(displayRule.rule);

                angular.forEach(ruleProperties, (ruleProperty) => {
                    scope.$parent.$watch(attrs.advResponse + "." + ruleProperty, (newValue, oldValue, originalScope: IAdvTabScope) => {
                        var localResponse = $parse(attrs.advResponse)(originalScope);
                        var result = ruleService.evalRuleSet(displayRule.rule, localResponse);

                        if (result) {
                            if (displayRule.display === "Show") {
                                element.show();
                                tabsetCtrl.addTab(scope);
                            } else {
                                element.hide();
                                tabsetCtrl.removeTab(scope);
                            }
                        } else {
                            if (displayRule.display === "Show") {
                                element.hide();
                                tabsetCtrl.removeTab(scope);
                            } else {
                                element.show();
                                tabsetCtrl.addTab(scope);
                            }
                        }

                    }, true);
                });
            } else {
                tabsetCtrl.addTab(scope);
            }
            scope.$on("$destroy", () => {
                tabsetCtrl.removeTab(scope);
            });

            //We need to transclude later, once the content container is ready.
            //when this link happens, we"re inside a tab heading.
            scope.$transcludeFn = transclude;

        }
    }

    angular.module("app").directive("advTab", advTab);
}