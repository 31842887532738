namespace Advant.Crossroads {
    "use strict";

    interface IAdvInfiniteScroll extends angular.IDirective {
    }

    interface IAdvInfiniteScrollScope extends angular.IScope {

    }

    class AdvInfiniteScroll implements IAdvInfiniteScroll {
        static directiveId: string = "advInfiniteScroll";
        restrict: string = "A";

        constructor(private $timeout: angular.ITimeoutService) {
        }

        link = (scope: IAdvInfiniteScrollScope, element, attrs) => {
            var lengthThreshold = attrs.scrollThreshold || 50;
            var timeThreshold = attrs.timeThreshold || 400;
            var handler = scope.$eval(attrs.advInfiniteScroll);
            var promise = null;
            var lastRemaining = 9999;

            lengthThreshold = parseInt(lengthThreshold, 10);
            timeThreshold = parseInt(timeThreshold, 10);


            if (!handler || !angular.isFunction(handler)) {
                scope.$watch(attrs.advInfiniteScroll,(newValue) => {
                    handler = newValue;

                    if (!handler || !angular.isFunction(handler)) {
                        handler = angular.noop;
                    }
                });
                handler = angular.noop;
            }

            element.bind("scroll",() => {
                var remaining = element[0].scrollHeight - (element[0].clientHeight + element[0].scrollTop);

                //if we have reached the threshold and we scroll down
                if (remaining < lengthThreshold && (remaining - lastRemaining) < 0) {

                    //if there is already a timer running which has no expired yet we have to cancel it and restart the timer
                    if (promise !== null) {
                        this.$timeout.cancel(promise);
                    }
                    promise = this.$timeout(() => {
                        handler();
                        promise = null;
                    }, timeThreshold);
                }
                lastRemaining = remaining;
            });

        };
    }


    // Update the app1 variable name to be that of your module variable
    angular.module("app").directive(AdvInfiniteScroll.directiveId, ["$timeout", $timeout =>
        new AdvInfiniteScroll($timeout)
    ]);
}