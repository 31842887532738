namespace Advant.Crossroads {

    interface IAdvCcPayment extends angular.IDirective {
    }

    interface IAdvCcPaymenScope extends ng.IScope {
        onCreate: any;
        authorizeSession: any;

    }

    class AdvCcPayment implements IAdvCcPayment {
        static directiveId: string = "advCcPayment";
        templateUrl = "/app/directives/advCcPayment.html";
        restrict: string = "E";
        scope: any = {
            authorizeSession: "=",
            onCreate: "="
        };
        constructor(private helper: IHelper) {
        }

        config = {
            fields: {
                card: {
                    selector: "[data-cc-card]",
                },
                cvv: {
                    selector: "[data-cc-cvv]",
                },
                exp: {
                    selector: "[data-cc-exp]",
                },
                name: {
                    selector: "[data-cc-name]",
                    placeholder: "Full Name",
                }
            },
            // css classes to be injected into the iframes.
            // the properties allowed are restricted via whitelist.
            // further, unrestricted styling can be applied to the div's in which the iframes are injected.

            styles: {
                input: {
                    'font-size': "14px",
                    color: "rgb(85,85,85)",
                    background: "black",
                },
                '.card': {
                    'font-family': "monospace",
                },
                '.valid': {
                    color: "#43B02A",
                },
                '.invalidcc': {
                    color: "#C01324",
                },
                '@media screen and (max-width: 700px)': {
                    input: {
                        'font-size': "14px",
                    }
                },
                'input:-webkit-autofill': {
                    '-webkit-box-shadow': "0 0 0 50px white inset"
                },
                'input.valid:-webkit-autofill': {
                    '-webkit-text-fill-color': "#43B02A"
                },
                'input.invalid:-webkit-autofill': {
                    '-webkit-text-fill-color': "#C01324"
                },
                'input::placeholder': {
                    color: "#aaa"
                }
            },
            // these values correspond to css class names defined above
            classes: {
                empty: "empty",
                focus: "focus",
                invalid: "invalidcc",
                valid: "valid"
            }
        };



        link = (scope: IAdvCcPaymenScope, element, attrs) => {
            const hooks = {

                preFlowHook: scope.authorizeSession,

            };

            (window as any).firstdata.createPaymentForm(this.config, hooks, scope.onCreate);
        };
    }

    angular.module("app").directive(AdvCcPayment.directiveId, ["helper", helper =>
        new AdvCcPayment(helper)
    ]);
}