/// <reference path="../rules/ruleservice.ts" />
/// <reference path="../app.ts" />

namespace Advant.Crossroads {
    "use strict";

    interface IAdvDisplay extends angular.IDirective {
    }

    interface IAdvDisplayScope extends angular.IScope {
        section: ISection;
        group: IGroup;
        advResponse: any;
    }

    advDisplay.$inject = ["$parse", "$timeout", "ruleService"];
    function advDisplay($parse: angular.IParseService,
        $timeout: angular.ITimeoutService,
        ruleService: Rules.IRuleService): IAdvDisplay {

        var link = (scope: IAdvDisplayScope, element: angular.IAugmentedJQuery, attrs, controller, transclude) => {
            var block: { clone }, childScope: any, previousElements;

            var displayRule: IDynamicDisplayRule = $parse(attrs.advDisplay)(scope);

            if (displayRule) {
                var ruleProperties = ruleService.getPropertiesToWatch(displayRule.rule);

                angular.forEach(ruleProperties, (ruleProperty) => {
                    scope.$watch(attrs.advResponse + "." + ruleProperty, (newValue, oldValue, originalScope: IAdvDisplayScope) => {
                        showHide(originalScope);
                    }, true);
                });
            }

            var ELEMENT_NODE = 1;

            var extractElementNode = (element) => {
                for (var i = 0; i < element.length; i++) {
                    var elm = element[i];
                    if (elm.nodeType === ELEMENT_NODE) {
                        return elm;
                    }
                }
            }

            var domInsert = (element, parentElement, afterElement) => {
                // if for some reason the previous element was removed
                // from the dom sometime before this code runs then let's
                // just stick to using the parent element as the anchor
                if (afterElement) {
                    var afterNode = extractElementNode(afterElement);
                    if (afterNode && !afterNode.parentNode && !afterNode.previousElementSibling) {
                        afterElement = null;
                    }
                }
                afterElement ? afterElement.after(element) : parentElement.prepend(element);
            }

            /**
         * Return the DOM siblings between the first and last node in the given array.
         * @param {Array} array like object
         * @returns {Array} the inputted object or a jqLite collection containing the nodes
         */
            function getBlockNodes(nodes) {
                // TODO(perf): update `nodes` instead of creating a new object?
                var node = nodes[0];
                var endNode = nodes[nodes.length - 1];
                var blockNodes;
                var slice = [].slice;

                for (var i = 1; node !== endNode && (node = node.nextSibling); i++) {
                    if (blockNodes || nodes[i] !== node) {
                        if (!blockNodes) {
                            blockNodes = jQuery(slice.call(nodes, 0, i));
                        }
                        blockNodes.push(node);
                    }
                }

                return blockNodes || nodes;
            }

            var show = () => {
                if (!childScope) {
                    transclude((clone, newScope) => {
                        childScope = newScope;
                        // Note: We only need the first/last node of the cloned nodes.
                        // However, we need to keep the reference to the jqlite wrapper as it might be changed later
                        // by a directive with templateUrl when its template arrives.
                        block = {
                            clone: clone
                        };
                        domInsert(clone, element.parent(), element);

                    });
                }
            };

            var hide = () => {
                if (previousElements) {
                    previousElements.remove();
                    previousElements = null;
                }
                if (childScope) {
                    childScope.$destroy();
                    childScope = null;
                }
                if (block) {
                    previousElements = getBlockNodes(block.clone);
                    previousElements.remove();
                    previousElements = null;
                    block = null;
                }
            };

            var ELEMENT_NODE = 1;

            var domInsert = (element, parentElement, afterElement) => {
                // if for some reason the previous element was removed
                // from the dom sometime before this code runs then let's
                // just stick to using the parent element as the anchor
                if (afterElement) {
                    var afterNode = extractElementNode(afterElement);
                    if (afterNode && !afterNode.parentNode && !afterNode.previousElementSibling) {
                        afterElement = null;
                    }
                }
                afterElement ? afterElement.after(element) : parentElement.prepend(element);
            }

            var extractElementNode = (element) => {
                for (var i = 0; i < element.length; i++) {
                    var elm = element[i];
                    if (elm.nodeType === ELEMENT_NODE) {
                        return elm;
                    }
                }
            }




            var showHide = (originalScope: IAdvDisplayScope) => {
                scope.$evalAsync(() => {
                    var localResponse = $parse(attrs.advResponse)(originalScope);
                    var result = ruleService.evalRuleSet(displayRule.rule, localResponse);
                    if (result) {
                        if (displayRule.display === "Show") {
                            show();
                        } else {
                            hide();
                        }
                    } else {
                        if (displayRule.display === "Show") {
                            hide();

                        } else {
                            show();
                        }
                    }
                });
            };

        };

        return {
            restrict: "A",
            transclude: "element",
            priority: 999,
            link: link
        }
    }


    angular.module("app").directive("advDisplay", advDisplay);
}