angular.module("app")
    .constant("advAccordionConfig",
    {
        closeOthers: true
    });

angular.module("app")
    .controller("AdvAccordionController",
    [
        "$scope", "$attrs", "advAccordionConfig", function($scope, $attrs, accordionConfig) {
            // This array keeps track of the accordion groups
            this.groups = [];

            // Ensure that all the groups in this accordion are closed, unless close-others explicitly says not to
            this.closeOthers = function(openGroup) {
                var closeOthers = angular.isDefined($attrs.closeOthers)
                    ? $scope.$eval($attrs.closeOthers)
                    : accordionConfig.closeOthers;
                if (closeOthers) {
                    angular.forEach(this.groups,
                        function(group) {
                            if (group !== openGroup) {
                                group.isOpen = false;
                            }
                        });
                }
            };

            // This is called from the accordion-group directive to add itself to the accordion
            this.addGroup = function(groupScope) {
                this.groups.push(groupScope);

                groupScope.$on("$destroy",
                    event => {
                        this.removeGroup(groupScope);
                    });
            };

            // This is called from the accordion-group directive when to remove itself
            this.removeGroup = function(group) {
                var index = this.groups.indexOf(group);
                if (index !== -1) {
                    this.groups.splice(index, 1);
                }
            };
        }
    ])

// The accordion directive simply sets up the directive controller
// and adds an accordion CSS class to itself element.
    .directive("advAccordion",
        () => ({
            controller: "AdvAccordionController",
            controllerAs: "accordion",
            transclude: true,
            templateUrl(element, attrs:any) {
                return attrs.templateUrl || "/app/directives/advAccordion.html";
            }
        }));
