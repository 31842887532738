/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict";


    interface ILoggedOutScope extends ng.IScope {
        vm: LoggedOut;
    }

    interface ILoggedOutRootScope extends ng.IScope {
        stylesheets: Array<any>;
    }

    interface ILoggedOut {
        
    }

    class LoggedOut implements ILoggedOut {
        static controllerId: string = "loggedOut";
        static $inject: any = ["$rootScope", "common"];
        


        constructor(private $rootScope: ILoggedOutRootScope, private common: ICommonService) {
            
            this.activate();
            this.$rootScope.stylesheets = [];
            var baseStyleSheet = {
                link: "/content/application.css"
            };

            this.$rootScope.stylesheets.push(baseStyleSheet);
            
        }

        private activate() {
            var self = this;
            this.common.activateController([], LoggedOut.controllerId);
        }

        private closeWindow() {
            window.close();
        }

      
    }

    angular.module("app").controller(LoggedOut.controllerId, LoggedOut);
}