namespace Advant.Crossroads {

    export interface IHighSchoolLookup {
        log: (msg, data?, showToast?) => void;
        logError: (msg, data?, showToast?) => void;
        logSuccess: (msg, data?, showToast?) => void;
        loadingHighSchools: boolean;
        page: number;
        pageSize: number;
        totalCount: number;
        sortByRelevance: boolean;
        sortBy: string;
        sortDirection: string;
        totalPages: number;
        startRow: number;
        endRow: number;
        searchFilter: string;
        state: string;
        district: string;

        getHighSchools: () => angular.IPromise<IHighSchool[]>;
    }

    export class HighSchoolLookup implements IHighSchoolLookup {
        static serviceId: string = "highSchoolLookup";
        static $inject = ["Restangular", "common"];
        log: (msg, data?, showToast?) => void;
        logError: (msg, data?, showToast?) => void;
        logSuccess: (msg, data?, showToast?) => void;
        loadingHighSchools: boolean;
        page: number;
        pageSize: number;
        totalCount: number;
        sortByRelevance: boolean;
        sortBy: string;
        sortDirection: string;
        totalPages: number;
        startRow: number;
        endRow: number;
        searchFilter: string;
        state: string;
        district: string;

        constructor(private Restangular: Restangular.IService, private common: ICommonService) {
            this.log = common.logger.getLogFn(HighSchoolLookup.serviceId);
            this.logError = common.logger.getLogFn(HighSchoolLookup.serviceId, "error");
            this.logSuccess = common.logger.getLogFn(HighSchoolLookup.serviceId, "success");

            this.page = 1;
            this.pageSize = 25;
            this.sortByRelevance = false;
            this.sortBy = "Name";
            this.sortDirection = "ASC";
            this.searchFilter = "";
        }

        getHighSchools = (): angular.IPromise<IHighSchool[]> => {
            this.loadingHighSchools = true;
            var queryParams = {
                page: this.page,
                pageSize: this.pageSize,
                sortByRelevance: this.sortByRelevance,
                sortBy: this.sortBy,
                sortDirection: this.sortDirection,
                filter: this.searchFilter,
                state: this.state,
                district: this.district
            };

            return this.Restangular.one("lookup").one("school").withHttpConfig({ cache: true }).getList("highSchools", queryParams).then((result: any) => {
                this.totalCount = result.totalCount;
                this.totalPages = result.totalPages;

                if (result.totalCount > 0) {
                    this.startRow = this.page === 1 ? 1 : (this.pageSize * (this.page - 1)) + 1;
                    this.endRow = (this.startRow - 1) + result.length;
                } else {
                    this.startRow = 0;
                    this.endRow = 0;
                }
                this.loadingHighSchools = false;
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting the high schools", reason);
            });
        };

        searchHighSchools = (): void => {
            _.debounce(() => {
                this.sortByRelevance = true;
                this.sortBy = null;
                this.getHighSchools();
            }, 500);
        };

        sort = (sortValue: string): void => {
            if (this.sortBy === sortValue) {
                this.sortDirection = this.sortDirection === "ASC" ? "DESC" : "ASC";
            } else {
                this.sortBy = sortValue;
            }
            this.page = 1;
            this.searchFilter = "";
            this.sortByRelevance = false;
            this.getHighSchools();
        };

    }
    angular.module("app").service(HighSchoolLookup.serviceId, HighSchoolLookup);
}