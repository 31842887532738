namespace Advant.Crossroads {
    "use strict";

    export interface IUserLink extends ng.IDirective {

    }

    export interface IUserLinkScope extends ng.IScope {
        userInfo: IUserInfoModel;
        logoff();
    }

    export class UserLink implements IUserLink {
        static directiveId: string = "userLink";
        restrict: string = "E";
        replace: boolean = true;
        scope: any = {};
        templateUrl: string = "/app/directives/userLink.html";

        constructor(private $rootScope: ng.IRootScopeService,
            private $timeout: ng.ITimeoutService,
            private config: ICrossroadsConfig,
            private authentication: AuthenticationProviderService) {
        }

        link = (scope: IUserLinkScope, element?, attr?) => {
            var self = this;
            scope.userInfo = self.authentication.getUser();
            scope.logoff = () => {
                self.authentication.logout().then(result => {
                    scope.userInfo = undefined;
                });
            };

            this.$rootScope.$on(this.config.events.login,
                (event, data) => {
                    this.$timeout(() => {
                        var eventData: any = data; // converts the IAngularEvent back to a dynamic type so we can view the "show" property that was set by the ICommonService
                        scope.userInfo = eventData.userInfo;
                    });
                });
            this.$rootScope.$on(this.config.events.reloadUser,
                (event, data) => {
                    var eventData: any = data; // converts the IAngularEvent back to a dynamic type so we can view the "show" property that was set by the ICommonService
                    this.$timeout(() => {
                        scope.userInfo = eventData.userInfo;
                    });
                });
            this.$rootScope.$on(this.config.events.logout,
                () => {
                    this.$timeout(() => {
                        scope.userInfo = null;
                    });
                });
        }
    }

    angular.module("app").directive(UserLink.directiveId, ["$rootScope", "$timeout", "config", "authentication", ($rootScope, $timeout, config, authentication) =>
        new UserLink($rootScope, $timeout, config, authentication)
    ]);
}