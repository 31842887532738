/// <reference path="../app.ts" />
namespace Advant.Crossroads {

    export interface IResourceClassWithUpdateAndSubmit<T> extends angular.resource.IResourceClass<T> {
        update(): T;
        update(dataOrParams: any): T;
        update(dataOrParams: any, success: Function): T;
        update(success: Function, error?: Function): T;
        update(params: any, data: any, success?: Function, error?: Function): T;
        submit(): T;
        submit(dataOrParams: any): T;
        submit(dataOrParams: any, success: Function): T;
        submit(success: Function, error?: Function): T;
        submit(params: any, data: any, success?: Function, error?: Function): T;
    }
    export interface IResourceClassWithSubmit<T> extends angular.resource.IResourceClass<T> {
        submit(): T;
        submit(dataOrParams: any): T;
        submit(dataOrParams: any, success: Function): T;
        submit(success: Function, error?: Function): T;
        submit(params: any, data: any, success?: Function, error?: Function): T;
    }

    export interface IUserApplicationService {
        applicationDefiniton: angular.resource.IResourceClass<angular.resource.IResource<any>>;
        applicationResponse: IResourceClassWithUpdateAndSubmit<angular.resource.IResource<any>>;
        paymentInfo: IResourceClassWithSubmit<angular.resource.IResource<any>>;

    }

    export class UserApplicationService implements IUserApplicationService {
        public static serviceId: string = "userApplicationService";
        public applicationDefiniton: angular.resource.IResourceClass<angular.resource.IResource<any>>;
        public applicationResponse: IResourceClassWithUpdateAndSubmit<angular.resource.IResource<any>>;
        public paymentInfo: IResourceClassWithSubmit<angular.resource.IResource<any>>;

        constructor(private $http: angular.IHttpService, private $resource: angular.resource.IResourceService) {
            this.applicationDefiniton = $resource("/api/UserApplication/definition/:org/:prog/:app");
            this.applicationResponse = <IResourceClassWithUpdateAndSubmit<angular.resource.IResource<any>>>$resource("/api/UserApplication/:org/:prog/:app", null, {
                update: { method: "PUT" },
                submit: {
                    method: "PUT",
                    url: "/api/UserApplication/:org/:prog/:app/submit"
                }
            });

            this.paymentInfo = <IResourceClassWithSubmit<angular.resource.IResource<any>>>$resource("/api/UserApplication/:org/:prog/:app/payment", null, {
                submit: { method: "PUT" }
            });
        }

    }


    angular.module("app").factory(UserApplicationService.serviceId, ["$http", "$resource", ($http, $resource) =>
        new UserApplicationService($http, $resource)
    ]);
}