/// <reference path="crossroadsstorage.ts" />
namespace Advant.Crossroads {
    /* tslint:disable: no-string-literal */
    export interface IAuthUrls {
        apiHost: string;
        site: string;
        register: string;
        login: string;
        logout: string;
        userInfo: string;
        changePassword: string;
        forcePasswordChange: string;
        externalLogins: string;
        registerExternal: string;
        cfncLogin: string;
        exchangeToken: string;
    }

    export interface IAuthenticationFactory {
        getUserInfo(accessToken: string): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        login(data: ILoginModel): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        refreshToken(data: IRefreshTokenModel): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        logout(accessToken: string): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        register(data: RegistrationModel): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        changePassword(data: any): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        forcePasswordChange(data: any): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        getExternalLogins(clientId: string): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        registerExternal(accesToken: string, data: any): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        getToken(clientId: string): angular.IPromise<ng.IHttpPromiseCallbackArg<any>>;
    }

    export interface IAuthenticationProviderUrls {
        login: string;
        registerExternal: string;
        postLogout: string;
        home: string;
        cfncHostLogin: string;
    }

    export interface IAuthenticationEvents {
        login: string;
        logout: string;
        register: string;
        reloadUser: string;
        closeOAuthWindow: string;
    }

    export interface IAuthenticationProviderService {
        user: any;
        externalUser: any;
        externalLogins: Array<any>;
        cfncLogin(accessToken): angular.IPromise<ng.IHttpPromiseCallbackArg<any>>;
        login(data: ILoginModel): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        refreshToken(): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        loginWithExternal(login: IExternalLoginViewModel, data: ILoginModel): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        logout(): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        register(data: RegistrationModel): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        registerExternal(): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        changePassword(data: any): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        forcePasswordChange(data: any): angular.IPromise<angular.IHttpPromiseCallbackArg<any>>;
        authenticate(): angular.IPromise<ng.IHttpPromiseCallbackArg<boolean>>;
        redirectAuthenticated(url: string): void;
        getUser(): IUserInfoModel;
        accessToken: (accessToken?: string, refreshToken?: string, persist?: boolean) => string;
        setLogoutTimer: () => void;
        checkLogin: () => void;
    }

    //  angular authorization factory
    export class AuthenticationFactory implements IAuthenticationFactory {
        private formHeader: any;

        static $inject: any = ["$http", "authentication.urls"];

        constructor(private $http: angular.IHttpService, private urls: IAuthUrls) {
            this.formHeader = { "Content-Type": "application/x-www-form-urlencoded" };

            var api = this.urls.apiHost;
            if (api) {
                this.urls.login = api + this.urls.login;
                this.urls.register = api + this.urls.register;
                this.urls.logout = api + this.urls.logout;
                this.urls.userInfo = api + this.urls.userInfo;
                this.urls.changePassword = api + this.urls.changePassword;
                this.urls.forcePasswordChange = api + this.urls.forcePasswordChange;
                this.urls.externalLogins = api + this.urls.externalLogins;
                this.urls.registerExternal = api + this.urls.registerExternal;
                this.urls.cfncLogin = api + this.urls.cfncLogin;
                this.urls.exchangeToken = api + this.urls.exchangeToken;
            }
        }

        // Form Encode login data
        private formEncode(data: any) {
            var param = obj => {
                var query = "";
                var subValue, fullSubName, innerObj, i;
                angular.forEach(obj, (value, name: any) => {
                    if (value instanceof Array) {
                        for (i = 0; i < value.length; ++i) {
                            subValue = value[i];
                            fullSubName = name + "[" + i + "]";
                            innerObj = {};
                            innerObj[fullSubName] = subValue;
                            query += param(innerObj) + "&";
                        }
                    } else if (value instanceof Object) {
                        angular.forEach(value, (subValue, subName) => {
                            fullSubName = name + "[" + subName + "]";
                            innerObj = {};
                            innerObj[fullSubName] = subValue;
                            query += param(innerObj) + "&";
                        });
                    } else if (value !== undefined && value !== null) {
                        query += encodeURIComponent(name) + "=" + encodeURIComponent(value) + "&";
                    }
                });

                return query.length ? query.substr(0, query.length - 1) : query;
            };
            return angular.isObject(data) && String(data) !== "[object File]" ? param(data) : data;
        }

        getUserInfo(accessToken: string): angular.IPromise<angular.IHttpPromiseCallbackArg<IUserInfoModel>> {
            return this.$http({ url: this.urls.userInfo, method: "GET", headers: { "Authorization": "Bearer " + accessToken } });
        }

        cfncLogin(accessToken: string, clientId: string, returnUrl: string): angular.IPromise<ng.IHttpPromiseCallbackArg<any>> {
            return this.$http({ url: this.urls.cfncLogin + "?provider=Bearer&client_id=" + clientId + "&redirect_uri=" + returnUrl, method: "GET", headers: { "Authorization": "Bearer " + accessToken } });
        }

        login(data: ILoginModel): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            return this.$http({ method: "POST", url: this.urls.login, data: this.formEncode(data), headers: this.formHeader });
        }

        refreshToken(data: IRefreshTokenModel): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            return this.$http({ method: "POST", url: this.urls.login, data: this.formEncode(data), headers: this.formHeader });
        }

        logout(accessToken: string): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            return this.$http({ method: "POST", url: this.urls.logout, headers: { "Authorization": "Bearer " + accessToken } });
        }

        register(data: RegistrationModel): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            return this.$http({ method: "POST", url: this.urls.register, data: data });
        }

        changePassword(data: any): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            return this.$http({ method: "POST", url: this.urls.changePassword, data: data });
        }

        forcePasswordChange(data: any): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            return this.$http({ method: "POST", url: this.urls.forcePasswordChange, data: data });
        }

        getExternalLogins(clientId: string): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            return this.$http({
                method: "GET",
                url: this.urls.externalLogins + "?returnUrl=" + encodeURIComponent(this.urls.site) + "&clientId=" + clientId + "&generateState=true"
            });
        }

        registerExternal(accessToken: string, data: any): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            return this.$http({ method: "POST", url: this.urls.registerExternal, data: data, headers: { "Authorization": "Bearer " + accessToken } });
        }

        getToken = (clientId: string): angular.IPromise<any> => {
            return this.$http({ url: this.urls.exchangeToken + "?clientId=" + clientId, method: "GET" });
        };
    }

    export class AuthenticationProvider {
        registerThenLogin: boolean = true;
        usePopups: boolean = false;
        useFullRefreshForLoginUrl = true;
        useFullRefreshForRedirect = false;
        providerUrls: IAuthenticationProviderUrls = {
            login: "/login",
            registerExternal: "/registerExternal",
            postLogout: "https://localhost:44373/Identity/Account/Logout",
            home: "/",
            cfncHostLogin: "https://localhost:44373/Identity/Account/Login"
        };
        events: IAuthenticationEvents = {
            login: null,
            logout: null,
            register: null,
            reloadUser: null,
            closeOAuthWindow: null
        };
        clientId: string;


        $get: Array<any>;

        constructor(public apiUrls: IAuthUrls) {
            this.clientId = "CrossRoadsLocal";

            this.$get = ["authentication.api", "$rootScope", "$q", "$http", "$location", "$timeout", "$window", "$cookies", "$uibModal", "config", "Idle", "dialogs",
                (api, $rootScope, $q, $http, $location, $timeout, $window, $cookies, $uibModal, config, Idle, dialogs) => {
                    return new AuthenticationProviderService(api, this, $rootScope, $q, $http, $location, $timeout, $window, $cookies, $uibModal, config, Idle, dialogs);
                }];
        }
    }

    export class AuthenticationProviderService implements IAuthenticationProviderService {
        private externalLoginWindowTimer: any;

        user: IUserInfoModel;
        externalUser: any;
        externalLogins: Array<any>;

        logoutTimer: angular.IPromise<any>;
        warningDialog: angular.ui.bootstrap.IModalServiceInstance;

        constructor(private api: AuthenticationFactory,
            private authProvider: AuthenticationProvider,
            private $rootScope: angular.IRootScopeService,
            private $q: angular.IQService,
            private $http: angular.IHttpService,
            private $location: angular.ILocationService,
            private $timeout: angular.ITimeoutService,
            private $window: angular.IWindowService,
            private $cookies: angular.cookies.ICookiesService,
            private $uibModal: angular.ui.bootstrap.IModalService,
            private config: ICrossroadsConfig,
            private Idle: angular.idle.IIdleService,
            private dialogs: angular.dialogs.IDialogService) {


            $rootScope.$on("IdleStart", () => {
                this.warningDialog = this.dialogs.create("/app/login/logoutDialog.html", "logoutDialog");
            });

            $rootScope.$on("IdleEnd", () => {
                this.warningDialog.close();
            });

            $rootScope.$on("IdleTimeout", () => {
                this.warningDialog.close();
                this.logout();
            });

            this.initialize();
        }

        checkLogin = () => {
            var cookie = this.$cookies.get(this.config.authCookieName);
            if (!cookie) {
                this.user = null;
                this.clearUserInfo();
                this.accessToken("clear");
            } else if (!this.accessToken()) {
                this.exchangeToken();
            }
        };

        accessToken(accessToken?: string, refreshToken?: string, persist?: boolean, expiresOn?: string): string {
            if (accessToken) {
                if (accessToken === "clear") {
                    localStorage.removeItem(CrossroadsStorage.accessToken);
                    sessionStorage.removeItem(CrossroadsStorage.accessToken);
                } else {
                    if (persist) {
                        localStorage[CrossroadsStorage.refreshToken] = refreshToken;
                    } else {
                        sessionStorage[CrossroadsStorage.refreshToken] = refreshToken;
                    }
                    sessionStorage[CrossroadsStorage.expiresOn] = expiresOn ? moment.utc(expiresOn, "ddd, DD MMM YYYY HH:mm:ss") : null;
                    sessionStorage[CrossroadsStorage.accessToken] = accessToken;
                }
            }
            return sessionStorage[CrossroadsStorage.accessToken] || localStorage[CrossroadsStorage.accessToken];
        }

        private handleExternalData(externalData: any, provider: string, rememberMe: boolean): angular.IPromise<IUserInfoModel> {
            var deferred = this.$q.defer<IUserInfoModel>();

            // return if there was an error
            if (externalData.error) {
                deferred.reject({ message: externalData.error });
            } else {
                // get user info and login or show external register screen
                this.api.getUserInfo(externalData.access_token).then(user => {
                    if (user.data.hasRegistered) {
                        this.accessToken(externalData.access_token);
                        this.user = user.data;
                        appInsights.setAuthenticatedUserContext(user.data.userName);
                        this.redirectAuthenticated(this.redirectTarget() || this.authProvider.providerUrls.home);
                        if (this.authProvider.events.login) {
                            this.loginHandler(user.data);
                        }
                        deferred.resolve(this.user);
                    } else {
                        this.externalUser = user.data;
                        this.externalUser.access_token = externalData.access_token;
                        this.externalUser.provider = provider;

                        if (rememberMe != null) {
                            localStorage[CrossroadsStorage.rememberMe] = rememberMe;
                        }

                        this.$location.path(this.authProvider.providerUrls.registerExternal);
                        deferred.reject();
                    }
                });
            }
            return deferred.promise;
        }

        private initialize(): void {
            if (this.$location.search().access_token && !this.accessToken()) {
                this.cfncLogin(this.$location.search().access_token);
            }
            if (this.$location.hash().indexOf("access_token") !== -1) {
                var externalData = this.parseQueryString(this.$location.hash());
                var login = "CFNC";
                var rememberMe = false;
                if (localStorage[CrossroadsStorage.rememberMe]) {
                    rememberMe = JSON.parse(localStorage[CrossroadsStorage.rememberMe]);
                    delete localStorage[CrossroadsStorage.rememberMe];
                }
                delete localStorage[CrossroadsStorage.loginProvider];
                this.handleExternalData(externalData, login, rememberMe);
            }

            // check for access token and get user info
            if (this.accessToken()) {
                this.api.getUserInfo(this.accessToken()).then((user) => {
                    this.user = user.data;
                    this.setUserInfo(user.data);
                    if (this.authProvider.events.reloadUser) {
                        this.reloadUserHandler(user.data);
                    }
                });
            }

            // fetch list of external logins
            this.api.getExternalLogins(this.authProvider.clientId).then((logins) => {
                this.externalLogins = logins.data;
            });
        }

        private parseQueryString(q: string): any {
            var data = {};
            var pair, separatorIndex, escapedKey, escapedValue, key, value;

            if (q === null) {
                return data;
            }

            var pairs = q.split("&");

            for (var i = 0; i < pairs.length; i++) {
                pair = pairs[i];
                separatorIndex = pair.indexOf("=");

                if (separatorIndex === -1) {
                    escapedKey = pair;
                    escapedValue = null;
                } else {
                    escapedKey = pair.substr(0, separatorIndex);
                    escapedValue = pair.substr(separatorIndex + 1);
                }

                key = decodeURIComponent(escapedKey);
                value = decodeURIComponent(escapedValue);

                data[key] = value;
            }

            return data;
        }

        private redirectTarget(newTarget?: string): string {
            if (newTarget === "clear") {
                sessionStorage.removeItem(CrossroadsStorage.redirectTarget);
                return null;
            }
            if (newTarget) {
                console.log("Changing redirectTarget to:" + newTarget);
                sessionStorage[CrossroadsStorage.redirectTarget] = newTarget;
            }
            return sessionStorage[CrossroadsStorage.redirectTarget];
        }

        private setUserInfo(userInfo: IUserInfoModel) {
            sessionStorage[CrossroadsStorage.userName] = userInfo.userName;
            sessionStorage[CrossroadsStorage.firstName] = userInfo.firstName;
            sessionStorage[CrossroadsStorage.lastName] = userInfo.lastName;
            sessionStorage[CrossroadsStorage.fullName] = userInfo.fullName;
            sessionStorage[CrossroadsStorage.tenant] = userInfo.tenant;
            sessionStorage[CrossroadsStorage.hasRegistered] = userInfo.hasRegistered;
            sessionStorage[CrossroadsStorage.loginProvider] = userInfo.loginProvider;
            sessionStorage[CrossroadsStorage.timeZone] = userInfo.timeZone;
            sessionStorage[CrossroadsStorage.ctpEligible] = userInfo.ctpEligible;
            sessionStorage[CrossroadsStorage.cteEligible] = userInfo.cteEligible;
            sessionStorage[CrossroadsStorage.cihsEligible] = userInfo.cihsEligible;
            sessionStorage[CrossroadsStorage.rcn] = userInfo.rcn;
            sessionStorage[CrossroadsStorage.dateOfBirth] = userInfo.dateOfBirth;
        }

        private clearUserInfo() {
            sessionStorage.removeItem(CrossroadsStorage.userName);
            sessionStorage.removeItem(CrossroadsStorage.firstName);
            sessionStorage.removeItem(CrossroadsStorage.lastName);
            sessionStorage.removeItem(CrossroadsStorage.fullName);
            sessionStorage.removeItem(CrossroadsStorage.tenant);
            sessionStorage.removeItem(CrossroadsStorage.hasRegistered);
            sessionStorage.removeItem(CrossroadsStorage.loginProvider);
            sessionStorage.removeItem(CrossroadsStorage.timeZone);
            sessionStorage.removeItem(CrossroadsStorage.ctpEligible);
            sessionStorage.removeItem(CrossroadsStorage.cteEligible);
            sessionStorage.removeItem(CrossroadsStorage.cihsEligible);
            sessionStorage.removeItem(CrossroadsStorage.rcn);
            sessionStorage.removeItem(CrossroadsStorage.dateOfBirth);

        }

        getUserInfo(): angular.IPromise<IUserInfoModel> {
            var deferred = this.$q.defer<IUserInfoModel>();
            var userInfo: IUserInfoModel;
            var auth = this;
            this.api.getUserInfo(this.accessToken()).then(result => {
                userInfo = result.data;
                auth.setUserInfo(userInfo);
                deferred.resolve(userInfo);
            }, reason => {
                deferred.reject(reason);
            });
            return deferred.promise;
        }

        getUser = (): IUserInfoModel => {
            var userInfo: IUserInfoModel;
            if (sessionStorage[CrossroadsStorage.userName]) {
                userInfo = {
                    userName: sessionStorage[CrossroadsStorage.userName],
                    firstName: sessionStorage[CrossroadsStorage.firstName],
                    lastName: sessionStorage[CrossroadsStorage.lastName],
                    fullName: sessionStorage[CrossroadsStorage.fullName],
                    tenant: sessionStorage[CrossroadsStorage.tenant],
                    hasRegistered: sessionStorage[CrossroadsStorage.hasRegistered],
                    loginProvider: sessionStorage[CrossroadsStorage.loginProvider],
                    timeZone: sessionStorage[CrossroadsStorage.timeZone],
                    ctpEligible: sessionStorage[CrossroadsStorage.ctpEligible] === "true",
                    cteEligible: sessionStorage[CrossroadsStorage.cteEligible] === "true",
                    cihsEligible: sessionStorage[CrossroadsStorage.cihsEligible] === "true",
                    rcn: sessionStorage[CrossroadsStorage.rcn],
                    dateOfBirth: sessionStorage[CrossroadsStorage.dateOfBirth],
                };
            } else {
                return this.user;
            }
            return userInfo;
        };

        public exchangeToken = (): angular.IPromise<ng.IHttpPromiseCallbackArg<any>> => {
            var deferred: angular.IDeferred<any> = this.$q.defer();
            var clientId = this.authProvider.clientId;
            var auth = this;

            this.api.getToken(clientId).then((result) => {
                auth.accessToken(result.data.access_token, result.data.refresh_token, false, result.data[".expires"]);
                sessionStorage[CrossroadsStorage.refreshTokenExpiresIn] = result.data["cfi:refreshExpiresIn"];
                auth.getUserInfo()
                    .then((userInfo: any) => {
                        auth.user = userInfo;
                        if (auth.authProvider.events.login) {
                            this.loginHandler(userInfo);
                        }
                        deferred.resolve(result);
                    }, (reason) => {
                        deferred.reject(reason);
                    });


            },
                (reason) => {
                    console.warn(reason);
                    deferred.reject(reason);
                });
            return deferred.promise;
        };


        login(data: ILoginModel): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            var deferred: angular.IDeferred<any> = this.$q.defer();
            var auth = this;

            data.grant_type = "password";
            data.client_id = this.authProvider.clientId;
            if (!data.tenant) {
                data.tenant = "AdvantSystems";
            }

            this.api.login(data)
                .then(result => {
                    auth.accessToken(result.data.access_token, result.data.refresh_token, data.rememberMe, result.data[".expires"]);
                    sessionStorage[CrossroadsStorage.refreshTokenExpiresIn] = result.data["cfi:refreshExpiresIn"];
                    sessionStorage[CrossroadsStorage.rememberMe] = data.rememberMe;
                    auth.getUserInfo()
                        .then((userInfo: any) => {
                            auth.user = userInfo;
                            auth.redirectAuthenticated(this.redirectTarget() || this.authProvider.providerUrls.home);
                            if (auth.authProvider.events.login) {
                                this.loginHandler(userInfo);
                            }
                        });
                    deferred.resolve(result);
                },
                reason => {
                    deferred.reject(reason);
                });

            return deferred.promise;
        }

        cfncLogin(accessToken: string): angular.IPromise<ng.IHttpPromiseCallbackArg<any>> {
            var deferred: angular.IDeferred<any> = this.$q.defer();
            var auth = this;
            var redirect = encodeURIComponent(this.$location.protocol() + "://" + this.$location.host() + this.$location.path());

            window.location.href = this.authProvider.apiUrls.cfncLogin + "?provider=CFNC&response_type=token&client_id=" + this.authProvider.clientId + "&redirect_uri=" + redirect + "&access_token=" + accessToken;

            this.api.cfncLogin(accessToken, this.authProvider.clientId, redirect)
                .then(result => {
                    auth.accessToken(result.data.access_token, result.data.refresh_token);
                    auth.getUserInfo()
                        .then((userInfo: any) => {
                            auth.user = userInfo;
                            appInsights.setAuthenticatedUserContext(userInfo.userName);
                            auth.redirectAuthenticated(this.redirectTarget() || this.authProvider.providerUrls.home);
                            if (auth.authProvider.events.login) {
                                this.loginHandler(userInfo);
                            }
                        });


                    deferred.resolve(result);
                },
                reason => {
                    deferred.reject(reason);
                });

            return deferred.promise;
        }

        loginWithExternal(login: IExternalLoginViewModel, data?: ILoginModel): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            var deferred: angular.IDeferred<any> = this.$q.defer();
            var self = this;
            if (this.authProvider.usePopups) {
                var loginWindow: Window = window.open(this.authProvider.apiUrls.apiHost + login.url, "frame", "resizeable,height=510,width=380");
                var auth = this;
                // Watch for close event
                this.$timeout.cancel(this.externalLoginWindowTimer);
                this.externalLoginWindowTimer = this.$timeout((closeWatcher) => {
                    if (!loginWindow.closed) {
                        auth.externalLoginWindowTimer = auth.$timeout(closeWatcher, 500);
                        return;
                    }
                    // closeOAuthWindow handler - passes external_data if there is any
                    if (auth.authProvider.events.closeOAuthWindow) {
                        this.closeOAuthWindowHandler(window.sessionStorage["external_data"]);
                    }

                    // return if the window was closed and external data wasn't added
                    if (typeof (window.sessionStorage["external_data"]) === "undefined") {
                        deferred.reject();
                        return;
                    }

                    // move external_data from global to local
                    var externalData = window.sessionStorage["external_data"];
                    window.sessionStorage.removeItem("external_data");

                    deferred.resolve(self.handleExternalData(externalData, login.url, data.rememberMe));
                }, 500);
            } else {
                if (data != null && data.rememberMe != null) {
                    localStorage[CrossroadsStorage.rememberMe] = JSON.stringify(data.rememberMe);
                }
                localStorage[CrossroadsStorage.loginProvider] = JSON.stringify(login);
                window.location.href = this.authProvider.apiUrls.apiHost + login.url;
            }

            return deferred.promise;
        }

        refreshToken(): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            var deferred: angular.IDeferred<any> = this.$q.defer();
            var auth = this;
            var data: IRefreshTokenModel = {
                grant_type: "refresh_token",
                client_id: this.authProvider.clientId,
                refresh_token: localStorage[CrossroadsStorage.refreshToken] || sessionStorage[CrossroadsStorage.refreshToken]
            };

            this.api.refreshToken(data)
                .then((result) => {
                    auth.accessToken(result.data.access_token, result.data.refresh_token, sessionStorage[CrossroadsStorage.rememberMe], result.data[".expires"]);

                    deferred.resolve(result);
                }, (reason) => {
                    this.logout();
                    deferred.reject(reason);
                });

            return deferred.promise;
        }

        logout(): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            var deferred: angular.IDeferred<any> = this.$q.defer();

            this.user = null;
            this.clearUserInfo();
            appInsights.clearAuthenticatedUserContext();
            this.$cookies.remove(this.config.authCookieName, { domain: ".cfnc.org" });
            this.accessToken("clear");
            this.redirectTarget("clear");

            if (this.authProvider.events.logout) {
                this.logoutHandler();
            }
            
            window.location.href = this.authProvider.providerUrls.postLogout;

            deferred.resolve();

            return deferred.promise;
        }

        register(data: RegistrationModel): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            var deferred: angular.IDeferred<any> = this.$q.defer();

            this.api.register(data).then(result => {
                if (this.authProvider.events.register) {
                    this.registerHandler();
                }

                if (this.authProvider.registerThenLogin) {
                    var loginInfo: ILoginModel = {
                        grant_type: "password",
                        userName: data.userName,
                        password: data.password,
                        rememberMe: false
                    };
                    this.login(loginInfo).then(user => {
                        deferred.resolve(user);
                    },
                        reason => {
                            deferred.reject(reason);
                        });
                } else {
                    deferred.resolve();
                }
            }, reason => {
                deferred.reject(reason);
            });
            return deferred.promise;
        }

        setLogoutTimer = () => {
            var timer = 28 * 60 * 1000; //28 minutes
            var refreshTokenExpiresOn: any = new Date(sessionStorage[CrossroadsStorage.expiresOn]);
            console.log(refreshTokenExpiresOn);
            this.$timeout.cancel(this.logoutTimer);
            this.logoutTimer = this.$timeout(() => {
                console.log("inside timeout");
                if (sessionStorage[CrossroadsStorage.expiresOn]) {
                    var expiresOn: any = new Date(sessionStorage[CrossroadsStorage.expiresOn]);
                    if (expiresOn - refreshTokenExpiresOn == 0) {
                        var modalInstance = this.$uibModal.open({
                            templateUrl: '/app/login/logoutModal.html',
                            controller: 'logoutModal',
                            backdrop: 'static',
                            resolve: {
                                data: function () { }
                            }
                        });
                    }
                    else {
                        this.setLogoutTimer();
                    }
                }
                else {
                    this.logout();
                }


            }, timer);


        };

        getItemFromStorage = (item) => {
            if (sessionStorage[CrossroadsStorage.rememberMe]) {
                return localStorage.getItem(item);
            } else {
                return sessionStorage.getItem(item);
            }
        };

        registerExternal(): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            var deferred: angular.IDeferred<any> = this.$q.defer();

            if (!this.externalUser) {
                deferred.reject();
            } else {
                this.api.registerExternal(this.externalUser.access_token, this.externalUser).then(result => {
                    deferred.resolve(this.loginWithExternal(this.externalUser.provider));
                }, reason => {
                    deferred.reject(reason);
                });
            }

            return deferred.promise;
        }

        changePassword(data): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            var deferred: angular.IDeferred<any> = this.$q.defer();

            this.api.changePassword(data).then(result => {
                deferred.resolve();
            }, reason => {
                deferred.reject(reason);
            });

            return deferred.promise;
        }

        forcePasswordChange(data): angular.IPromise<angular.IHttpPromiseCallbackArg<any>> {
            var deferred: angular.IDeferred<any> = this.$q.defer();

            this.api.forcePasswordChange(data).then(result => {
                deferred.resolve();
            }, reason => {
                deferred.reject(reason);
            });

            return deferred.promise;
        }

        authenticate(): angular.IPromise<ng.IHttpPromiseCallbackArg<boolean>> {
            var deferred: angular.IDeferred<any> = this.$q.defer();

            var cookie = this.$cookies.get(this.config.authCookieName);

            if (cookie && !this.accessToken()) {
                this.exchangeToken().then(() => {
                    deferred.resolve(true);
                }, () => {
                    deferred.resolve(false);
                });
            } else {
                if (!cookie) {
                    this.user = null;
                    this.clearUserInfo();
                    this.accessToken("clear");
                }

                if (this.accessToken()) {
                    deferred.resolve(true);
                } else {
                    if (!this.redirectTarget()) {
                        this.redirectTarget(this.$location.path());
                    }
                    if (this.$location.search().access_token && !this.accessToken()) {
                        deferred.resolve(false);
                    } else {
                        if (this.$location.path().indexOf(this.authProvider.providerUrls.login) === -1) {
                            if (this.authProvider.useFullRefreshForLoginUrl) {
                                //redirect to cfnc login page
                                this.$window.location.href = this.authProvider.providerUrls.cfncHostLogin + "?returnUrl=" + encodeURIComponent(this.$location.absUrl());
                            } else {
                                this.$location.path(this.authProvider.providerUrls.login);
                            }
                        }
                    }
                }
            }
            return deferred.promise;
        }

        redirectAuthenticated(url: string): void {
            if (!this.accessToken()) {
                return;
            }
            if (this.redirectTarget()) {
                this.redirectTarget("clear");
            }

            if (this.authProvider.useFullRefreshForRedirect) {
                this.$window.location.href = url;
            } else {
                this.$location.hash("");
                this.$location.path(url);
            }
        }

        $broadcast(...args: any[]) {
            return this.$rootScope.$broadcast.apply(this.$rootScope, arguments);
        }

        loginHandler(userInfo: IUserInfoModel) {
            this.$broadcast(this.authProvider.events.login, { authService: this, userInfo: userInfo });
        }

        reloadUserHandler(userInfo: IUserInfoModel) {
            this.$broadcast(this.authProvider.events.reloadUser, { authService: this, userInfo: userInfo });
        }

        logoutHandler() {
            this.$broadcast(this.authProvider.events.logout, { authService: this });
        }

        registerHandler() {
            this.$broadcast(this.authProvider.events.register, { authService: this });
        }

        closeOAuthWindowHandler(externalData: any) {
            this.$broadcast(this.authProvider.events.closeOAuthWindow, { authService: this, externalData: externalData });
        }
    }
}

angular.module("authentication", [])
    .constant("authentication.urls", {
        site: "/",
        register: "/account/register",
        login: "/token",
        logout: "/account/logout",
        userInfo: "/account/userInfo",
        changePassword: "/account/changePassword",
        forcePasswordChange: "/account/forcePasswordChange",
        externalLogins: "/account/externalLogins",
        registerExternal: "/account/registerExternal",
        cfncLogin: "/account/CfncLogin",
        exchangeToken: "/account/gettoken"
    })
    .factory("authentication.api", ["$http", "authentication.urls", ($http, urls) => new Advant.Crossroads.AuthenticationFactory($http, urls)])
    .provider("authentication", ["authentication.urls", urls => new Advant.Crossroads.AuthenticationProvider(urls)]);