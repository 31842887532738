/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    interface IAdvTabHeadingTransclude extends angular.IDirective {
    }

    interface IAdvTabHeadingTranscludeScope extends angular.IScope {
    }

    interface IAdvTabHeadingTranscludeAttributes extends angular.IAttributes {
    }

    function advTabHeadingTransclude(): IAdvTabHeadingTransclude {
        return {
            restrict: "A",
            require: "^advTab",
            link: (scope, elm) => {
                scope.$watch("headingElement", (heading: angular.IAugmentedJQuery) => {
                    if (heading) {
                        elm.html("");
                        elm.append(heading);
                    }
                });
            }
        }
    }

    angular.module("app").directive("advTabHeadingTransclude", advTabHeadingTransclude);
}