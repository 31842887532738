namespace Advant.Crossroads {
    "use strict";

    export interface IAdvSpinner extends angular.IDirective {

    }

    export interface IAdvSpinnerScope extends angular.IScope {
        spinner: any;
    }

    // description:
    //  creates a new Spinner and sets its options
    // usage:
    //  <div data-adv-spinner="vm.spinnerOptions"></div>
    export class AdvSpinner implements IAdvSpinner {
        static directiveId: string = "advSpinner";
        restrict: string = "A";

        link = (scope: IAdvSpinnerScope, element, attrs) => {
            scope.spinner = null;

            scope.$watch(attrs.advSpinner, options => {
                if (scope.spinner) {
                    scope.spinner.stop();
                }
                scope.spinner = new Spinner(options);
                scope.spinner.spin(element[0]);
            }, true);
        };
    }

    angular.module("app").directive(AdvSpinner.directiveId, [() =>
        new AdvSpinner()
    ]);
}
