
namespace Advant.Crossroads {
    "use strict";

    export interface IRouteConfigSettings {
        navOrder?: number;
        content?: string;
    }

    export interface IRouteConfig {
        templateUrl?: string;
        title?: string;
        section?: string;
        settings: IRouteConfigSettings;
        caseInsensitiveMatch?: boolean;
        controller?: string;
        controllerAs?: string;
        reloadOnSearch?: boolean;
    }

    export interface IRoute {
        url?: string;
        config?: IRouteConfig;
        subNavs?: Array<IRoute>;
    }


    export class RouteConfig {
        routes: Array<IRoute>;
        constructor() {
            this.routes = [
                {
                    url: "/login",
                    config: {
                        templateUrl: "/app/login/login.html",
                        title: "Login",
                        caseInsensitiveMatch: true,
                        settings: {}
                    }
                }, {
                    url: "/login/:tenant",
                    config: {
                        title: "Login",
                        templateUrl: "/app/login/loginForm.html",
                        caseInsensitiveMatch: true,
                        settings: {}
                    }
                }, {
                    url: "/application/completed/userApplications/:userApplicationId",
                    config: {
                        templateUrl: "/app/application/confirmation.html",
                        title: "Completed Application",
                        caseInsensitiveMatch: true,
                        settings: {}
                    }
                }, {
                    url: "/application/:organization/:program/:application",
                    config: {
                        templateUrl: "/app/application/application.html",
                        title: "Complete Application",
                        caseInsensitiveMatch: true,
                        settings: {}
                    }
                }, {
                    url: "/application/loggedout",
                    config: {
                        templateUrl: "/app/login/loggedout.html",
                        title: "Logged Out",
                        caseInsensitiveMatch: true,
                        settings: {}
                    }
                }, {
                    url: "/application/logout",
                    config: {
                        templateUrl: "/app/login/logout.html",
                        title: "Log Out",
                        caseInsensitiveMatch: true,
                        settings: {}
                    }
                }, {
                    url: "/",
                    config: {
                        templateUrl: "/app/home/home.html",
                        title: "Home",
                        caseInsensitiveMatch: true,
                        settings: {}
                    }
                }
            ];
        }
    }

    var app = angular.module("app");
    var advantRoutes = new RouteConfig();

    // collect the routes
    angular.module("app").constant("routes", advantRoutes.routes);

    // configure the routes and route resolvers
    function routeConfigurator($routeProvider: any, routes: any) {
        function setRoute(r: IRoute) {

            if (r.url) {
                $routeProvider.when(r.url, r.config);
            }
            if (r.subNavs) {
                r.subNavs.forEach(sn => setRoute(sn));
            }
            return $routeProvider;
        }

        routes.forEach(r=> setRoute(r));

        // $routeProvider.otherwise({ redirectTo: "/" });
    }

    angular.module("app").config(["$routeProvider", "routes", routeConfigurator]);
}