/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    export interface IRdsSaveDialogScope extends mgcrea.ngStrap.modal.IModalScope {
        yes: () => void;
        no: () => void;
    }

    export interface IRdsSaveDialog {

    }

    export class RdsSaveDialog implements IRdsSaveDialog {
        static controllerId: string = "rdsSaveDialog";
        static $inject: any = ["local"];

        constructor(private local) {
        }

        yes() {
            this.local.confirm();
        }

        no() {
            this.local.cancel();
        }
    }

    angular.module("app").controller(RdsSaveDialog.controllerId, RdsSaveDialog);
}
