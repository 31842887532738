namespace Advant.Crossroads {
    "use strict";

    interface IInput extends angular.IDirective {

    }

    interface IInputScope extends angular.IScope {
        maskValue: string;
    }

    interface IInputAttributes extends angular.IAttributes {
        value: string;
        ngModel: string;
        type: string;
    }

    input.$inject = ["$parse", "$filter"];
    function input($parse: angular.IParseService, $filter: angular.IFilterService): IInput {
        var dir: IInput = {};

        dir.restrict = "E";
        dir.require = "?ngModel";

        dir.link = (scope: IInputScope, element: angular.IAugmentedJQuery, attrs: IInputAttributes, ngModel: angular.INgModelController) => {
            if (attrs.ngModel && attrs.value && attrs.type !== "radio") {
                var parsed = $parse(attrs.value);
                var parsedValue = parsed(scope) || attrs.value;
                var modelValue = $parse(attrs.ngModel)(scope);
                if (!modelValue && typeof parsedValue === "string" && parsedValue.indexOf("vm.") !== 0) {
                    if (element[0].attributes["data-date-format"] != null) {
                        parsedValue = $filter("date")(parsedValue, element[0].attributes["data-date-format"].value);
                    }
                    $parse(attrs.ngModel).assign(scope, parsedValue);
                    scope.$applyAsync();
                }
                if (!modelValue && (typeof parsedValue === "number" || typeof parsedValue === "boolean")) {
                    $parse(attrs.ngModel).assign(scope, parsedValue);
                    scope.$applyAsync();
                }
            }

            if (attrs.maskField) {
                element.bind('focus', () => {
                    element.attr("type", attrs.maskFieldType);
                }).bind('blur', () => {
                    element.attr("type", "password");
                });
            }
        };

        return dir;
    }


    angular.module("app").directive("input", input);
}
