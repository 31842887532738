namespace Advant.Crossroads {
    "use strict";

    interface ICheckListDefault extends angular.IDirective {
    }

    interface ICheckListDefaultScope extends angular.IScope {
    }

    checkListDefault.$inject = ["$parse", "helper"];
    function checkListDefault($parse: angular.IParseService): ICheckListDefault {

        var link = (scope: ICheckListDefaultScope, element: angular.IAugmentedJQuery, attrs: angular.IAttributes, ctrl: angular.INgModelController) => {

            scope.$watch(attrs.checkListDefault, (defaultValue: string) => {
                if (defaultValue) {
                    let modelValue = $parse(attrs.ngModel)(scope);


                    if (!modelValue) {
                        let defaultArray = defaultValue.split(",");

                        $parse(attrs.ngModel).assign(scope, defaultArray);
                    }
                }
            });

        }

        return {
            restrict: "A",
            require: "?ngModel",
            priority: -1,
            link: link,
            replace: true
        }
    }

    angular.module("app").directive("checkListDefault", checkListDefault);
}