namespace Advant.Crossroads {
    export class CrossroadsStorage {
        public static userName: string = "userInfo.userName";
        public static firstName = "userInfo.firstName";
        public static lastName: string = "userInfo.lastName";
        public static fullName: string = "userInfo.fullName";
        public static tenant: string = "userInfo.tenant";
        public static loginProvider: string = "userInfo.loginProvider";
        public static hasRegistered: string = "userInfo.hasRegistered";
        public static timeZone: string = "userInfo.timeZone";
        public static accessToken: string = "accessToken";
        public static refreshToken: string = "refreshToken";
        public static redirectTarget: string = "redirectTarget";
        public static rememberMe: string = "rememberMe";
        public static ctpEligible = "ctpEligible";
        public static cteEligible = "cteEligible";
        public static cihsEligible = "cihsEligible";
        public static rcn = "rcn";
        public static dateOfBirth = "dateOfBirth";
        public static refreshTokenExpiresIn = "refreshTokenExpiresIn";
        public static expiresOn = "expiresOn";
    }
}  