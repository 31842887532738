/// <reference path="advtabsetcontroller.ts" />
namespace Advant.Crossroads {
    "use strict";

    interface IAdvTabset extends angular.IDirective {
    }

    interface IAdvTabsetScope extends angular.IScope {
        vertical: boolean;
        justified: boolean;
        active: number;
    }

    interface IAdvTabsetAttributes extends angular.IAttributes {
        vertical: string;
        justified: string;
        active: string;
        templateUrl: string;
    }

    function advTabset(): IAdvTabset {
        return {
            transclude: true,
            replace: true,
            scope: {},
            bindToController: {
                active: "=?",
                type: "@"
            },
            controller: "AdvTabsetController",
            controllerAs: "tabset",
            templateUrl(element, attrs: IAdvTabsetAttributes) {
                return attrs.templateUrl || "adv/template/tabs/tabset.html";
            },

            link: link
        }

        function link(scope: IAdvTabsetScope, element: angular.IAugmentedJQuery, attrs: IAdvTabsetAttributes) {
            scope.vertical = angular.isDefined(attrs.vertical) ?
                scope.$parent.$eval(attrs.vertical) : false;
            scope.justified = angular.isDefined(attrs.justified) ?
                scope.$parent.$eval(attrs.justified) : false;
            if (angular.isUndefined(attrs.active)) {
                scope.active = 0;
            }

        }
    }

    angular.module("app").directive("advTabset", advTabset);
}