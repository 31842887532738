namespace Advant.Crossroads {


    interface IAdvValidateSection extends angular.IDirective {
    }

    interface IAdvValidateSectionScope extends angular.IScope {
        advValidateSection: angular.IFormController;
        section: ISection;
        validateUsing: () => (section: ISection) => angular.IPromise<boolean>;
        hasBeenLoaded: boolean;
    }

    class AdvValidateSection implements IAdvValidateSection {
        static directiveId: string = "advValidateSection";
        restrict: string = "A";
        scope: any = {
            advValidateSection: "=",
            section: "=",
            hasBeenLoaded: "=",
            validateUsing: "&"
        };

        constructor(private $timeout: angular.ITimeoutService) {
        }

        link = (scope: IAdvValidateSectionScope, element: angular.IAugmentedJQuery, attrs, ctrl: angular.INgModelController) => {
            //  This variable is used to detect if a section was loaded while the results of the server validation are still
            //  waiting to come back.
            var useServerValidation = true;

            if (!scope.hasBeenLoaded && !(element.css("display") === "none")) {
                scope.validateUsing()(scope.section).then((result) => {
                    // If the section was loaded while the server validation was pending then useServerValidation will be false
                    // and therefore the server validation will not be used
                    if (!result && useServerValidation) {
                        scope.advValidateSection.$setValidity("serverValidation", false, ctrl);
                    }
                });
            }

            scope.$watch(() => { return scope.hasBeenLoaded; }, (value, oldValue) => {
                if (value !== oldValue) {
                    if (!value && !(element.css("display") === "none")) {
                        scope.validateUsing()(scope.section).then((result) => {
                            if (!result) {
                                scope.advValidateSection.$setValidity("serverValidation", false, ctrl);
                            }
                        });
                    } else {
                        useServerValidation = false;
                        scope.advValidateSection.$setValidity("serverValidation", null, ctrl);
                    }
                }
            });

        }
    }


    angular.module("app").directive(AdvValidateSection.directiveId, ["$timeout", $timeout =>
        new AdvValidateSection($timeout)
    ]);
}