/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    export interface IShellScope extends ng.IScope {
        vm: Shell;
    }

    export interface IShellController {
        busyMessage: string;
        isBusy: boolean;
        showSplash: boolean;
        spinnerOptions: SpinnerOptions;
        toggleSpinner: (on: boolean) => void;
        topBarIsCollapsed: boolean;
        sideBarIsCollapsed: boolean;
        activate: () => void;
        isApplication: boolean;
        copyrightYear: string;
    }

    export class Shell implements IShellController {
        static controllerId: string = "shell";
        static $inject: any = ["$rootScope", "$location", "common", "config", "authentication", "Idle"];
        logSuccess: any;
        events: any;
        public busyMessage: string;
        public isBusy: boolean;
        public showSplash: boolean;
        public spinnerOptions: SpinnerOptions;
        public topBarIsCollapsed: boolean;
        public sideBarIsCollapsed: boolean;
        public isApplication: boolean;
        public copyrightYear: string;

        constructor(private $rootScope: ng.IRootScopeService, private $location: ng.ILocationService, private common: ICommonService,
            private config: ICrossroadsConfig, authentication: IAuthenticationProviderService, private Idle: angular.idle.IIdleService) {

            this.logSuccess = common.logger.getLogFn(Shell.controllerId, "success");
            this.events = config.events;
            this.topBarIsCollapsed = true;
            this.sideBarIsCollapsed = true;
            this.isApplication = this.$location.path().indexOf("/application/") > -1;

            this.busyMessage = "Loading ...";
            this.isBusy = true;
            this.showSplash = true;
            this.spinnerOptions = {
                radius: 30,
                lines: 15,
                length: 20,
                width: 9,
                speed: 1.5,
                corners: 1.0,
                trail: 70,
                color: "#0E81B2"
            };
            this.Idle.watch();
            this.copyrightYear = (new Date).getFullYear().toString();

            var vm = this;
            $rootScope.$on("$routeChangeStart",
                (event, next, current) => {
                    vm.toggleSpinner(true);
                    vm.isApplication = next.originalPath.indexOf("/application/") > -1 || current.originalPath.indexOf("/application/") > -1;
                }
                );

            $rootScope.$on(config.events.controllerActivateSuccess,
                (event) => {
                    vm.toggleSpinner(false);
                }
                );

            $rootScope.$on(config.events.spinnerToggle,
                (event, data) => {
                    var eventData: any = data; // converts the IAngularEvent back to a dynamic type so we can view the "show" property that was set by the ICommonService
                    vm.toggleSpinner(eventData.show);
                });


            this.activate();
        }

        activate = (): void => {
            this.logSuccess("Crossroads loaded!", null, null, false);
            this.common.activateController([], Shell.controllerId)
                .then(() => {
                this.showSplash = false;
            });
        };

        public toggleSpinner(on: boolean) { this.isBusy = on; }

    }


    angular.module("app").controller(Shell.controllerId, Shell);
}