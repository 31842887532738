/// <reference path="advaccordion.ts" />
/// <reference path="../app.ts" />

namespace Advant.Crossroads {
    "use strict";

    interface IAdvAccordionGroup extends angular.IDirective {
    }

    interface IAdvAccordionGroupScope extends angular.IScope {
        openClass: string;
        panelClass: string;
        toggleOpen: ($event: any) => void;
        isDisabled: boolean;
        isOpen: boolean;
        headingId: string;
        panelId: string;
        group: IGroup;
        panel: { activePanel: number };
    }

    interface IAdvAccordionGroupAttributes extends angular.IAttributes {
        openClass: string;
        panelClass: string;
        advResponse: string;
        displayRule: string;
        hasBeenLoaded: string;
        templateUrl: string;

    }

    advAccordionGroup.$inject = ["$parse", "$animate", "$compile", "ruleService"];
    function advAccordionGroup($parse: angular.IParseService, $animate: angular.animate.IAnimateService, $compile: angular.ICompileService, ruleService: Rules.IRuleService): IAdvAccordionGroup {
        return {
            require: "^advAccordion",         // We need this directive to be inside an accordion
            priority: 500,
            transclude: {
                "heading": "?advAccordionHeading"
            },              // It transcludes the contents of the directive into the template
            replace: true,                // The element containing the directive will be replaced with the template
            templateUrl: (element, attrs: IAdvAccordionGroupAttributes) => {
                return attrs.templateUrl || "/app/directives/advAccordionGroup.html";
            },
            scope: {
                heading: "@",               // Interpolate the heading attribute onto this scope
                isOpen: "=?",
                isDisabled: "=?"
            },
            controller: () => {
                return {
                    heading: "",
                    setHeading: function (element) {
                        this.heading = element;
                    }
                }
            },

            link: link
        }

        /**
         * Return the DOM siblings between the first and last node in the given array.
         * @param {Array} array like object
         * @returns {Array} the inputted object or a jqLite collection containing the nodes
         */
        function getBlockNodes(nodes) {
            // TODO(perf): update `nodes` instead of creating a new object?
            var node = nodes[0];
            var endNode = nodes[nodes.length - 1];
            var blockNodes;
            var slice = [].slice;

            for (var i = 1; node !== endNode && (node = node.nextSibling); i++) {
                if (blockNodes || nodes[i] !== node) {
                    if (!blockNodes) {
                        blockNodes = jQuery(slice.call(nodes, 0, i));
                    }
                    blockNodes.push(node);
                }
            }

            return blockNodes || nodes;
        }


        function link(scope: IAdvAccordionGroupScope, element: angular.IAugmentedJQuery, attrs: IAdvAccordionGroupAttributes, accordionCtrl, transclude) {

            var block, childScope, previousElements;
            var headerBlock, headerChildScope, headerPreviousElements;
            scope.panel = { activePanel: 0 };

            scope.openClass = attrs.openClass || "panel-open";
            scope.panelClass = attrs.panelClass || "panel-default";
            if (!scope.isOpen) {
                scope.panel.activePanel = -1;
            }

            scope.$watch("panel.activePanel", (value) => {
                scope.isOpen = value === 0;
                element.toggleClass(scope.openClass, scope.isOpen);
                if (value === 0) {
                    accordionCtrl.closeOthers(scope);
                }
            });

            scope.toggleOpen = ($event) => {
                if (!scope.isDisabled) {
                    if (!$event || $event.which === 32) {
                        scope.isOpen = !scope.isOpen;
                    }
                }
            };

            var id = "accordiongroup-" + scope.$id + "-" + Math.floor(Math.random() * 10000);
            scope.headingId = id + "-tab";
            scope.panelId = id + "-panel";

            var displayRule: IDynamicDisplayRule = $parse(attrs.displayRule)(scope.$parent);
            if (displayRule) {
                var loadedGetter = $parse(attrs.hasBeenLoaded);
                var loadedSetter: any = loadedGetter.assign;
                var alreadyLoaded;

                var ruleProperties = ruleService.getPropertiesToWatch(displayRule.rule);

                angular.forEach(ruleProperties, (ruleProperty) => {
                    scope.$parent.$watch(attrs.advResponse + "." + ruleProperty, (newValue, oldValue, originalScope: IAdvAccordionGroupScope) => {
                        var localResponse = $parse(attrs.advResponse)(originalScope);
                        var result = ruleService.evalRuleSet(displayRule.rule, localResponse);

                        if (result) {
                            if (displayRule.display === "Show") {
                                show();
                            } else {
                                hide();
                            }
                        } else {
                            if (displayRule.display === "Show") {
                                hide();
                            } else {
                                show();
                            }
                        }

                        scope.$evalAsync(() => {
                            // If the section the group is in has not been loaded then 
                            // go ahead and load the whole section so we can reflect validation
                            // changes
                            alreadyLoaded = loadedGetter(scope);
                            if (!alreadyLoaded) {
                                loadedSetter(scope, true);
                            }
                        });
                    }, true);
                });
            } else {
                show();
            }

            function show() {
                accordionCtrl.addGroup(scope);

                if (!headerChildScope) {
                    transclude((clone, newScope) => {
                        headerChildScope = newScope;
                        // Note: We only need the first/last node of the cloned nodes.
                        // However, we need to keep the reference to the jqlite wrapper as it might be changed later
                        // by a directive with templateUrl when its template arrives.
                        headerBlock = {
                            clone: clone
                        };
                        angular.element(element[0].querySelector("[adv-accordion-transclude]")).append(clone);

                    }, element.parent(), "heading");
                }
                if (!childScope) {
                    transclude((clone, newScope) => {
                        childScope = newScope;
                        // Note: We only need the first/last node of the cloned nodes.
                        // However, we need to keep the reference to the jqlite wrapper as it might be changed later
                        // by a directive with templateUrl when its template arrives.
                        block = {
                            clone: clone
                        };
                        angular.element(element[0].querySelector("[ng-body-transclude]")).append(clone);
                    }, element.parent());
                }
                element.show();

            }

            function hide() {
                accordionCtrl.removeGroup(scope);
                if (previousElements) {
                    previousElements.remove();
                    previousElements = null;
                }
                if (childScope) {
                    childScope.$destroy();
                    childScope = null;
                }
                if (block) {
                    previousElements = getBlockNodes(block.clone);
                    previousElements.remove();
                    previousElements = null;
                    block = null;
                }
                if (headerPreviousElements) {
                    headerPreviousElements.remove();
                    headerPreviousElements = null;
                }
                if (headerChildScope) {
                    headerChildScope.$destroy();
                    headerChildScope = null;
                }
                if (headerBlock) {
                    headerPreviousElements = getBlockNodes(headerBlock.clone);
                    headerPreviousElements.remove();
                    headerPreviousElements = null;
                    headerBlock = null;
                }

                element.hide();
            }

        }
    }

    angular.module("app").directive("advAccordionGroup", advAccordionGroup);
}