/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict";


    interface ILogOutScope extends ng.IScope {
        vm: LogOut;
    }

    interface ILogOutRootScope extends ng.IScope {
        stylesheets: Array<any>;
    }

    interface ILogOutRouteParams extends ng.route.IRouteParamsService {
        referrer: string;
    }

    interface ILogOut {
        
    }

    class LogOut implements ILogOut {
        static controllerId: string = "logOut";
        static $inject: any = ["$rootScope", "common", "$routeParams", "authentication", "$window", "$timeout"];
        timer: any;
        


        constructor(private $rootScope: ILogOutRootScope,
            private common: ICommonService,
            private $routeParams: ILogOutRouteParams,
            private authentication: IAuthenticationProviderService,
            private $window: ng.IWindowService,
            private $timeout: ng.ITimeoutService) {
            
            this.activate();
            this.$rootScope.stylesheets = [];
            var baseStyleSheet = {
                link: "/content/application.css"
            };

            this.$rootScope.stylesheets.push(baseStyleSheet);

            if ($routeParams.referrer) {
                this.authentication.logout();
                if (this.$window.parent) {
                    this.$window.parent.postMessage("Crossroads", "*");
                }
            }
            else {
                this.timer = this.$timeout(() => {
                    this.authentication.logout();
                }, 5000);
                if (this.$window.parent) {
                    this.$window.parent.postMessage("Crossroads", "*");
                }
            }
        }

        private activate() {
            var self = this;
            this.common.activateController([], LogOut.controllerId);
        }
    }

    angular.module("app").controller(LogOut.controllerId, LogOut);
}