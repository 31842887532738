namespace Advant.Crossroads {
    "use strict";

    export interface ILoginControllerScope extends ng.IScope {
        vm: LoginController;
        loginForm: ng.IFormController;
    }

    export interface ILoginRouteParams extends ng.route.IRouteParamsService {
        tenant: string;
    }


    export interface ILogin {
        errors: Array<any>;
        login: () => void;
        busy: boolean;
        externalLogins: IExternalLoginViewModel[];
    }

    export class LoginController implements ILogin {
        static controllerId: string = "loginController";
        static $inject: any = ["$scope", "$routeParams", "authentication", "common"];
        errors: Array<any> = [];
        busy: boolean;
        loginInfo: ILoginModel;
        log: (msg, data?, showToast?) => void;
        externalLogins: IExternalLoginViewModel[];

        constructor(private $scope: ILoginControllerScope,
            private $routeParams: ILoginRouteParams,
            private authentication: IAuthenticationProviderService,
            private common: ICommonService) {

            // authentication.redirectAuthenticated("/");
            this.log = common.logger.getLogFn(LoginController.controllerId);
            this.externalLogins = authentication.externalLogins;
            this.busy = false;

            this.loginInfo = {
                grant_type: "password",
                userName: "",
                password: "",
                rememberMe: false
            };

            this.activate();

        }

        private activate() {
            var self = this;
            this.common.activateController([], LoginController.controllerId)
                .then(result=> {
                self.log("Activated Login Screen");
            });
        }

        login() {
            var self = this;
            self.loginInfo.tenant = self.$routeParams.tenant;
            self.errors = [];
            if (self.$scope.loginForm.$valid) {
                self.busy = true;
                self.authentication.login(self.loginInfo).then(result=> {
                    self.busy = false;
                }, reason=> {
                        self.errors.push(reason.data.error_description);
                        self.busy = false;
                    });
            }
        }

        loginExternal(externalLoginInfo: IExternalLoginViewModel) {
            this.authentication.loginWithExternal(externalLoginInfo, this.loginInfo);
        }

    }


    angular.module("app").controller(LoginController.controllerId, LoginController);
}