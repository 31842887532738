namespace Advant.Crossroads {
    "use strict";

    interface IDynamicName extends ng.IDirective {

    }

    interface IDynamicNameScope extends ng.IScope {
    }

    class DynamicName implements IDynamicName {
        static directiveId: string = "dynamicName";
        restrict: string = "A";
        priority: number = 10000;

        constructor(private $compile: ng.ICompileService, private $parse: ng.IParseService) {
        }

        controller = ["$scope", "$element", "$attrs", ($scope: IDynamicNameScope, $element, $attrs) => {
            var name = this.$parse($attrs.dynamicName)($scope);
            delete ($attrs.dynamicName);
            $element.removeAttr("data-dynamic-name");
            $element.removeAttr("dynamic-name");
            $attrs.$set("name", name);
        }];
    }

    angular.module("app").directive(DynamicName.directiveId, ["$compile", "$parse", ($compile, $parse) =>
        new DynamicName($compile, $parse)
    ]);
}