namespace Advant.Crossroads {

    interface IRLCPSponsoringSchoolSearch {
        schoolDistrictName: string;
        schoolDistrictNumber: string;
        schoolName: string;
        schoolCeebCode: string;
        schoolNumber: string;
        districts: ISchoolDistrict[];
        schools: IRLCPSponsoringSchool[];
        selectedDistrict: any;
        selectedSchool: any;
        orientation: string;

        districtSearch?: (searchFilter: string, replace?: boolean) => void;
        schoolSearch?: (district: ISchoolDistrict, searchFilter: string, replace?: boolean) => void;
        selectSchool?: (school: IRLCPSponsoringSchool) => void;
        selectDistrict?: (district: ISchoolDistrict) => void;
        loadMoreSchools?: ($select, $event) => void;
        loadMoreDistricts?: ($select, $event) => void;

    }

    interface IAdvRLCPSponsoringHighSchoolAttributes extends angular.IAttributes {
        orientation: string;
    }

    interface IAdvRLCPSonsoringHighSchool extends angular.IDirective {
    }

    interface IAdvRLCPSonsoringHighSchoolScope extends angular.IScope {
        rlcpSponsoringSchoolSearch: IRLCPSponsoringSchoolSearch;

        schoolDistrictNameField: string;
        schoolDistrictNumberField: string;
        schoolNameField: string;
        schoolCeebCodeField: string;
        schoolNumberField: string;
        errorModel: any;
        ngModel: any;

        loading: boolean;

        hsPage: number;
        hsTotalPages: number;
        dPage: number;
        dTotalPages: number;

        clearDistrict?: () => void;
        clearSchool?: () => void;
    }

    class AdvRLCPSonsoringHighSchool implements IAdvRLCPSonsoringHighSchool {
        static directiveId: string = "advRlcpSponsoringHighSchool";
        restrict: string = "EA";
        priority = 1;
        templateUrl: string = "/app/directives/advRLCPSonsoringHighSchool.html";
        require = "?ngModel";
        scope: any = {
            ngModel: "=",
            schoolDistrictNameField: "=?",
            schoolDistrictNumberField: "=?",
            schoolNameField: "=?",
            schoolCeebCodeField: "=?",
            schoolNumberField: "=?",
            placeholder: "@",
            errorModel: "="
        }

        constructor(private $document: angular.IDocumentService,
            private $uibPosition: angular.ui.bootstrap.IPositionService,
            private $parse: angular.IParseService,
            private $timeout: angular.ITimeoutService,
            private rlcpSchoolLookup: IRLCPSponsoringSchoolLookup,
            private schoolDistrictLookup: ISchoolDistrictLookup) {
        }

        link = (scope: IAdvRLCPSonsoringHighSchoolScope, element: JQuery, attrs: IAdvRLCPSponsoringHighSchoolAttributes, controller) => {
            var ctrl = controller;
            
            scope.rlcpSponsoringSchoolSearch = {
                schoolDistrictName: scope.schoolDistrictNameField,
                schoolDistrictNumber: scope.schoolDistrictNumberField,
                schoolName: scope.schoolNameField,
                schoolCeebCode: scope.schoolCeebCodeField,
                schoolNumber: scope.schoolNumberField,
                districts: [],
                schools: [],
                selectedDistrict: { leaName: scope.schoolDistrictNameField, leaNumber: scope.schoolDistrictNumberField },
                selectedSchool: { schoolName: scope.schoolNameField, ceebCode: scope.schoolCeebCodeField, schoolNumber: scope.schoolNumberField },
                orientation: attrs.orientation

            };

            scope.hsPage = 1;
            scope.dPage = 1;

            scope.rlcpSponsoringSchoolSearch.districtSearch = (searchFilter, replace = true) => {
                if (searchFilter && replace) {
                    scope.dPage = 1;
                    scope.dTotalPages = 1;
                }

                this.schoolDistrictLookup.searchFilter = searchFilter || null;
                this.schoolDistrictLookup.page = scope.dPage;
                this.schoolDistrictLookup.sortBy = "LEANameSorting";
                this.schoolDistrictLookup.getSchoolDistricts().then(result => {
                    if (replace === false) {
                        this.schoolDistrictLookup.sortByRelevance = false;
                        scope.rlcpSponsoringSchoolSearch.districts = scope.rlcpSponsoringSchoolSearch.districts.concat(result);

                    } else {
                        this.schoolDistrictLookup.sortByRelevance = true;
                        scope.rlcpSponsoringSchoolSearch.districts = result;
                    }
                    scope.dTotalPages = this.schoolDistrictLookup.totalPages;
                    scope.loading = false;
                });

            }

            scope.rlcpSponsoringSchoolSearch.schoolSearch = (district, searchFilter, replace = true) => {
                if (district) {
                    if (searchFilter && replace) {
                        scope.hsPage = 1;
                        scope.hsTotalPages = 1;
                    }
                    this.rlcpSchoolLookup.page = scope.hsPage;
                    this.rlcpSchoolLookup.district = district.leaNumber;
                    this.rlcpSchoolLookup.searchFilter = searchFilter;
                    this.rlcpSchoolLookup.sortBy = "SchoolName";
                    this.rlcpSchoolLookup.getRLCPSponsoringSchools().then(result => {
                        if (replace === false) {
                            this.rlcpSchoolLookup.sortByRelevance = false;
                            scope.rlcpSponsoringSchoolSearch.schools = scope.rlcpSponsoringSchoolSearch.schools.concat(result);
                        }
                        else {
                            this.rlcpSchoolLookup.sortByRelevance = true;
                            scope.rlcpSponsoringSchoolSearch.schools = result;
                        }
                        scope.hsTotalPages = this.rlcpSchoolLookup.totalPages;
                        scope.loading = false;
                    });
                } else {
                    scope.rlcpSponsoringSchoolSearch.schoolName = null;
                }
            }

            scope.rlcpSponsoringSchoolSearch.selectDistrict = (district) => {
                if (district) {
                    scope.clearSchool();
                    scope.rlcpSponsoringSchoolSearch.schoolDistrictName = district.leaName;
                    scope.rlcpSponsoringSchoolSearch.schoolDistrictNumber = district.leaNumber;
                    scope.schoolDistrictNameField = scope.rlcpSponsoringSchoolSearch.schoolDistrictName;
                    scope.schoolDistrictNumberField = scope.rlcpSponsoringSchoolSearch.schoolDistrictNumber;

                    scope.rlcpSponsoringSchoolSearch.schoolSearch(district, "");
                }
                else {
                    scope.clearDistrict();
                    scope.clearSchool();
                    scope.rlcpSponsoringSchoolSearch.districtSearch("", true)
                }
            }

            scope.rlcpSponsoringSchoolSearch.selectSchool = (school) => {
                if (school) {
                    scope.schoolDistrictNameField = scope.rlcpSponsoringSchoolSearch.schoolDistrictName;
                    scope.schoolDistrictNumberField = scope.rlcpSponsoringSchoolSearch.schoolDistrictNumber;
                    scope.schoolNameField = school.schoolName;
                    scope.schoolCeebCodeField = school.ceebCode;
                    scope.schoolNumberField = school.schoolNumber;

                    scope.ngModel = scope.schoolDistrictNameField + "|" + scope.schoolNameField;
                    ctrl.$modelValue = scope.schoolDistrictNameField + "|" + scope.schoolNameField; //scope.ngModel;
                }
                else {
                    scope.clearSchool();
                    scope.rlcpSponsoringSchoolSearch.schoolSearch(scope.rlcpSponsoringSchoolSearch.selectedDistrict, "", true);
                }
            }

            scope.rlcpSponsoringSchoolSearch.loadMoreDistricts = ($select, $event) => {
                if (!$event) {
                    scope.dPage = 1;
                    scope.rlcpSponsoringSchoolSearch.schools = [];
                    scope.rlcpSponsoringSchoolSearch.districts = [];
                }
                else {
                    $event.stopPropagation();
                    $event.preventDefault();
                    scope.dPage++;
                }

                scope.loading = true;

                scope.rlcpSponsoringSchoolSearch.districtSearch($select.search, false);
            }

            scope.rlcpSponsoringSchoolSearch.loadMoreSchools = ($select, $event) => {
                if (!$event) {
                    scope.hsPage = 1;
                    scope.rlcpSponsoringSchoolSearch.schools = [];
                }
                else {
                    $event.stopPropagation();
                    $event.preventDefault();
                    scope.hsPage++;
                }

                scope.loading = true;

                scope.rlcpSponsoringSchoolSearch.schoolSearch(scope.rlcpSponsoringSchoolSearch.selectedDistrict, $select.search, false);
            }

            scope.clearDistrict = () => {
                scope.rlcpSponsoringSchoolSearch.schoolDistrictName = null;
                scope.rlcpSponsoringSchoolSearch.schoolDistrictNumber = null;
                scope.rlcpSponsoringSchoolSearch.selectedDistrict = null;
                scope.schoolDistrictNameField = null;
                scope.schoolDistrictNumberField = null;
                scope.ngModel = null;
            }

            scope.clearSchool = () => {
                scope.rlcpSponsoringSchoolSearch.schoolCeebCode = null;
                scope.rlcpSponsoringSchoolSearch.schoolName = null;
                scope.rlcpSponsoringSchoolSearch.selectedSchool = null;
                scope.rlcpSponsoringSchoolSearch.schoolNumber = null;
                scope.schoolCeebCodeField = null;
                scope.schoolNameField = null;
                scope.schoolNumberField = null;
                scope.ngModel = null;
            }

            scope.$watch(() => { return scope.errorModel; }, (errorModel) => {
                for (var key in errorModel) {
                    controller.$error[key] = errorModel[key];
                }
            });

            scope.$watch("ngModel", (newvalue, oldvalue) => {
                if (newvalue && scope.rlcpSponsoringSchoolSearch.selectedDistrict && scope.rlcpSponsoringSchoolSearch.selectedSchool) {
                    ctrl.$setValidity("required", true);
                }
                else {
                    ctrl.$setValidity("required", false);
                }
            });

            ctrl.$validators.required = (value) => {
                if (!value) {
                    return false;
                }
                return true;
            }

            if (scope.rlcpSponsoringSchoolSearch.schoolDistrictNumber) {
                scope.rlcpSponsoringSchoolSearch.schoolSearch(scope.rlcpSponsoringSchoolSearch.selectedDistrict, "");
            }

            scope.rlcpSponsoringSchoolSearch.districtSearch("", false);

        }


    }

    angular.module("app").directive(AdvRLCPSonsoringHighSchool.directiveId, ["$document", "$uibPosition", "$parse", "$timeout", "rlcpSponsoringSchoolLookup", "schoolDistrictLookup",
        ($document, $uibPosition, $parse, $timeout, rlcpSponsoringSchoolLookup, schoolDistrictLookup) =>
            new AdvRLCPSonsoringHighSchool($document, $uibPosition, $parse, $timeout, rlcpSponsoringSchoolLookup, schoolDistrictLookup)
    ]);
}