/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    interface ICfncRcn extends angular.IDirective {
    }

    interface ICfncRcnScope extends angular.IScope {
    }

    interface ICfncRcnAttributes extends angular.IAttributes {
        firstNameField: string;
        lastNameField: string;
        dobField: string;
        user: string;
        advResponse: any;
        title: string;
        cssClass: string;
        ngModel: string;
    }

    cfncRcn.$inject = ["$parse", "helper"];
    function cfncRcn($parse: angular.IParseService, helper: IHelper): ICfncRcn {
        var validValues = (value: any) => {
            return value >= 1100100102 && value <= (1100418668 + ((new Date().getFullYear() - 2018) + 1) * 500000);
        };

        var link = (scope: ICfncRcnScope, element: angular.IAugmentedJQuery, attrs: ICfncRcnAttributes, ctrl: angular.INgModelController) => {
            if (!ctrl) { return; }

            var firstName: string = $parse(attrs.firstNameField)(scope);
            var lastName: string = $parse(attrs.lastNameField)(scope);
            var dob: string = $parse(attrs.dobField)(scope);
            var user: IUser = $parse(attrs.user)(scope);

            (ctrl.$validators as any).rcnRange = (modelValue, viewValue) => {
                return ctrl.$isEmpty(viewValue) || validValues(viewValue);
            };

            if (user.rcn) {
                if (checkRcnFieldsMatch(firstName, lastName, dob, user)) {
                    ctrl.$setViewValue(user.rcn);
                    ctrl.$render();
                    $parse(attrs.ngModel).assign(scope, user.rcn);
                    element.attr("disabled", "disabled");
                } else {
                    element.removeAttr("disabled");
                }

                scope.$watch(attrs.firstNameField, (newValue: any, oldValue: any, oScope: ICfncRcnScope) => {
                    if (newValue === oldValue) {
                        return;
                    }

                    var firstName: string = newValue;
                    var lastName: string = $parse(attrs.lastNameField)(scope);
                    var dob: string = $parse(attrs.dobField)(scope);

                    if (checkRcnFieldsMatch(firstName, lastName, dob, user)) {
                        ctrl.$setViewValue(user.rcn);
                        ctrl.$render();
                        element.attr("disabled", "disabled");
                    } else {
                        element.removeAttr("disabled");
                    }
                }, true);
                scope.$watch(attrs.lastNameField, (newValue: any, oldValue: any, oScope: ICfncRcnScope) => {
                    if (newValue === oldValue) {
                        return;
                    }

                    var firstName: string = $parse(attrs.firstNameField)(scope);
                    var lastName: string = newValue;
                    var dob: string = $parse(attrs.dobField)(scope);

                    if (checkRcnFieldsMatch(firstName, lastName, dob, user)) {
                        ctrl.$setViewValue(user.rcn);
                        ctrl.$render();
                        element.attr("disabled", "disabled");
                    } else {
                        element.removeAttr("disabled");
                    }
                }, true);
                scope.$watch(attrs.dobField, (newValue: any, oldValue: any, oScope: ICfncRcnScope) => {
                    if (newValue === oldValue) {
                        return;
                    }

                    var firstName: string = $parse(attrs.firstNameField)(scope);
                    var lastName: string = $parse(attrs.lastNameField)(scope);
                    var dob: string = newValue;

                    if (checkRcnFieldsMatch(firstName, lastName, dob, user)) {
                        ctrl.$setViewValue(user.rcn);
                        ctrl.$render();
                        element.attr("disabled", "disabled");
                    } else {
                        element.removeAttr("disabled");
                    }
                }, true);
            }
        };

        var checkRcnFieldsMatch = (firstName: string, lastName: string, dob: string, user: IUser) => {
            return firstName === user.name.first && lastName === user.name.last && moment.utc(dob).isSame(user.dateOfBirth, "day");
        }


        return {
            restrict: "A",
            require: "?ngModel",
            priority: -1,
            link: link,
            replace: true
        }

    }

    angular.module("app").directive("cfncRcn", cfncRcn);
}