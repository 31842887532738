namespace Advant.Crossroads {
    "use strict";

    interface IAdvFieldDisplay extends angular.IDirective {

    }

    interface IAdvFieldDisplayScope extends angular.IScope {

    }

    class AdvFieldDisplay implements IAdvFieldDisplay {
        static directiveId: string = "advFieldDisplay";
        restrict: string = "A";
        replace: boolean = true;
        template: string;

        constructor(private $compile: angular.ICompileService, private $parse: angular.IParseService, private $timeout: angular.ITimeoutService) {
        }

        link = (scope: IAdvFieldDisplayScope, element, attrs) => {
            var delay: any = 0;
            if (attrs.delay) {
                delay = this.$parse(attrs.delay)(scope);
            }
            var parsed = this.$parse(attrs.advFieldDisplay);
            element.html(this.getStringValue(parsed, scope));
            var compiled = false;

            if (attrs.loadIf) {
                scope.$watch(attrs.loadIf, (newValue) => {
                    if (newValue && !compiled) {
                        scope.$evalAsync(() => {
                            this.$compile(element.contents())(scope);
                            compiled = true;
                            if (attrs.isCompiled) {
                                var isCompiled = this.$parse(attrs.isCompiled);
                                isCompiled.assign(scope, compiled);
                            }
                        });
                    }
                });
            } else {
                scope.$evalAsync(() => {
                    this.$compile(element.contents())(scope);
                    compiled = true;
                    if (attrs.isCompiled) {
                        var isCompiled = this.$parse(attrs.isCompiled);
                        isCompiled.assign(scope, compiled);
                    }
                });
            }
        };

        private getStringValue(parsed: angular.ICompiledExpression, scope: IAdvFieldDisplayScope) {
            return (parsed(scope) || "").toString();
        }
    }

    angular.module("app").directive(AdvFieldDisplay.directiveId, ["$compile", "$parse", "$timeout", ($compile, $parse, $timeout: angular.ITimeoutService, $q: angular.IQService) =>
        new AdvFieldDisplay($compile, $parse, $timeout)
    ]);
}