namespace Advant.Crossroads {
    "use strict";

    interface IAdvAffix extends angular.IDirective {
    }

    interface IAdvAffixScope extends angular.IScope {
        advAffix: boolean;
    }

    interface IAdvAffixAttributes extends angular.IAttributes {
        AdvAffix: string;
        DataOffsetTop: string;
        offsetTop: number;
        offsetBottom: number;
        offsetParent: number;
        offsetUnpin: number;
    }

    AdvAffix.$inject = ["$window", "$parse", "$affix"];
    function AdvAffix($window: angular.IWindowService, $parse: angular.IParseService, $affix: mgcrea.ngStrap.affix.IAffixService): IAdvAffix {
        return {
            restrict: "A",
            link: link,
            scope: {
                advAffix: "="
            }

        }

        function link(scope: IAdvAffixScope, element: angular.IAugmentedJQuery, attrs: IAdvAffixAttributes) {

            var offsetTop = attrs.offsetTop != null ? attrs.offsetTop : 0;
            var offsetBottom = attrs.offsetBottom != null ? attrs.offsetBottom : 0;
            var offsetParent = attrs.offsetParent != null ? attrs.offsetParent : 0;
            var offsetUnpin = attrs.offsetUnpin != null ? attrs.offsetUnpin : 0;
            var affix: mgcrea.ngStrap.affix.IAffix;


            scope.$watch("advAffix", (newValue: any, oldValue:any) => {
                if (newValue) {

                    // Using a generic object because $affix type definition is incorrect. The 4 offset properties are expected to be numbers but are used like strings.
                    // target is missing from the type definition and is required but is not defaulted to anything. using angular.element will return the 
                    // jquery object that affix needs to function properly.
                    var options: any = {
                        offsetTop: offsetTop,
                        offsetBottom: offsetBottom,
                        offsetParent: offsetParent,
                        offsetUnpin: offsetUnpin,
                        target: angular.element($window)
                    };

                    affix = $affix(element, options);
                }

                if (!newValue && affix != null){
                    affix.destroy();
                }
            });

        }
    }

    angular.module("app").directive("advAffix", AdvAffix);
}