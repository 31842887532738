
namespace Advant.Crossroads {
    "use strict";

    interface IAdvDateInput extends angular.IDirective {

    }

    interface IAdvDateInputScope extends angular.IScope {
    }

    interface IAdvDateInputAttributes extends angular.IAttributes {
        minDate: string;
        maxDate: string;
        advDateInput: string;
    }

    function advDateInput($parse: angular.IParseService): IAdvDateInput {
        var validDate = (dateValue: string, inputFormat: string): boolean => {
            var dateMomentValue: moment.Moment;

            if (angular.isDate(dateValue)) {
                dateMomentValue = moment.utc(dateValue);
            } else {
                dateMomentValue = moment.utc(dateValue, inputFormat, true);
            }           

            return dateMomentValue.isValid();
        };

        var validBaseMinDate = (dateValue: string, inputFormat: string): boolean => {
            var dateMomentValue: moment.Moment;

            if (angular.isDate(dateValue)) {
                dateMomentValue = moment.utc(dateValue);
            } else {
                dateMomentValue = moment.utc(dateValue, inputFormat, true);
            }

            if (moment(dateMomentValue).isBefore("1899-12-31")) {
                return false;
            }

            return dateMomentValue.isValid();
        };

        var validMinDate = (dateValue, inputFormat, minDate) => {
            var dateMomentValue: moment.Moment;

            if (angular.isDate(dateValue)) {
                dateMomentValue = moment.utc(dateValue);
            } else {
                dateMomentValue = moment.utc(dateValue, inputFormat, true);
            }

            var minValue = moment(minDate);
            if (dateMomentValue.diff(minValue) > -1) {
                return true;

            } else {
                return false;
            }
        };

        var validMaxDate = (dateValue, inputFormat, maxDate) => {
            var dateMomentValue: moment.Moment;

            if (angular.isDate(dateValue)) {
                dateMomentValue = moment.utc(dateValue);
            } else {
                dateMomentValue = moment.utc(dateValue, inputFormat, true);
            }

            var maxValue = moment(maxDate);
            if (dateMomentValue.diff(maxValue) < 1) {
                return true;

            } else {
                return false;
            }
        };

        return {
            restrict: "A",
            require: "ngModel",
            link: (scope: IAdvDateInputScope, element, attrs: IAdvDateInputAttributes, ngModelCtrl: angular.INgModelController) => {
                ngModelCtrl.$formatters.unshift((modelValue) => {
                    if (modelValue) {
                        var modelMoment = moment(modelValue, moment.ISO_8601, true);
                        if (modelMoment.isValid()) {
                            return modelMoment.utc().format(attrs.advDateInput);
                        }
                        return modelValue;
                    }
                    return modelValue;
                });
                var minDate = $parse(attrs.minDate)(scope) || undefined;
                var maxDate = $parse(attrs.maxDate)(scope) || undefined;

                (ngModelCtrl.$validators as any).date = (modelValue, viewValue) => {
                    return ngModelCtrl.$isEmpty(viewValue) || validDate(viewValue, attrs.advDateInput);
                };                

                (ngModelCtrl.$validators as any).minDate = (modelValue, viewValue) => {
                    return ngModelCtrl.$isEmpty(viewValue) || angular.isUndefined(minDate) || !validDate(viewValue, attrs.advDateInput) || validMinDate(viewValue, attrs.advDateInput, minDate);
                };

                (ngModelCtrl.$validators as any).maxDate = (modelValue, viewValue) => {
                    return ngModelCtrl.$isEmpty(viewValue) || angular.isUndefined(maxDate) || !validDate(viewValue, attrs.advDateInput) || validMaxDate(viewValue, attrs.advDateInput, maxDate);
                };

                (ngModelCtrl.$validators as any).baseMin = (modelValue, viewValue) => {
                    return ngModelCtrl.$isEmpty(viewValue) || validBaseMinDate(viewValue, attrs.advDateInput);
                };

                ngModelCtrl.$parsers.unshift((viewValue) => {
                    if (viewValue) {
                        var dateMomentValue: moment.Moment;

                        if (angular.isDate(viewValue)) {
                            dateMomentValue = moment.utc(viewValue);
                        } else {
                            dateMomentValue = moment.utc(viewValue, attrs.advDateInput, true);
                        }

                        if (dateMomentValue.isValid()) {
                            return dateMomentValue.utc().toISOString();
                        } else {
                            return viewValue;
                        }
                    }
                    return viewValue;
                });
            }
        };
    }

    advDateInput.$inject = ["$parse"];
    angular.module("app").directive("advDateInput", advDateInput);

}