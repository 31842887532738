/// <reference path="../app.ts" />

namespace Advant.Crossroads {
    "use strict";

    interface ITranscriptSchoolCeeb extends ng.IDirective {
    }

    interface ITranscriptSchoolCeebScope extends ng.IScope {
        transcriptSchoolCeeb: any;
    }

    interface ITranscriptSchoolCeebAttributes extends ng.IAttributes {
        ngModel: any;
        transcriptSchoolCeeb: any;
    }
    TranscriptSchoolCeeb.$inject = ["$parse", "$compile"];
    function TranscriptSchoolCeeb($parse: ng.IParseService, $compile: ng.ICompileService): ITranscriptSchoolCeeb {

        var self = this;

        var link = (scope: ITranscriptSchoolCeebScope, element: ng.IAugmentedJQuery, attrs: ITranscriptSchoolCeebAttributes, ctrl: ng.INgModelController) => {
            var getter = $parse(attrs.transcriptSchoolCeeb);
            var setter = getter.assign;

            scope.$watch(attrs.ngModel, (newValue) => {
                var currentValue = getter(scope);
                setter(scope, newValue);
            });
        };

        return {
            restrict: "A",
            require: "?ngModel",
            link: link
        }
    }

    angular.module("app").directive("transcriptSchoolCeeb", TranscriptSchoolCeeb);
}