/// <reference path="../rules/ruleservice.ts" />
/// <reference path="../rules/rules.d.ts" />
/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    interface IAdvSelect extends angular.IDirective {
    }

    interface IAdvSelectAttributes extends angular.IAttributes {
        choices: string;
        field: string;
        allowMultiple: string;
        advModel: string;
        advRequired: string;
        name: string;
        multiple: string;
        default: string;
        advRule: string;
        advResponse: string;
        convertedValues: Array<any>;
    }

    interface IAdvSelectScope extends angular.IScope {
        choices: Array<IChoice>;
        field: ISelectField;
        allowMultiple: boolean;
        parentModel: any;
        modelValue: any;
        //convertedValues: Array<any>;
    }

    advSelect.$inject = ["$parse"];
    function advSelect($parse: angular.IParseService): IAdvSelect {

        var dir: IAdvSelect = {};
        dir.restrict = "E";
        dir.require = "?advModel";
        dir.replace = false;
        dir.template = (tElement, tAttrs: IAdvSelectAttributes) => {
            /* tslint:disable: max-line-length */
            if (angular.isDefined(tAttrs.multiple)) {
                return "<ui-select ng-model=\"" + tAttrs.advModel + "\" theme=\"bootstrap\" name=\"" + tAttrs.name + "_select" + "\" ng-required=\"" + tAttrs.advRequired + "\"" + (tAttrs.advRule ? " adv-rule=\"" + tAttrs.advRule + "\"" : "") + (tAttrs.advResponse ? " adv-response=\"" + tAttrs.advResponse + "\"" : "") + " reset-search-input=\"false\" multiple> <ui-select-match placeholder=\"{{::" + tAttrs.field + ".placeholder}}\" allow-clear=\"true\">{{$item.label}}</ui-select-match><ui-select-choices repeat=\"choice.value as choice in " + tAttrs.choices + " | filter: $select.search\" track by $index\"><div ng-bind-html=\"choice.label | highlight: $select.search\"></div></ui-select-choices></ui-select>";
            } else {
                var value = "<ui-select ng-model=\"" + tAttrs.advModel + "\" theme=\"bootstrap\" name=\"" + tAttrs.name + "_select" + "\" ng-required=\"" + tAttrs.advRequired + "\"" + (tAttrs.advRule ? " adv-rule=\"" + tAttrs.advRule + "\"" : "") + (tAttrs.advResponse ? " adv-response=\"" + tAttrs.advResponse + "\"" : "") + " reset-search-input=\"false\"><ui-select-match placeholder=\"{{::" + tAttrs.field + ".placeholder}}\" allow-clear=\"true\">{{$select.selected.label}}</ui-select-match><ui-select-choices repeat=\"choice.value as choice in " + tAttrs.choices + " | filter: $select.search\" track by $index><div ng-bind-html=\"choice.label | highlight: $select.search\"></div></ui-select-choices></ui-select>";
                return value;
            }
            /* tslint:enable: max-line-length */

        };


        dir.link = (scope: IAdvSelectScope, element: angular.IAugmentedJQuery, attrs: IAdvSelectAttributes) => {
            var field = $parse(attrs.field)(scope);

            if (field.showEmptyOption) {
                var empty: IChoice = {
                    selected: true,
                    label: field.emptyOption,
                    value: null
                };

                field.choiceList.choices.unshift(empty);
            }

            if (attrs.advModel && attrs.default) {
                var parsed = $parse(attrs.default);
                var parsedValue = parsed(scope) || attrs.default;
                var modelValue = $parse(attrs.advModel)(scope);
                if (angular.isDefined(attrs.multiple)) {
                    if (parsedValue.indexOf("vm.") !== -1) {
                        $parse(attrs.advModel).assign(scope, null);
                    }
                    else if (modelValue === undefined) {
                        if (parsedValue !== null) {
                            var test2 = parsedValue.split(',');
                            $parse(attrs.advModel).assign(scope, test2);
                        }
                    }
                    else {
                        //if (parsedValue.indexOf("vm.") !== 0) {
                        //    $parse(attrs.advModel).assign(scope, null);
                        //}
                        if (typeof modelValue === "string") {
                            //var test = modelValue.split(',');
                            //$parse(attrs.advModel).assign(scope, test);
                            $parse(attrs.advModel).assign(scope, modelValue);
                        }
                        //else if (typeof modelValue === "string" && (modelValue === '' || modelValue === null)) {
                        //    console.log('modelvalue is string and is empty or null');
                        //    $parse(attrs.advModel).assign(scope, null);
                        //}
                        else if (typeof modelValue === "number") {
                            var test3 = modelValue.toString();
                            $parse(attrs.advModel).assign(scope, test3);
                        }
                    }
                }
                if (!modelValue && (typeof parsedValue !== "string" || parsedValue.indexOf("vm.") !== 0)) {
                    if (angular.isDefined(attrs.multiple)) {
                        var test1 = parsedValue.split(',');
                        $parse(attrs.advModel).assign(scope, test1);
                    }
                    else {
                        $parse(attrs.advModel).assign(scope, parsedValue);
                    }
                    scope.$applyAsync();
                }
            }
        };

        return dir;
    }

    angular.module("app").directive("advSelect", advSelect);
}