/// <reference path="../rules/ruleservice.ts" />
/// <reference path="../app.ts" />

namespace Advant.Crossroads {
    "use strict";

    export interface IApplicationRouteParams extends angular.route.IRouteParamsService {
        organization: string;
        program: string;
        application: string;
    }

    interface IApplicationScope extends angular.IScope {
        vm: Application;
        applicationForm: angular.IFormController;
        modal: any;
    }

    interface IApplicationRootScope extends angular.IScope {
        stylesheets: Array<any>;
    }

    interface ITranscriptInfo {
        schoolCeeb?: string;
        studentId?: string;
        sendTranscript: boolean;
    }

    interface IApplication {
        loading: boolean;
        appDef: IApplicationDefinition;
        userApplication: IUserApplication;
        response: angular.resource.IResource<any>;
        closed: boolean;
        paymentInfo: IPaymentViewModel;
        logoPath: string;
        currentSection: string;
        userInfo: IUserInfoModel;
        user: any;
        showPayment: (type: string) => boolean;
        majors: Array<IMajor>;
        majorLists: Array<IMajorList>;
        submitting: boolean;
        saving: boolean;
        transcriptInfo: ITranscriptInfo;
        canSendTranscript: boolean;
        today: Date;
        fee: number;
        submittalMessage: string;
        studentIdFieldKey: string;
    }

    class Application implements IApplication {
        static controllerId: string = "application";
        intervalPromise: angular.IPromise<any>;
        static $inject: any = ["$scope", "$rootScope", "$routeParams", "$location", "$sce", "$http", "$modal", "authentication",
            "common", "config", "userApplicationService", "Restangular", "ruleService", "$anchorScroll", "payeezyService", "$interval"];
        loading: boolean;
        errors: Array<any> = [];
        log: (msg, data?, showToast?) => void;
        logError: (msg, data?, showToast?) => void;
        appDef: IApplicationDefinition;
        userApplication: IUserApplication;
        response: angular.resource.IResource<any>;
        paymentInfo: IPaymentViewModel;
        currentSection: string;
        userInfo: IUserInfoModel;
        logoPath: string;
        closed: boolean;
        majors: Array<IMajor>;
        majorLists: Array<IMajorList>;
        submitting: boolean;
        saving: boolean;
        transcriptInfo: ITranscriptInfo = { sendTranscript: false };
        canSendTranscript: boolean = false;
        today = new Date();
        fee: number;
        submittalMessage: string;
        apploadStart: number;
        apploadComplete: number;
        paymentForm: any;
        user: any;
        activeTab: number;
        studentIdFieldKey: string;

        constructor(private $scope: IApplicationScope,
            private $rootScope: IApplicationRootScope,
            private $routeParams: IApplicationRouteParams,
            private $location: angular.ILocationService,
            private $sce: angular.ISCEService,
            private $http: angular.IHttpService,
            private $modal: mgcrea.ngStrap.modal.IModalService,
            private authentication: IAuthenticationProviderService,
            private common: ICommonService,
            private config: ICrossroadsConfig,
            private userApplicationService: IUserApplicationService,
            private Restangular: Restangular.IService,
            private ruleService: Rules.IRuleService,
            private $anchorScroll: angular.IAnchorScrollService,
            private payeezyService: IPayeezyService,
            private $interval: angular.IIntervalService) {

            this.apploadStart = performance.now();
            this.loading = true;
            this.common.$broadcast(config.events.spinnerToggle, { show: true });
            this.response = null;

            this.authentication.authenticate().then((result) => {
                if (result) {
                    this.closed = false;
                    this.log = common.logger.getLogFn(Application.controllerId);
                    this.logError = common.logger.getLogFn(Application.controllerId, "error");
                    this.submitting = false;
                    this.saving = false;

                    this.userInfo = this.authentication.getUser();
                    this.activate();
                    this.$rootScope.stylesheets = [];
                    var baseStyleSheet = {
                        link: "/content/application.css"
                    };

                    this.$rootScope.stylesheets.push(baseStyleSheet);
                    this.intervalPromise = this.$interval(this.autoSave, 300000);
                }
                else {
                    return;
                }
            });
        }

        private activate() {
            var self = this;
            this.common.activateController([this.getUserData(), this.getAppDef()], Application.controllerId)
                .then(() => {
                    self.log("Activated Application Screen");
                    this.loading = false;
                    this.apploadComplete = performance.now();

                    if (this.userApplication) {
                        appInsights.trackEvent("UserApplicationFormLoaded",
                            {
                                ApplicationDefinitionId: this.appDef.applicationDefinitionId,
                                Name: this.appDef.name,
                                UserApplicationId: this.userApplication.id
                            },
                            { "ApplicationFormLoadTime": this.apploadComplete - this.apploadStart });
                    }

                    if (this.appDef.applicationMessageEnabled) {
                        this.showApplicationMessage();
                    }
                });

        }

        private isUpperCase = (value: string): boolean => {
            if (!isNaN(<any>value * 1)) {
                return false;
            } else {
                if (value === value.toUpperCase()) {
                    return true;
                } else {
                    return false;
                }
            }
        };

        private convertCase = (value: string): string => {
            if (!value) {
                return value;
            }

            if (!this.isUpperCase(value.charAt(0))) {
                return value;
            }

            var chars = value.split("");

            for (var i = 0; i < chars.length; i++) {
                var hasNext = (i + 1 < chars.length);

                if (i > 0 && hasNext && !this.isUpperCase(chars[i + 1])) {
                    break;
                }

                chars[i] = chars[i].toLowerCase();
            }

            return chars.join("");
        };

        onCreate = (paymentForm) => {

            this.paymentForm = paymentForm;
        };
        authorizeSession = (callback: any) => {


            this.$http({
                method: "POST",
                url: this.config.apiHost + "/" + this.appDef.applicationDefinitionId + "/payments/authorizeSession"
            }).then((response: any) => {
                if (response.status >= 200 && response.status < 300) {
                    callback(response.data);
                }
            },
                (response) => {
                    this.submitting = false;
                    this
                        .logError("An error occurred while trying to authorize your card. This is NOT normally an issue with your credit card. Please try again or contact us at 866-866-CFNC(2362) if the error continues.", response, true);
                });
        };

        checkCanSendTranscript = (): void => {
            if (this.studentIdFieldKey) {
                this.transcriptInfo.studentId = this.response[this.convertCase(this.studentIdFieldKey)];
            }

            if (this.appDef.canReceiveTranscripts && this.transcriptInfo.studentId && this.transcriptInfo.schoolCeeb) {
                this.Restangular.all("transcripts").one("canSend").get({ studentId: this.transcriptInfo.studentId, schoolCeeb: this.transcriptInfo.schoolCeeb }).then((result) => {
                    this.canSendTranscript = result;
                }, (reason) => {
                    this.canSendTranscript = false;
                    this.logError("Failed to check transcript status", reason, false);
                });
            } else {
                this.canSendTranscript = false;
            }
        };


        getAppDef() {
            return this.Restangular.all("application")
                .customGET("/" + this.$routeParams.organization + "/" + this.$routeParams.program + "/" + this.$routeParams.application).then((result) => {
                    this.log("Retrieved Application Definition");

                    return this.getAppResponse(result.applicationDefinitionId).then(() => {
                        angular.forEach(result.form.sections, (section) => { section.hasBeenLoaded = false; });
                        this.appDef = result;
                        this.logoPath = this.config.apiHost + "/application/" + this.appDef.applicationDefinitionId + "/logo";

                        var appStyleSheet = {
                            link: this.config.apiHost + "/application/" + this.appDef.applicationDefinitionId + "/stylesheet"
                        };

                        this.$rootScope.stylesheets.push(appStyleSheet);

                        var application: any = this.appDef;

                        if (application && application.form && application.form.sections.length > 0) {
                            application.form.sections[0].active = true;
                        }

                        if (this.closed)
                            return;

                        if (this.response) {
                            var groups = _.flatten(_.map(this.appDef.form.sections, "groups"));
                            angular.forEach(groups, (group: IGroup) => {
                                angular.forEach(group.fields, (field: IInputField) => {
                                    if (field.studentIdField) {
                                        this.studentIdFieldKey = field.key;
                                        this.transcriptInfo.studentId = this.response[this.convertCase(field.key)];
                                    }
                                    if (field.mostRecentHighSchoolCeeb) {
                                        this.transcriptInfo.schoolCeeb = this.response[this.convertCase(field.key)];
                                    }
                                });
                            });

                            this.checkCanSendTranscript();
                        }

                        // returns a promise that will complete when all of the other data request are finished.
                        return this.common.$q.all([this.getAppPaymentInfo(), this.getMajors(), this.getMajorLists()]);
                    });

                }, (reason) => {
                    this.logError("Failed to Retrieve Application Definition", reason, true);
                });
        }

        getAppResponse = (appId) => {
            return this.Restangular.one("userApplications").customGET("current/applicationDefinition?appDefId=" + appId).then((result) => {
                this.log("Retrieved user's application response.");
                this.userApplication = result;
                this.response = result.applicationResponse;
            }, (reason) => {
                if (reason.data && reason.data.modelState && reason.data.modelState.applicationClosed) {
                    this.closed = true;
                } else {
                    this.logError("Failed to retrieve the user's application response.", reason);
                }
            });
        };

        getMajors = (): angular.IPromise<Array<IMajor>> => {
            this.majors = [];
            var queryParams = {
                page: 1,
                pageSize: 128,
                sortBy: "Name",
                sortDirection: "ASC",
                filter: ""
            };


            return this.Restangular.one(this.appDef.applicationDefinitionId).withHttpConfig({ cache: true }).getList<IMajor>("majors", queryParams).then((result) => {
                this.majors = this.Restangular.stripRestangular(result);
                return result;
            }, (reason) => {
                this.logError("An error occurred while getting the majors", reason);
                return this.majors;
            });
        };

        getMajorLists = (): angular.IPromise<Array<IMajorList>> => {
            this.majorLists = [];

            return this.Restangular.all(this.appDef.applicationDefinitionId).all("majorlists").withHttpConfig({ cache: true }).getList<IMajorList>().then((result) => {
                this.majorLists = _.forEach(result, (value) => {
                    value.id = value.id.toLowerCase();
                });
                return this.majorLists;
            }, (reason) => {
                this.logError("An error occurred while getting the major lists", reason);
                return this.majorLists;
            });
        };

        getPaymentInfo = () => {
            return this.Restangular.all("userApplications").one("paymentOptions").get().then((result) => {
                this.log("Retrieved payment options");
                this.paymentInfo = result;
                if (this.showPayment("NoPayment")) {
                    this.paymentInfo.paymentMethod = 'NoPayment';
                }
            }, (reason) => {
                this.logError("Failed to retrieve payment options", reason);
            });
        };

        getAppPaymentInfo = () => {
            return this.Restangular.one("userApplications").customGET("applicationPaymentOptions?appDefId=" + this.appDef.applicationDefinitionId).then((result) => {
                this.log("Retrieved payment options");
                console.log(result);
                this.paymentInfo = result;
                this.paymentInfo.availableCardTypes = _.drop(this.paymentInfo.availableCardTypes);
                if (this.showPayment("NoPayment")) {
                    this.paymentInfo.paymentMethod = 'NoPayment';
                }
            }, (reason) => {
                this.logError("Failed to retrieve payment options", reason);
            });
        };

        getUserData = () => {
            return this.Restangular.all("account").one("fullUserInfo").get().then((result) => {
                this.log("Retrieved user data");
                this.user = result;
            }, (reason) => {
                this.logError("Failed to retrieve user", reason);
            });
        };

        saveApplication() {
            var startedSaving = performance.now();
            var form: any = this.$scope.applicationForm;
            form.$submitted = true;
            this.saving = true;
            if (this.$scope.applicationForm.$invalid) {
                var confirmation = this.$modal(<any>{
                    templateUrl: "adv/template/saveConfirmation.html",
                    backdrop: "static",
                    controller: SaveDialog.controllerId,
                    controllerAs: "vm",
                    locals: {
                        local: {
                            confirm: () => {
                                this.log("Saving Application", this.userApplication, false);
                                this.userApplication.put()
                                    .then(() => {
                                        // save was succesful
                                        this.saving = false;
                                        appInsights.trackEvent("UserApplicationSaved",
                                            {
                                                ApplicationDefinitionId: this.appDef.applicationDefinitionId,
                                                Name: this.appDef.name,
                                                UserApplicationId: this.userApplication.id
                                            },
                                            {
                                                "UserApplicationSaveDuration": performance.now() - startedSaving
                                            });
                                        this.log("Application saved successfully", "Application saved successfully", true);
                                        confirmation.hide();
                                    },
                                        (reason) => {
                                            this.saving = false;
                                            this.logError("An error occurred while trying to save your application.",
                                                reason);
                                            confirmation.hide();
                                        });
                            },
                            cancel: () => {
                                // user canceled the dialog
                                this.saving = false;
                                appInsights.trackEvent("UserApplicationSaveCanceled",
                                    {
                                        ApplicationDefinitionId: this.appDef.applicationDefinitionId,
                                        Name: this.appDef.name,
                                        UserApplicationId: this.userApplication.id
                                    });
                                confirmation.hide();
                            }
                        }
                    }
                });

            } else {
                this.userApplication.put().then(() => {
                    this.saving = false;
                    appInsights.trackEvent("UserApplicationSaved",
                        {
                            ApplicationDefinitionId: this.appDef.applicationDefinitionId,
                            Name: this.appDef.name,
                            UserApplicationId: this.userApplication.id
                        },
                        {
                            "UserApplicationSaveDuration": performance.now() - startedSaving
                        });
                    this.log("Application saved successfully", "Application saved successfully", true);
                }, (reason) => {
                    this.logError("An error occurred while trying to save your application.", reason);
                });
            }

        }

        saveApplicationAndOpenRDS() {
            var confirmation = this.$modal({
                templateUrl: "adv/template/rdsSaveConfirmation.html",
                controller: RdsSaveDialog.controllerId,
                controllerAs: "vm",
                locals: {
                    local: {
                        confirm: () => {
                            this.log("Saving Application", this.userApplication, false);
                            this.saveApplicationWithoutConfirmation().then(() => {
                                window.open(this.config.rdsUrl, "_blank");
                            });
                        },
                        cancel: () => {
                            // user canceled the dialog
                            this.saving = false;
                            confirmation.hide();
                        }
                    }
                }
            });
        }

        saveApplicationWithoutConfirmation(): ng.IPromise<any> {
            var defer = this.common.$q.defer();

            this.saving = true;
            this.log("Saving Application", this.userApplication, false);
            this.userApplication.put()
                .then(() => {
                    // save was succesful
                    this.saving = false;
                    this.log("Application saved successfully", "Application saved successfully", true);
                    defer.resolve();
                },
                    (reason) => {
                        this.saving = false;
                        this.logError("An error occurred while trying to save your application.",
                            reason);
                        defer.reject(reason);
                    });
            return defer.promise;
        }

        autoSave = () => {
            if (!this.closed) {
                this.userApplication.put()
                    .then(() => {
                    },
                        (reason) => {
                        });
            }
        }

        submitApplication() {
            var startedSubmitting = performance.now();
            var form: any = this.$scope.applicationForm;
            form.$submitted = true;
            this.submitting = true;
            var confirmation = this.$modal(<any>{
                templateUrl: "submitConfirmation.html",
                backdrop: "static",
                controller: SaveDialog.controllerId,
                controllerAs: "vm",
                locals: {
                    local: {
                        confirm: () => {
                            if (this.paymentInfo.cardNumber) {
                                this.paymentInfo.cardNumber = this.paymentInfo.cardNumber.replace(/\D/g, "");
                            }

                            var value = {
                                application: this.userApplication,
                                payment: this.paymentInfo,
                                transcript: this.transcriptInfo
                            };
                            if (this.paymentInfo.paymentMethod === "CreditCard" && this.appDef.paymentFields && this.appDef.paymentFields.name === "Payeezy") {
                                const onSuccess = (clientToken) => {
                                    console.log(`submit success; clientToken="${clientToken}"`);
                                    this.paymentForm.reset(() => { });
                                    value.payment.token = clientToken;
                                    this.submit(value, startedSubmitting);
                                };

                                const onError = (error) => {
                                    if (error.message && error.message === "form validation failed") {
                                        error.message =
                                            "Credit card information is invalid. Please fix any errors and try again.";
                                    }
                                    this.submitting = false;
                                    this
                                        .logError(error.message, error, true);
                                };

                                this.paymentForm.onSubmit(onSuccess, onError);
                            } else {
                                this.submit(value, startedSubmitting);
                            }

                            confirmation.hide();
                        },
                        cancel: () => {
                            // user canceled the dialog
                            this.submitting = false;
                            confirmation.hide();
                        }
                    }
                }
            });
        }


        submit = (value, startedSubmitting: number) => {
            this.$interval.cancel(this.intervalPromise);

            this.Restangular.one(this.userApplication.id)
                .one("submit")
                .customPUT(value)
                .then(() => {
                    this.submitting = false;
                    this.$location.path("/application/completed/" + this.userApplication.id);
                    appInsights.trackEvent("UserApplicationSubmitted",
                        {
                            ApplicationDefinitionId: this.appDef.applicationDefinitionId,
                            Name: this.appDef.name,
                            UserApplicationId: this.userApplication.id
                        },
                        {
                            "UserApplicationSubmitDuration": performance.now() - startedSubmitting
                        });

                    this.common.ga('send', 'event', 'application', 'submit', 'Submit College App', '1');
                },
                    (reason) => {
                        if (reason.status === 400 && reason.data && reason.data.message) {
                            this.logError(reason.data.message, reason, true);
                        } if (reason.data && reason.data.exceptionMessage === "The card type is invalid") {
                            this
                                .logError("The type of credit card provided is not accepted by this institution. Please try another card.", reason, true);
                        } else {
                            this
                                .logError("An unknown error has occurred while trying to submit your application. Please save and try again later.", reason, true);
                        }
                        this.submitting = false;
                        this.$interval(this.autoSave, 300000);
                    });
        };

        setActiveSection(section: any) {
            if (section !== "Submit Application") {

                this.currentSection = section.text;
                section.hasBeenLoaded = true;
            } else {
                this.calculateFee();
                this.parseSubmittalMessage();
                this.checkCanSendTranscript();
                this.currentSection = section;
            }
            if (this.userApplication) {
                appInsights.trackPageView(`CrossRoads: ${this.appDef.name} - ${this.currentSection}`, this.$location.url(), {
                    ApplicationDefinitionId: this.appDef.applicationDefinitionId,
                    Name: this.appDef.name,
                    UserApplicationId: this.userApplication.id
                });
            }

        }

        parseSubmittalMessage = () => {
            this.submittalMessage = this.appDef.submittalMessage.replace("[[Fee]]", this.fee ? this.fee.toString() : "0");
        };

        calculateFee = () => {
            if (this.closed || this.showPayment("NoPayment")) {
                return;
            }
            var dynamicFees = this.appDef.dynamicFees;

            for (var i = 0; i < dynamicFees.length; i++) {
                var dynamicFee = dynamicFees[i];
                if (!dynamicFee.ruleId || this.ruleService.evalRuleSet(dynamicFee.ruleSet, this.userApplication.applicationResponse)) {
                    this.fee = dynamicFee.fee;
                    break;
                }
            }
        };

        validateSection = (section: ISection): angular.IPromise<boolean> => {
            if (section && this.userApplication) {
                return this.Restangular.one(this.userApplication.id).one(section.name).one("validate").customPUT(this.userApplication).then((serviceResult: boolean) => {
                    return serviceResult;
                }, reason => {
                    this.logError("An unknown error has occurred while trying to validate an unloaded section.", reason, true);
                    return false;
                });
            } else {
                var defered = this.common.$q.defer<boolean>();
                defered.resolve(false);
                return defered.promise;
            }
        };

        isSectionValid(section: ISection): boolean {
            var groupsCompiled = _.map(section.groups, "isCompiled");
            var compiled = true;
            angular.forEach(groupsCompiled, (value: boolean) => {
                if (!value) {
                    compiled = false;
                }
            });

            return compiled;
        }


        /**
         * Method used by RequiredIf properties to evaluate the result of the provided rule
         * @param ruleSet   The rule that is assigned to the RequiredIf parameter of field
         * @returns {boolean} True if the field should be required otherwise false
         */
        isRequired(ruleSet: Rules.IRuleSet): boolean {
            return this.ruleService.evalRuleSet(ruleSet, this.userApplication.applicationResponse);
        }

        showPayment(type: string) {
            var definition: any = this.appDef;
            var result = definition.acceptedPayments.indexOf(type) > -1;

            if (result) {
                switch (type) {
                    case "NoPayment":
                        if (definition.noPaymentRuleId) {
                            result = this.ruleService.evalRuleSet(definition.noPaymentRule, this.userApplication.applicationResponse);
                        }
                        break;
                    case "Check":
                        if (definition.checkRuleId) {
                            result = this.ruleService.evalRuleSet(definition.checkRule, this.userApplication.applicationResponse);
                        }
                        break;
                    case "CreditCard":
                        if (definition.creditCardRuleId) {
                            result = this.ruleService.evalRuleSet(definition.creditCardRule, this.userApplication.applicationResponse);
                        }
                        break;
                    case "FeeWaiver":
                        if (definition.feeWaiverRuleId) {
                            result = this.ruleService.evalRuleSet(definition.feeWaiverRule, this.userApplication.applicationResponse);
                        }
                        break;
                    case "PayLater":
                        if (definition.payLaterRuleId) {
                            result = this.ruleService.evalRuleSet(definition.payLaterRule, this.userApplication.applicationResponse);
                        }
                        break;
                    default:
                        result = false;
                }
            }

            if (!result && this.paymentInfo.paymentMethod == type) {
                this.paymentInfo.paymentMethod = null
            }

            return result;
        }

        render(snippet) {
            return this.$sce.trustAsHtml(snippet);
        }

        wordCount = (text: string): number => {
            var s = text ? text.split(/\s+/).length : 0;
            return s;
        };

        logout = () => {
            this.$location.path("/application/logout");
            //this.authentication.logout();
        };

        getChoiceLabel(fieldKey) {
            var field: any;
            var choiceListId;
            this.appDef.form.sections.forEach((section) => {
                section.groups.forEach((group: any) => {
                    if (group.fields.hasOwnProperty(fieldKey)) {
                        field = group.fields[fieldKey];
                        if (field.hasOwnProperty("choiceListId")) {
                            choiceListId = field.choiceListId.replace("C", "c");
                        }
                    }
                });
            });

            if (choiceListId) {
                var choiceList = this.appDef["choiceLists"][choiceListId];
                var choiceLabel = _.result<string>(_.find(choiceList.choices, { value: this.response[fieldKey] }), "label");
                return choiceLabel;
            } else {
                return "";
            }


        }

        finishApplication() {
            let form = this.$scope.applicationForm;
            form.$submitted = true;
            if (!form.$invalid) {
                this.setActiveSection('Submit Application');
                this.activeTab = this.appDef.form.sections.length
            }
            else {
                var warning = this.$modal({
                    title: "Unable to Continue",
                    content: "Some fields on your application are invalid or incomplete. Please return to pages without a checkmark indicator and correct the questions in red to complete your application.",
                    locals: {
                        local: {
                            cancel: () => {
                                warning.hide();
                            }
                        }
                    }
                });
            }
        }

        showApplicationMessage() {
            var applicationMessage = this.$modal(<any>{
                scope: this.$scope,
                controllerAs: "vm",
                templateUrl: "adv/template/applicationMessage.html",
                backdrop: "static",
                locals: {
                    local: {
                        close: () => {
                            applicationMessage.hide();
                        }
                    }
                }
            });
        }
    }

    angular.module("app").controller(Application.controllerId, Application);
}