namespace Advant.Crossroads {

    interface IHighSchoolSearch {
        highSchools: IHighSchool[];
        searchFilter: string;
        selectedSchool: IHighSchool;
        state: string;
        schoolName: string;
        stateList: any;
        unlistedSchool: boolean;
        search?: () => void;
        selectSchool?: (highSchool: IHighSchool) => void;
        selectSchoolNotInList?: () => void;
        nextPage?: () => void;
        clear: (event: angular.IAngularEvent) => void;
        close: () => void;

    }

    interface IAdvHighSchool extends angular.IDirective {
    }

    interface IAdvHighSchoolScope extends angular.IScope {
        $schoolSearch: IHighSchoolSearch;
        ngModel: any;
        ceebField: any;
        typeField: any;
        streetField: any;
        street2Field: any;
        cityField: any;
        stateField: any;
        countryField: any;
        postalField: any;
        totalPages?: number;
    }

    class AdvHighSchool implements IAdvHighSchool {
        static directiveId: string = "advHighSchool";
        restrict: string = "EA";
        priority = 1;
        templateUrl: string = "/app/directives/advHighSchool.html";
        scope: any = {
            ngModel: "=",
            ceebField: "=",
            typeField: "=",
            streetField: "=",
            street2Field: "=",
            cityField: "=",
            stateField: "=",
            countryField: "=",
            postalField: "=",
            placeholder: "@"
        }

        constructor(private $document: angular.IDocumentService,
            private $uibPosition: angular.ui.bootstrap.IPositionService,
            private $parse: angular.IParseService,
            private $timeout: angular.ITimeoutService,
            private highSchoolLookup: IHighSchoolLookup,
            private stateLookup: IStateLookup) {
        }

        link = (scope: IAdvHighSchoolScope, element: JQuery, attrs) => {
            scope.$schoolSearch = {
                highSchools: null,
                searchFilter: null,
                selectedSchool: null,
                unlistedSchool: false,
                state: "NC",
                stateList: null,
                schoolName: scope.ngModel,
                clear: null,
                close: null
            };

            var lookupBox = element.find("[name = 'schoolLookupBox']").first();
            var schoolField = element.find("[name = 'schoolNameField']").first();
            var schoolFinder = element.find("[name = 'schoolFinder']").first();
            var schoolList = lookupBox.find(".school-list").first();
            var schoolId = this.$parse(attrs.ngModel + "Id")(scope.$parent);

            var schoolNameWatch, searchWatch, stateWatch;

            scope.$schoolSearch.stateList = this.stateLookup.getStates();

            scope.$schoolSearch.unlistedSchool = !schoolId && scope.ngModel;

            if (!scope.$schoolSearch.unlistedSchool) {
                this.disableFields(attrs);
            }

            var turnOnSchoolNameWatch = () => {
                schoolNameWatch = scope.$watch((listenScope: IAdvHighSchoolScope) => { return listenScope.$schoolSearch.schoolName; }, (newValue) => { scope.ngModel = newValue; });
            };

            if (scope.$schoolSearch.unlistedSchool) {
                turnOnSchoolNameWatch();
            }


            var turnOnWatches = () => {
                turnOnSchoolNameWatch();
                searchWatch = scope.$watch((listenScope: IAdvHighSchoolScope) => { return listenScope.$schoolSearch.searchFilter; }, () => {
                    scope.$schoolSearch.search();
                });

                stateWatch = scope.$watch((listenScope: IAdvHighSchoolScope) => { return listenScope.$schoolSearch.state; }, (newValue, oldValue) => {
                    if (newValue === oldValue) {
                        return;
                    }
                    this.highSchoolLookup.state = newValue;
                    scope.$schoolSearch.search();
                });
            }

            var turnOffWatches = () => {
                schoolNameWatch();
                searchWatch();
                stateWatch();
            }

            var turnOffJustSearchWatches = () => {
                searchWatch();
                stateWatch();
            }

            schoolField.click((event) => {
                event.preventDefault();
                var position = this.$uibPosition.position(element);
                lookupBox.css({ top: (position.top - 320 < -150 ? position.top + 60 : position.top - 400), left: position.left + 20 });
                if (!scope.$schoolSearch.unlistedSchool) {
                    lookupBox.toggleClass("hidden");
                    scope.$schoolSearch.search();
                    turnOnWatches();
                }
            });

            schoolFinder.click((event) => {
                event.preventDefault();
                schoolNameWatch();
                lookupBox.toggleClass("hidden");
                scope.$schoolSearch.search();
                turnOnWatches();
                scope.$schoolSearch.unlistedSchool = false;
                scope.$digest();
            });



            scope.$schoolSearch.search = () => {
                this.highSchoolLookup.state = scope.$schoolSearch.state;
                this.highSchoolLookup.searchFilter = scope.$schoolSearch.searchFilter;
                if (scope.$schoolSearch.searchFilter) {
                    this.highSchoolLookup.sortByRelevance = true;
                } else {
                    this.highSchoolLookup.sortByRelevance = false;
                }

                this.highSchoolLookup.page = 1;
                schoolList.scrollTop(0);
                this.highSchoolLookup.getHighSchools().then((result) => {
                    scope.$schoolSearch.highSchools = result;
                });
            };

            scope.$schoolSearch.selectSchoolNotInList = () => {
                this.$parse(attrs.ngModel + "Id").assign(scope.$parent, null);
                scope.$schoolSearch.schoolName = "";
                scope.$schoolSearch.unlistedSchool = true;
                if (angular.isDefined(attrs.ceebField)) {
                    scope.ceebField = "000003";
                }
                this.enableFields(attrs);
                turnOffJustSearchWatches();
                lookupBox.addClass("hidden");
            };

            scope.$schoolSearch.selectSchool = (highSchool: IHighSchool) => {
                scope.ngModel = highSchool.name;
                this.$parse(attrs.ngModel + "Id").assign(scope.$parent, highSchool.id);
                scope.$applyAsync(attrs.ngModel + "Id");
                scope.$schoolSearch.schoolName = highSchool.name;
                scope.$schoolSearch.unlistedSchool = false;

                if (angular.isDefined(attrs.ceebField)) {
                    scope.ceebField = highSchool.ceebCode;
                }

                if (angular.isDefined(attrs.typeField)) {
                    scope.typeField = highSchool.highSchoolType;
                }

                if (angular.isDefined(attrs.streetField)) {
                    scope.streetField = highSchool.address.street1;
                }

                if (angular.isDefined(attrs.street2Field)) {
                    scope.street2Field = highSchool.address.street2;
                }

                if (angular.isDefined(attrs.cityField)) {
                    scope.cityField = highSchool.address.city;
                }

                if (angular.isDefined(attrs.stateField)) {
                    scope.stateField = highSchool.address.state;
                }

                if (angular.isDefined(attrs.countryField)) {
                    scope.countryField = highSchool.address.country || "US";
                }

                if (angular.isDefined(attrs.postalField)) {
                    scope.postalField = highSchool.address.zip;
                }

                this.disableFields(attrs);
                turnOffWatches();
                lookupBox.addClass("hidden");
            };

            scope.$schoolSearch.nextPage = () => {
                if (this.highSchoolLookup.totalPages > this.highSchoolLookup.page) {
                    this.highSchoolLookup.page += 1;
                    this.highSchoolLookup.getHighSchools().then((result) => {
                        scope.$schoolSearch.highSchools = scope.$schoolSearch.highSchools.concat(result);
                    });
                }
                
            };

            scope.$schoolSearch.clear = (event: angular.IAngularEvent) => {
                event.preventDefault();

                scope.$schoolSearch.schoolName = null;
                this.$parse(attrs.ngModel + "Id").assign(scope.$parent, null);
                this.$parse(attrs.ngModel).assign(scope.$parent, null);

                this.clearFields(scope, attrs);
            };

            scope.$schoolSearch.close = () => {
                turnOffWatches();
                lookupBox.addClass("hidden");
            };

        }

        disableFields = (attrs) => {
            // Have to run this code in $timeout because we need to run after the last $digest cycle in case any of these fields
            // are hidden
            this.$timeout(() => {
                if (angular.isDefined(attrs.ceebFieldDisabled)) {
                    this.$document.find("[ng-model='" + attrs.ceebField + "']").attr("disabled", "disabled");
                }
                if (angular.isDefined(attrs.typeFieldDisabled)) {
                    this.$document.find("[ng-model='" + attrs.typeField + "']").attr("disabled", "disabled");
                }
                if (angular.isDefined(attrs.streetFieldDisabled)) {
                    this.$document.find("[ng-model='" + attrs.streetField + "']").attr("disabled", "disabled");
                }
                if (angular.isDefined(attrs.street2FieldDisabled)) {
                    this.$document.find("[ng-model='" + attrs.street2Field + "']").attr("disabled", "disabled");
                }
                if (angular.isDefined(attrs.cityFieldDisabled)) {
                    this.$document.find("[ng-model='" + attrs.cityField + "']").attr("disabled", "disabled");
                }
                if (angular.isDefined(attrs.stateFieldDisabled)) {
                    this.$document.find("[ng-model='" + attrs.stateField + "']").attr("disabled", "disabled");
                    this.$document.find("[ng-model='" + attrs.stateField + "']").find("*").attr("disabled", "disabled");
                    this.$document.find("[ng-model='" + attrs.stateField + "']").find("*").css("pointerEvents", "none");
                }
                if (angular.isDefined(attrs.countryFieldDisabled)) {
                    this.$document.find("[ng-model='" + attrs.countryField + "']").attr("disabled", "disabled");
                    this.$document.find("[ng-model='" + attrs.countryField + "']").find("*").attr("disabled", "disabled");
                    this.$document.find("[ng-model='" + attrs.countryField + "']").find("*").css("pointerEvents", "none");
                }
                if (angular.isDefined(attrs.postalFieldDisabled)) {
                    this.$document.find("[ng-model='" + attrs.postalField + "']").attr("disabled", "disabled");
                }
            });
        };

        clearFields = (scope, attrs) => {
            if (angular.isDefined(attrs.ceebField)) {
                scope.ceebField = null;
            }

            if (angular.isDefined(attrs.typeField)) {
                scope.typeField = null;
            }

            if (angular.isDefined(attrs.streetField)) {
                scope.streetField = null;
            }

            if (angular.isDefined(attrs.street2Field)) {
                scope.street2Field = null;
            }

            if (angular.isDefined(attrs.cityField)) {
                scope.cityField = null;
            }

            if (angular.isDefined(attrs.stateField)) {
                scope.stateField = null;
            }

            if (angular.isDefined(attrs.countryField)) {
                scope.countryField = null;
            }

            if (angular.isDefined(attrs.postalField)) {
                scope.postalField = null;
            }

        };

        enableFields = (attrs) => {
            // Have to run this code in $timeout because we need to run after the last $digest cycle in case any of these fields
            // are hidden
            this.$timeout(() => {
                if (angular.isDefined(attrs.ceebFieldDisabled)) {
                    this.$document.find("[ng-model='" + attrs.ceebField + "']").removeAttr("disabled");
                }
                if (angular.isDefined(attrs.typeFieldDisabled)) {
                    this.$document.find("[ng-model='" + attrs.typeField + "']").removeAttr("disabled");
                }
                if (angular.isDefined(attrs.streetFieldDisabled)) {
                    this.$document.find("[ng-model='" + attrs.streetField + "']").removeAttr("disabled");
                }
                if (angular.isDefined(attrs.street2FieldDisabled)) {
                    this.$document.find("[ng-model='" + attrs.street2Field + "']").removeAttr("disabled");
                }
                if (angular.isDefined(attrs.cityFieldDisabled)) {
                    this.$document.find("[ng-model='" + attrs.cityField + "']").removeAttr("disabled");
                }
                if (angular.isDefined(attrs.stateFieldDisabled)) {
                    this.$document.find("[ng-model='" + attrs.stateField + "']").removeAttr("disabled");
                    this.$document.find("[ng-model='" + attrs.stateField + "']").find("*").removeAttr("disabled");
                    this.$document.find("[ng-model='" + attrs.stateField + "']").find("*").css("pointerEvents", "");
                }
                if (angular.isDefined(attrs.countryFieldDisabled)) {
                    this.$document.find("[ng-model='" + attrs.countryField + "']").removeAttr("disabled");
                    this.$document.find("[ng-model='" + attrs.countryField + "']").find("*").removeAttr("disabled");
                    this.$document.find("[ng-model='" + attrs.countryField + "']").find("*").css("pointerEvents", "");
                }
                if (angular.isDefined(attrs.postalFieldDisabled)) {
                    this.$document.find("[ng-model='" + attrs.postalField + "']").removeAttr("disabled");
                }
            });
        };
    }

    angular.module("app").directive(AdvHighSchool.directiveId, ["$document", "$uibPosition", "$parse", "$timeout", "highSchoolLookup", "stateLookup",
        ($document, $uibPosition, $parse, $timeout, highSchoolLookup, stateLookup) =>
            new AdvHighSchool($document, $uibPosition, $parse, $timeout, highSchoolLookup, stateLookup)
    ]);
}