/// <reference path="../rules/ruleservice.ts" />
/// <reference path="../rules/rules.d.ts" />
/// <reference path="../app.ts" />
namespace Advant.Crossroads {

    interface IAdvTerm extends angular.IDirective {
    }

    interface IAdvTermScope extends angular.IScope {
        model: ITerm;
        ngModel: any;
        appdef: IApplicationDefinition;
        elemName: string;
        userApplication: IUserApplication;
        placeholder: string;
        availableTerms: Array<ITerm>;
        advResponse: any;
        anyDefault: any;
    }

    class AdvTerm implements IAdvTerm {
        static directiveId: string = "advTerm";
        restrict: string = "E";
        require: string = "?ngModel";
        templateUrl = "/app/directives/advTerm.html";
        model: ITerm;

        scope: any = {
            ngModel: "=",
            appdef: "=",
            userApplication: "=",
            advResponse: "=",
            placeholder: "@"
        };

        constructor(private q$: angular.IQService, private ruleService: Rules.IRuleService) {
        }

        link = (scope: IAdvTermScope, element, attrs, ctrl: angular.INgModelController) => {
            if (!ctrl) { return; }
            scope.elemName = attrs.name + "_select";
            scope.availableTerms = scope.appdef.availableTerms;

            scope.$watch("model", (newValue: ITerm, oldValue, originalScope) => {
                if (newValue && scope.userApplication) {
                    scope.userApplication.term = newValue;
                    scope.ngModel = newValue.code;
                }
            });

            scope.$watch("userApplication", (newValue: IUserApplication, oldValue, originalScope: any) => {
                if (newValue) {
                    originalScope.model = newValue.term;
                }
            });

            scope.$watch("advResponse", (newValue, oldValue, originalScope) => {
                this.getTermList(scope);
            }, true);
        };

        getTermList = (scope: IAdvTermScope) => {
            if (scope.appdef.availableTerms && scope.appdef.availableTerms.length > 0) {
                scope.availableTerms = [];
                var defaultT = _.find(scope.appdef.availableTerms, { "isDefault": true });
                scope.anyDefault = defaultT == undefined ? false : true;

                _.forEach(scope.appdef.availableTerms, (term) => {
                    if (term.rule) {
                        var result = this.ruleService.evalRuleSet(term.rule, scope.advResponse);

                        if (result) {
                            scope.availableTerms = scope.availableTerms.concat(term);
                        }
                    } else {
                        scope.availableTerms = scope.availableTerms.concat(term);
                    }

                });

                if (scope.model) {
                    const term = _.find(scope.availableTerms, { "code": scope.model.code, "name": scope.model.name });
                    const inCurrentList = term != undefined;

                    if (!inCurrentList) {
                        scope.model = null;
                        scope.ngModel = null;
                    } else {
                        scope.model = term;
                        scope.ngModel = term.code;
                    }
                }
            }
        };

    }

    angular.module("app").directive(AdvTerm.directiveId, ["$q", "ruleService", ($q, ruleService) =>
        new AdvTerm($q, ruleService)
    ]);
}