/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    export interface ISaveDialogScope extends mgcrea.ngStrap.modal.IModalScope {
        yes: () => void;
        no: () => void;
    }

    export interface ISaveDialog {

    }

    export class SaveDialog implements ISaveDialog {
        static controllerId: string = "saveDialog";
        static $inject: any = ["local"];

        constructor(private local) {
        }

        yes() {
            this.local.confirm();
        }

        no() {
            this.local.cancel();
        }
    }

    angular.module("app").controller(SaveDialog.controllerId, SaveDialog);
}
