/// <reference path="../rules/ruleservice.ts" />
/// <reference path="../rules/rules.d.ts" />
/// <reference path="../app.ts" />
namespace Advant.Crossroads {
    "use strict";

    interface IAdvMajor extends angular.IDirective {
    }

    interface IAdvMajorScope extends angular.IScope {
        activeMajors: Array<IMajor>;
        allMajors: Array<IMajor>;
        majorLists: Array<IMajorList>;
        availableTerms: Array<ITerm>;
        selectedMajorResponse: string;
        required: boolean;
        model: any;
        currentTerm: ITerm;
        advResponse: any;
        elemName: string;
        modelValue: any;
    }

    class AdvMajor implements IAdvMajor {
        static directiveId: string = "advMajor";
        restrict: string = "E";
        require: string = "?ngModel";
        templateUrl: string = "/app/directives/advMajor.html";
        scope: any = {
            model: "=ngModel",
            majorLists: "=",
            currentTerm: "=",
            availableTerms: "=",
            required: "@",
            advResponse: "=",
            placeholder: "@"
        };

        constructor(private q$: angular.IQService, private ruleService: Rules.IRuleService) {
        }


        link = (scope: IAdvMajorScope, element, attrs, ctrl: angular.INgModelController) => {
            if (!ctrl) { return; }
            scope.selectedMajorResponse = attrs.name.toLowerCase() + "SelectedMajor";
            scope.elemName = attrs.name + "_select";

            if (attrs.majorlist) {
                scope.$watchCollection("majorLists", (newValue: ITerm, oldValue, originalScope) => {
                    if (newValue) {
                        var list = <IMajorList>_.find(scope.majorLists, { "id": attrs.majorlist });
                        if (list) {
                            scope.activeMajors = _.sortBy(list.majors, "name");
                            scope.modelValue = this.getSelectedMajor(scope, attrs.majorFormat);
                        }
                    }
                });
            } else {
                scope.$watchCollection("majorLists", (newValue: ITerm, oldValue, originalScope) => {
                    if (newValue !== oldValue) {
                        this.getMajorList(scope, attrs.majorFormat);
                    }
                });

                scope.$watch("currentTerm", (newValue: ITerm, oldValue, originalScope) => {
                    this.getMajorList(scope, attrs.majorFormat);
                });


                scope.$watch("advResponse", (newValue, oldValue, originalScope) => {
                    this.getMajorList(scope, attrs.majorFormat);
                }, true);

                
                scope.$watch("modelValue", (newValue: any, oldValue, originalScope) => {
                    var majorCode = this.getMajorCode(scope, attrs.majorFormat);
                    if (newValue !== oldValue || (majorCode == null && scope.activeMajors != null)) {
                        if (newValue) {
                            if (newValue.code !== majorCode) {
                                scope.model = this.getMajorValue(newValue, attrs.majorFormat);
                            }

                            if (attrs.nameField) {
                                scope.advResponse[attrs.nameField] = newValue.name;
                            }
                            if (attrs.nameAndCodeField) {
                                scope.advResponse[attrs.nameAndCodeField] = newValue.code + "|" + newValue.name;
                            }
                        }
                        else {
                            scope.advResponse[attrs.nameField] = null;
                            scope.advResponse[attrs.nameAndCodeField] = null;
                            scope.model = null;
                        }
                    }
                });
            }

            scope.$watch("advResponse[selectedMajorResponse]", (newValue: IMajor, oldValue, originalScope) => {
                if (newValue) {

                    scope.model = this.getMajorValue(newValue, attrs.majorFormat);

                    if (attrs.nameField) {
                        scope.advResponse[attrs.nameField] = newValue.name;
                    }
                    if (attrs.nameAndCodeField) {
                        scope.advResponse[attrs.nameAndCodeField] = newValue.code + "|" + newValue.name;
                    }
                } else {
                    if (attrs.nameField) {
                        scope.advResponse[attrs.nameField] = null;
                    }
                    if (attrs.nameAndCodeField) {
                        scope.advResponse[attrs.nameAndCodeField] = null;
                    }
                    scope.model = null;
                }

            });
        };

        getMajorList = (scope: IAdvMajorScope, majorFormat) => {

            if (scope.majorLists && scope.majorLists.length > 0) {
                scope.activeMajors = [];
                var majorId: string;

                if (!scope.currentTerm) {
                    return;
                }

                var majorLists: Array<IMajorList> = _.filter(scope.majorLists,
                    (majorList) => {
                        return _.includes(scope.currentTerm.majorListIds, majorList.id);
                    });

                var rulePromises = [];
                _.forEach(majorLists,
                    (majorList) => {
                        if (majorList.ruleId) {
                            rulePromises.push(this.ruleService
                                .getAndEvalRuleSet(majorList.applicationDefinitionId,
                                majorList.ruleId,
                                scope.advResponse).then(result => {
                                    if (result) {
                                        scope.activeMajors = scope.activeMajors.concat(majorList.majors);
                                        scope.activeMajors = _.uniqBy(scope.activeMajors, "id");
                                        scope.activeMajors = _.sortBy(scope.activeMajors, "name");
                                    }
                                    return;
                                }));
                        } else {
                            scope.activeMajors = scope.activeMajors.concat(majorList.majors);
                            scope.activeMajors = _.uniqBy(scope.activeMajors, "id");
                            scope.activeMajors = _.sortBy(scope.activeMajors, "name");

                        }
                    });

                if (rulePromises.length > 0) {
                    this.q$.all(rulePromises).then(result => {
                        if (scope.advResponse[scope.selectedMajorResponse]) {
                            majorId = scope.advResponse[scope.selectedMajorResponse].id;
                        } else {
                            majorId = null;
                        }


                        const inCurrentList = _.includes(_.map(scope.activeMajors, "id"), majorId);

                        if (!inCurrentList) {
                            scope.model = null;
                            scope.advResponse[scope.selectedMajorResponse] = null;
                            scope.modelValue = null;
                        }
                    });
                } else {
                    if (scope.advResponse && scope.advResponse[scope.selectedMajorResponse]) {
                        majorId = scope.advResponse[scope.selectedMajorResponse].id;
                    } else {
                        majorId = null;
                    }

                    const inCurrentList = _.includes(_.map(scope.activeMajors, "id"), majorId);

                    if (!inCurrentList) {
                        scope.model = null;
                        scope.advResponse[scope.selectedMajorResponse] = null;
                    }
                }
            }
        };

        getMajorValue = (selectedMajor: IMajor, majorFormat: string) => {
            var major: IMajor;
            if (selectedMajor) {
                switch (majorFormat) {
                    case "Name":
                        return selectedMajor.name;
                    case "CodeAndName":
                        return selectedMajor.code + "|" + selectedMajor.name;
                    case "Code":
                    default:
                        return selectedMajor.code;
                }
            }
            else {
                return null;
            }
        };


        getSelectedMajor = (scope: IAdvMajorScope, majorFormat: string) => {
            var major: IMajor;
            if (scope.model) {
                switch (majorFormat) {
                    case "Name":
                        var name = scope.model
                        if (name.indexOf("|") > -1) {
                            name = name.split("|")[1];
                        }
                        major = <IMajor>_.find(scope.activeMajors, { "name": name });
                        break;
                    case "CodeAndName":
                    case "Code":
                    default:
                        var code = scope.model
                        if (code.indexOf("|") > -1) {
                            var code = code.split("|")[0];
                        }
                        major = <IMajor>_.find(scope.activeMajors, { "code": code });
                        break;
                }
            }

            return major;
        };

        getMajorCode = (scope: IAdvMajorScope, majorFormat: string) => {
            var major: IMajor;
            if (scope.model && scope.activeMajors) {
                switch (majorFormat) {
                    case "Name":
                        major = <IMajor>_.find(scope.activeMajors, { "name": scope.model });
                        break;
                    case "CodeAndName":
                    case "Code":
                    default:
                        var code = scope.model;
                        if (code.indexOf("|") > -1) {
                            var code = code.split("|")[0];
                        }
                        major = <IMajor>_.find(scope.activeMajors, { "code": code });
                        break;
                }

                if (major) {
                    return major.code;
                }
                else {
                    return null;
                }
            }
            return null;
        };




    }


    angular.module("app").directive(AdvMajor.directiveId, ["$q", "ruleService", ($q, ruleService) =>
        new AdvMajor($q, ruleService)
    ]);
}