/// <reference path="rules.ts" />

namespace Advant.Crossroads.Rules {
    "use strict";

    export interface IRuleService {
        evalRuleSet(ruleSet: IRuleSet, value: any): boolean;
        getAndEvalRuleSet(applicationDefinitionId: string, ruleId: string, value): angular.IPromise<boolean>;
        getPropertiesToWatch(ruleSet: IRuleSet): string[];
    }

    export class RuleService implements IRuleService {
        static serviceId: string = "ruleService";
        private compareOperator: ICompareOperator;
        private joinOperator: IJoinOperator;
        rules: Array<IRuleSet>;

        constructor(private $q: angular.IQService, private Restangular: Restangular.IService, private helper: IHelper) {
            this.compareOperator = {
                LessThan: "LessThan",
                LessThanEqualTo: "LessThanEqualTo",
                Equal: "Equal",
                GreaterThan: "GreaterThan",
                GreaterThanEqualTo: "GreaterThanEqualTo",
                NotEqual: "NotEqual",
                Contains: "Contains",
                DoesNotContain: "DoesNotContain",
                StartsWith: "StartsWith",
                EndsWith: "EndsWith",
                IsNull: "IsNull",
                IsNotNull: "IsNotNull"
            };

            this.joinOperator = {
                None: "None",
                And: "And",
                Or: "Or"
            };
        }

        uniqueArray(array: any[]): any[] {
            var a = array.concat();
            for (var i = 0; i < a.length; ++i) {
                for (var j = i + 1; j < a.length; ++j) {
                    if (a[i] === a[j]) {
                        a.splice(j--, 1);
                    }
                }
            }

            return a;

        }

        getPropertiesToWatch = (ruleSet: IRuleSet): string[] => {
            var propertyNames = [];
            angular.forEach(ruleSet.groups, (group: IRuleGroup) => {
                var groupPropertyNames = _.map(group.rules, "codeLeftValue");
                angular.forEach<any>(groupPropertyNames, (value, key) => {
                    groupPropertyNames[key] = this.helper.toCamelCase(value);
                });
                propertyNames = this.uniqueArray(propertyNames.concat(groupPropertyNames));

                var groupRightValues = _.map(group.rules, "valueRightValue");
                var regEx = /\[\[([^)]*)\]\]/;
                var groupRightProperties = [];

                angular.forEach<any>(groupRightValues, (value, key) => {
                    if (value && value.match(regEx)) {
                        groupRightProperties.push(this.helper.toCamelCase(regEx.exec(value)[1]));
                    }
                });

                if (groupRightProperties.length > 0) {
                    propertyNames = this.uniqueArray(propertyNames.concat(groupRightProperties));
                }
            });

            return propertyNames;
        };

        getAndEvalRuleSet(applicationDefinitionId: string, ruleId: string, value): angular.IPromise<boolean> {
            var getRule = this.getRule(applicationDefinitionId, ruleId);
            return getRule.then((result) => {
                return this.evalRuleSet(result, value);
            });
        }

        evalRuleSet(ruleSet: IRuleSet, value: any): boolean {
            if (!ruleSet) {
                return false;
            }
            ruleSet.isSelected = false;
            if (ruleSet.groups.length > 1) {
                switch (ruleSet.groups[1].ruleGroupJoinOperator) {
                    case this.joinOperator.And:
                        if (this.evalRuleGroup(ruleSet.groups[0], value) && this.evalRuleGroup(ruleSet.groups[1], value)) {
                            ruleSet.isSelected = true;
                        }
                        break;
                    case this.joinOperator.Or:
                        if (this.evalRuleGroup(ruleSet.groups[0], value) || this.evalRuleGroup(ruleSet.groups[1], value)) {
                            ruleSet.isSelected = true;
                        }
                        break;
                    default:
                        ruleSet.isSelected = false;
                        break;
                }

                if (ruleSet.groups.length > 2) {
                    for (var i = 2; i < ruleSet.groups.length; i++) {
                        switch (ruleSet.groups[i].ruleGroupJoinOperator) {
                            case this.joinOperator.And:
                                if (ruleSet.isSelected && this.evalRuleGroup(ruleSet.groups[i], value)) {
                                    ruleSet.isSelected = true;
                                } else {
                                    ruleSet.isSelected = false;
                                }
                                break;
                            case this.joinOperator.Or:
                                if (ruleSet.isSelected || this.evalRuleGroup(ruleSet.groups[i], value)) {
                                    ruleSet.isSelected = true;
                                } else {
                                    ruleSet.isSelected = false;
                                }
                                break;
                            default:
                                ruleSet.isSelected = false;
                                break;
                        }
                    }
                }
            } else {
                ruleSet.isSelected = this.evalRuleGroup(ruleSet.groups[0], value);
            }

            return ruleSet.isSelected;
        }

        evalRuleGroup(ruleGroup: IRuleGroup, value: any): boolean {
            ruleGroup.isSelected = false;
            if (ruleGroup.rules.length > 1) {
                switch (ruleGroup.rules[1].ruleJoinOperator) {
                    case this.joinOperator.And:
                        if (this.evalRule(ruleGroup.rules[0], value) && this.evalRule(ruleGroup.rules[1], value)) {
                            ruleGroup.isSelected = true;
                        }
                        break;
                    case this.joinOperator.Or:
                        if (this.evalRule(ruleGroup.rules[0], value) || this.evalRule(ruleGroup.rules[1], value)) {
                            ruleGroup.isSelected = true;
                        }
                        break;
                    default:
                        ruleGroup.isSelected = false;
                        break;
                }

                if (ruleGroup.rules.length > 2) {
                    for (var i = 1; i < ruleGroup.rules.length; i++) {
                        switch (ruleGroup.rules[i].ruleJoinOperator) {
                            case this.joinOperator.And:
                                if (ruleGroup.isSelected && this.evalRule(ruleGroup.rules[i], value)) {
                                    ruleGroup.isSelected = true;
                                } else {
                                    ruleGroup.isSelected = false;
                                }
                                break;
                            case this.joinOperator.Or:
                                if (ruleGroup.isSelected || this.evalRule(ruleGroup.rules[i], value)) {
                                    ruleGroup.isSelected = true;
                                } else {
                                    ruleGroup.isSelected = false;
                                }
                                break;
                            default:
                                ruleGroup.isSelected = false;
                                break;
                        }
                    }
                }
            } else {
                ruleGroup.isSelected = this.evalRule(ruleGroup.rules[0], value);
            }

            return ruleGroup.isSelected;
        }

        evalRule(rule: IRule, value: any): boolean {
            if (rule && value) {
                var regEx = /\[\[([^)]*)\]\]/g;
                var jScriptRegEx = /{{([^}]*)}}/g;
                var codeLeftValue = this.helper.toCamelCase(rule.codeLeftValue);
                var leftValue = value[codeLeftValue];
                var rightValue = rule.valueRightValue;

                if (rightValue) {
                    if (rightValue.toLowerCase() === "null") {
                        rightValue = undefined;
                    } else {
                        if (rightValue.match(regEx)) {
                            // if the right value contains a field lookup. IE [[FieldKey]] then it will use regex
                            // to find the value inside the brackets and than use that as the lookup in the json object

                            rightValue = rightValue.replace(regEx, (key, field) => {
                                var fieldName = this.helper.toCamelCase(field);

                                return value[fieldName];
                            });
                        }

                        if (rightValue.match(jScriptRegEx)) {
                            // if the right value contains a field lookup. IE [[FieldKey]] then it will use regex
                            // to find the value inside the brackets and than use that as the lookup in the json object
                            rightValue = rightValue.replace(jScriptRegEx, (key, expression) => {
                                var result = eval(expression);
                                return result;
                            });
                        }
                    }
                }

                rule.isSelected = this.compareValues(leftValue, rightValue, rule.compareOperator);
                return rule.isSelected;

            }

            return false;

        }

        compareValues(leftValue: any, rightValue: any, operator: string): boolean {

            var result: boolean;
            var isDate = false;


            if (leftValue && (_.isString(leftValue) || _.isDate(leftValue)) && moment(leftValue).isValid()) {
                leftValue = moment((<string>leftValue).replace(/T\d{2}:\d{2}:\d{2}.\d*Z/g, ""));
                if (rightValue && (_.isString(rightValue) || _.isDate(rightValue))) {
                    rightValue = moment(rightValue);
                } else {
                    rightValue = null;
                }
                isDate = true;
            }

            switch (operator) {
                case this.compareOperator.LessThan:
                    if (isDate) {
                        result = (<moment.Moment>leftValue).isBefore(rightValue, "day");
                    } else {
                        result = leftValue < rightValue;
                    }
                    break;
                case this.compareOperator.LessThanEqualTo:
                    if (isDate) {
                        result = (<moment.Moment>leftValue).isBefore(rightValue, "day") || (<moment.Moment>leftValue).isSame(rightValue, "day");
                    } else {
                        result = leftValue <= rightValue;
                    }
                    break;
                case this.compareOperator.Equal:
                    if (isDate) {
                        result = (<moment.Moment>leftValue).isSame(rightValue, "day");
                    } else {
                        if (_.isArray(leftValue)) {
                            result = leftValue.toString() === rightValue;
                        } else {
                            result = leftValue === rightValue;
                        }
                    }
                    break;
                case this.compareOperator.GreaterThan:
                    if (isDate) {
                        result = (<moment.Moment>leftValue).isAfter(rightValue, "day");
                    } else {
                        result = leftValue > rightValue;
                    }
                    break;
                case this.compareOperator.GreaterThanEqualTo:
                    if (isDate) {
                        result = (<moment.Moment>leftValue).isAfter(rightValue, "day") || (<moment.Moment>leftValue).isSame(rightValue, "day");
                    } else {
                        result = leftValue >= rightValue;
                    }
                    break;
                case this.compareOperator.NotEqual:
                    if (isDate) {
                        result = !(<moment.Moment>leftValue).isSame(rightValue, "day");
                    } else {
                        if (_.isArray(leftValue)) {
                            result = leftValue.toString() !== rightValue;
                        } else {
                            result = leftValue !== rightValue;
                        }
                    }
                    break;
                case this.compareOperator.Contains:
                    if (!leftValue) {
                        result = leftValue === rightValue;
                    } else {
                        result = leftValue.indexOf(rightValue) >= 0;
                    }
                    break;
                case this.compareOperator.DoesNotContain:
                    if (!leftValue) {
                        result = leftValue !== rightValue;
                    } else {
                        result = leftValue.indexOf(rightValue) < 0;
                    }
                    break;
                case this.compareOperator.StartsWith:
                    if (leftValue) {
                        result = leftValue.indexOf(rightValue) === 0;
                    } else {
                        result = false;
                    }
                    break;
                case this.compareOperator.EndsWith:
                    if (leftValue) {
                        result = leftValue.slice(rightValue.length) === rightValue;
                    } else {
                        result = false;
                    }
                    break;
                case this.compareOperator.IsNull:
                    result = (typeof leftValue === "undefined") || leftValue === null || leftValue === "";
                    break;
                case this.compareOperator.IsNotNull:
                    result = (typeof leftValue !== "undefined") && leftValue != null && leftValue !== "";
                    break;
                default:
                    result = false;
                    break;
            }


            return result;
        }

        getRule = (applicationDefinitionId: string, ruleId: string): angular.IPromise<IRuleSet> => {
            if (!this.rules) {
                return this.Restangular.all(applicationDefinitionId).all("rules").getList().then((result) => {
                    this.rules = result;
                    var rule = _.find(this.rules, { "id": ruleId });
                    return <any>rule;
                }, (reason) => {
                    console.log("Error Getting Rules");
                });
            }
            var rule = _.find(this.rules, { "id": ruleId });
            var deferred = this.$q.defer<IRuleSet>();
            deferred.resolve(rule);
            return deferred.promise;
        };
    }

    angular.module("rules").factory(RuleService.serviceId, ["$q", "Restangular", "helper", ($q, Restangular, helper) =>
        new RuleService($q, Restangular, helper)
    ]);

}