/// <reference path="common.ts" />
namespace Advant.Crossroads {
    export interface ICommonConfig {
        controllerActivateSuccessEvent?: string;
        spinnerToggleEvent?: string;
        loginEvent?: string;
        logoutEvent?: string;
        reloadUserEvent?: string;
    }

    export interface ICommonServiceProvider extends ng.IServiceProvider {
        config: ICommonConfig;
    }

    export class CommonServiceProvider implements ICommonServiceProvider {
        config: ICommonConfig = {
            // these are the properties we need to set
            // controllerActivateSuccessEvent: "",
            // spinnerToggleEvent: ""    
        };

        $get = () => {
            return this.config;
        };
    }

    // must configure the common service and set its 
    // events via the commonConfigProvider
    angular.module("common").provider("commonConfig",() =>
        new CommonServiceProvider()
        );

}