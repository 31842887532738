// Install the angularjs.TypeScript.DefinitelyTyped NuGet package
namespace Advant.Crossroads {
    "use strict";

    export interface IPayeezyViewModel extends IPaymentViewModel {
        apiKey: string;
        jsToken: string;
        taToken: string;
    }
    // ReSharper disable InconsistentNaming
    export interface IPayeezyResponseViewModel {
        //HTTP Status code
        //200, 201, 202
        //    Created / Accepted Transaction request - All OK. 
        //400
        //    Bad Request Input Request is invalid or incorrect. 
        //401
        //    Unauthorized Invalid API Key and Token. 
        //404
        //    Not Found The requested resource does not exist. 
        //500, 502, 503, 504
        //    Server errors Server Error at Payeezy end. 
        status: number;
        results: {
            // Payeezy Internal log id
            correlation_id: string;
            // Status message for Token creation. Possible values = "success" / "failed".
            status: string;
            // Type of the token being created. Possible values = FDToken.
            type: string;
            token?: {
                // Type of credit card. Possible values - “American Express", "Visa", "Mastercard", “JCB”, “Diners Club” or "Discover"
                type: string;
                cardholder_name: string;
                exp_date: string;
                // Its the value of the tokenized card info via the transarmor tokenization process
                value: string;
            }
            Error?: { messages: Array<{ code: string; description: string; }>; };
        }
    }
    // ReSharper restore InconsistentNaming
    export interface IPayeezyService {
        createToken: (paymentInfo: IPayeezyViewModel) => angular.IPromise<string>;
    }

    class PayeezyService implements IPayeezyService {
        static $inject: string[] = ["$http", "$q", "$sce"];

        constructor(private $http: angular.IHttpService, private $q: angular.IQService, private $sce: any) {
        }

        createToken(paymentInfo: IPayeezyViewModel) {
            var request = {
                apikey: paymentInfo.apiKey,
                js_security_key: paymentInfo.jsToken,
                ta_token: paymentInfo.taToken,
                auth: true,
                type: "FDToken",
                "credit_card.type": paymentInfo.cardType,
                "credit_card.cardholder_name": paymentInfo.firstName + " " + paymentInfo.lastName,
                "credit_card.card_number": paymentInfo.cardNumber,
                "credit_card.exp_date": this.padNumber(paymentInfo.expireMonth, 2) + paymentInfo.expireYear.toString().slice(2, 4),
                "credit_card.cvv": paymentInfo.cvv2
            };
            var deferred = this.$q.defer<string>();
            var url = this.$sce.trustAsResourceUrl("https://api-cert.payeezy.com/v1/securitytokens");

            this.$http.jsonp<IPayeezyResponseViewModel>(url, { params: request }).then((response) => {
                if (response.data.status === 200 || response.data.status === 201 || response.data.status === 202) {
                    deferred.resolve(response.data.results.token.value);
                } else {
                    deferred.reject(response.data.results.Error.messages[0].description);
                }
            }, (reason) => {
                deferred.reject(reason);
            });

            return deferred.promise;
        }

        padNumber = (num: number, length: number) => {
            var str = num.toString();
            while (str.length < length) {
                str = `0${str}`;
            }

            return str;

        };
    }

    angular.module("app").service("payeezyService", PayeezyService);
}